@mixin heading($color: color(mono, dark), $font-size: default(font-size), $margin-bottom: 30, $bold: true, $uppercase: true) {
  @include rem($font-size);
  color: $color;
  display: block;
  margin-bottom: #{$margin-bottom}px;
  @if ($bold == true) {
    font-weight: default(weight, bold);
  }
  @if ($uppercase == true) {
    text-transform: uppercase;
  }
}

@mixin rem($pixel-size: default(font-size)) {
  font-size: #{$pixel-size}px;
  font-size: #{$pixel-size/10}rem;
}

@mixin animated($prop: all, $time: 0.3s, $easing: ease, $delay: 0s) {
  transition: $prop $time $easing $delay;
}

@mixin poa($top: null, $right: null, $bottom: null, $left: null) {
  position: absolute;
  @if ($bottom != null) {
    bottom: $bottom;
  }
  @if ($left != null) {
    left: $left;
  }
  @if ($right != null) {
    right: $right;
  }
  @if ($top != null) {
    top: $top;
  }
}

@mixin position($type: 'absolute', $top: null, $right: null, $bottom: null, $left: null) {
  position: $type;

  @if ($bottom != null) {
    bottom: $bottom;
  }

  @if ($left != null) {
    left: $left;
  }

  @if ($right != null) {
    right: $right;
  }

  @if ($top != null) {
    top: $top;
  }
}

@mixin padding($top: null, $right: null, $bottom: null, $left: null) {
  @if ($bottom != null) {
    padding-bottom: $bottom;
  }
  @if ($left != null) {
    padding-left: $left;
  }
  @if ($right != null) {
    padding-right: $right;
  }
  @if ($top != null) {
    padding-top: $top;
  }
}

@mixin size($width, $height: $width) {
  height: #{$height}px;
  width: #{$width}px;
}

@mixin transform($transform) {
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
  -o-transform: $transform;
  transform: $transform;
}

/* percent */
@mixin percent {
  background-color: color(background);
  color: color(mono, light);
  padding: 0.8rem 1.3rem;
  margin-left: 10px;
  @include rem(15);
  border-radius: 0.2rem;
  @media(min-width: $xs-width) {
    @include rem(18);
  }
}

@mixin percent-listing {
  color: color(text);
  padding: 0.3rem 1rem;
  font-size: 1.2rem;
  line-height: 1.5rem;
  background-color: rgba(color(background), 0.3);
  font-weight: default(weight, bold);
  border-radius: 0.3rem;
  display: inline-block;
  @media(min-width: $xs-width) {
    @include rem(13);
  }
}

/*********** style title **************/

@mixin title2 {
  font-family: default(font-family);
  font-weight: default(weight, bold);
  color: color(mono, dark);
  text-transform: uppercase;
  font-size: 2.5rem;
  line-height: 2.5rem;
  @media(min-width: $xs-width) {
    font-size: 3rem;
    line-height: 3rem;
  }
  @media(min-width: $md-width) {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }
  @media(min-width: $lg-width) {
    font-size: 5rem;
    line-height: 5rem;
  }
}

@mixin title3 {
  font-family: default(font-family);
  color: color(mono, dark);
  font-weight: default(weight, bold);
  text-transform: uppercase;
  font-size: 1.6rem;
  line-height: 2rem;
  @media(min-width: $md-width) {
    font-size: 3rem;
    line-height: 3rem;
  }
  @media(min-width: $lg-width) {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }
}

@mixin title-process {
  font-family: default(font-family);
  font-weight: default(weight, bold);
  color: color(mono, dark);
  text-transform: uppercase;
  font-size: 2rem;
  line-height: 2rem;
  @media(min-width: $xs-width) {
    font-size: 3rem;
    line-height: 3rem;
  }
  @media(min-width: $lg-width) {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }
}

@mixin title4 {
  font-family: default(font-family);
  font-weight: default(weight, bold);
  color: inherit;
  text-transform: uppercase;
  font-size: 1.5rem;
  line-height: 1.5rem;
  @media(min-width: $lg-width) {
    font-size: 2rem;
    line-height: 2rem;
  }
}
