.breadcrumb {
  font-size: 1.4rem;
  background: none;
  border-radius: 0;
  font-weight: default(weight, light);
  margin: 0;
  padding: 15px 0 10px 0;

  li {
    color: color(text);
    a {
      color: color(text);
    }
    &.active {
      color: color(text);
    }

    + li::before {
      color: color(text);
      content: ">";
      padding: 0
    }
  }
  @media (max-width: $xs-width) {
    display: none;
  }
}
