.btn {
  background: color(mono);
  border: 0.2rem solid color(button, dark);
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  font-family: default(font-family, primary);
  font-size: 1.4rem;
  height: 44px; 
  letter-spacing: 1px;
  overflow: hidden;
  padding: 12px 10px 10px 10px;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: none;
  transition: all 0.3s ease 0s;
  z-index: 3;
  color: color(text, dark);

  @media (min-width: $md-width) {
    font-size: 1.6rem;
    height: 50px; 
    padding: 14px 30px 12px 30px;
  }

  &:hover {
    background-color: color(primary);
    border-color: color(primary);
    color: color(mono);
    text-decoration: none;
    text-shadow: none;
  }

  &-default {
    background: transparent;
    border-color: color(primary);
    color: color(primary);

    &:hover {
      background-color: color(primary, dark);
      border-color: color(primary, dark);
    }
  }
  
  &-primary {
    background: color(primary);
    border-color: color(primary);
    color: color(mono);

    &:hover {
      background-color: color(primary, dark);
      border-color: color(primary, dark);
    }
  }
  
  &-secondary {
    background: color(secondary);
    border-color: color(secondary);
    color: color(text, light);

    &:hover {
      background-color: color(mono);
      color: color(secondary);
    }
  }

  &-tertiary {
    background: transparent;
    border-color: color(primary);
    color: color(primary);

    &:hover {
      background-color: color(primary);
      color: color(mono);
    }
  }
  &-quaternary {
    background:transparent;
    border-color: color(mono);
    color: color(mono);
    &:hover {
      background: color(mono);
      color: color(primary);
    }
  }
  &-small {
    padding: 8px 10px;
    height: auto;
    line-height: 1;
  }
  &-wipe {
    border: 0;
    padding: 0;
    background: none;
    height: inherit;

    &:hover {
      color: color(text, dark);
      background: none;
    }
  }

  &-link {
    background: none;
    color: color(secondary);
    display: inline-block;
    font-size: 1.2rem;
    font-weight: default(weight, book);
    letter-spacing: 1px;
    margin-bottom: 1rem;
    padding: 1rem 0;
    position: relative;
    text-transform: uppercase;
    border: 0;

    @media (min-width: $md-width) {
      font-size: 1.4rem;
    }

    &:after {
      @include animated();
      @include poa(null, 0, 0, 0);
      content: "";
      background: color(secondary);
      height: 1px;
    }

    &:hover, &:focus {
      background: none;
      color: color(primary);
      text-decoration: none;

      &:after {
        bottom: 3px;
        background: color(primary);
      }
    }

    &.inverse {
      color: color(primary);
      &:after {
        background: color(primary);
      }

      &:hover {
        color: color(secondary);

        &:after {
          background: color(secondary);
        }
      }
    }
  }

  &-fw {
    width: 100%;
  }

  &-rounded {
    border-radius: 25px;
    background-color: color(background, grey);
    border-color: color(background, grey);
    text-transform: none;
    color: color(primary);
    padding: 10px;

    @media (min-width: $md-width) {
      padding: 10px 25px 10px 25px;
    }

    &:hover,
    &.selected {
      background-color: color(primary);
      border-color: color(primary);
      color: color(text, light);
    }
    &:focus,
    &:active,
    &:active:focus {
      outline: 0;
    }
  }

}

.btn-social {
  @include size(30);
  background: color(mono, dark);
  border-radius: 50px;
  display: block;
  position: relative;

  .icon {
    color: color(mono, light);
    @extend %full-center;
  }

  &:hover {
    background-color: color(background);
  }
}

/*** product qty ****/
.product-qty {
  text-align: center;

  &-ctn {
    margin-bottom: 10px;
    position: relative;
  }

  &-down,
  &-up {
    background: none;
    border: 0;
    color: color(text);
    cursor: pointer;
    font-size: 2rem;
    height: 100%;
    line-height: 4rem;
    position: absolute;
    top: 0;
    transition: .3s;
    width: 35px;
    z-index: 2;
  }

  &-down {
    left: 0;
  }

  &-up {
    right: 0;
  }

  &-btn,
  &-input {
    font-weight: default(weight, bold);
  }

  &-input {
    border: 1px solid color(border);
    border-radius: default(radius);
    color: color(text);
    height: 4.1rem;
    padding: 0 35px;
    position: relative;
    text-align: center;
    width: 100%;
    z-index: 1;
  }
}
