.header-stack {
  .navbar-action {
    opacity: 1;
  }
}

// displayMenu
.header-main .navbar {
  @include position(fixed, 0, 0, 0, 0);
  background: rgba(0, 0, 0, 0);
  pointer-events: none;
  z-index: 200;
  overflow: auto;
  padding: 0 0 3rem;
  transition: .3s;
  margin: 0;
  font-family: default(font-family, secondary);
  font-weight: default(weight, base);
  font-style: normal;
  min-height: inherit;

  @media (min-width: $md-width) {
    border-top: 0;
    display: flex;
    left: auto;
    right: auto;
    overflow: visible;
    padding: 0;
    position: relative;
    pointer-events: auto;
    text-align: center;
    transition: none;
    top: auto;
    z-index: 1;

    .header-mobile {
      display: none;
    }
  }

  &-inner {
    @include animated(left);
    @include position(absolute, 0, null, 0, 0);
    background: color(mono);
    width: 30rem;
    left: -101%;

    @media (min-width: $md-width) {
      background: transparent;
      left: auto;
      position: relative;
      width: auto;
    }
  }

  &-close {
    background: color(primary);
    color: color(mono);
    font-size: 1.6rem;
    padding: 1rem 1.5rem;

    .icon {
      @include poa(1.3rem, 1.5rem);
      cursor: pointer;
      font-size: 1.2rem;
    }

    @media (min-width: $md-width) {
      display: none;
    }
  }

  &:hover {
    .navbar-link {
      color: color(primary);
    }
  }

  &.open {
    background: rgba(0, 0, 0, 0.7);
    pointer-events: auto;

    .navbar-inner {
      left: 0;
    }
  }

  // Switch Menu
  &-action {
    display: flex;
    margin-top: .5rem;

    .icon {
      color: inherit;
      font-size: 1.8rem;
    }

    &-label {
      display: none;
    }

    @media (min-width: $md-width) {
      display: none;
    }
  }

  .footer-links {
    background-color: color(background);
    max-width: 100%;

    @media (min-width: $md-width) {
      display: none;
    }
  }

  &-content {
    background: color(mono);
    margin: 0;
    
    @media (min-width: $md-width) {
      background: transparent;
      padding: 1.5rem 3rem 0;
      position: relative;
      text-align: left;
      top: 5rem;
    }

    @media (min-width: $md-width) {
      margin: 0;
      padding: 0;
      position: static;
      text-align: center;
    }
  }

  &-universe-link {
    @include position(fixed, null, 1.5rem, 1.5rem, 1.5rem);

    display: none;
    height: 4rem;
    margin: 0 auto;
    max-width: 100%;
    padding-bottom: 8px;

    @media (min-width: $xs-width) {
      width: 400px;
    }

    @media (min-width: $md-width) {
      display: inline-block;
      margin-top: 3rem;
      position: static;
      width: auto;
    }
  }

  &-root-link {
    border-bottom: 1px solid color(primary, light);
    color: inherit;
    font-size: 1.3rem;
    display: block;
    position: relative;
    padding: 1.5rem 4.5rem 1.5rem 1.5rem;
    z-index: 2;
    text-transform: uppercase;
    text-decoration: none;
    transition: none;

    @media (min-width: $md-width) {
      border-color: transparent;
      padding: 0 0 1rem 0;
      margin-right: 3.5rem

    }

    &:hover {
      @media (min-width: $md-width) {
        border-bottom: 1px solid color(primary);
      }
    }
  }

  &-root-item {
    display: block;
    width: 100%;

    @media (min-width: $md-width) {
      width: auto;
    }

    &-content {
      margin: 0 auto;
      max-width: 100%;
      position: relative;
    }

    &:hover {
      .navbar-root-item-content {
        &::after {
          bottom: 6px;
          left: 0;
          opacity: .2;
        }
      }

      .navbar-root-link {
        &:after {
          @media (min-width: $md-width) {
            border-bottom: 1px solid color(text, dark);
          }
        }
      }

      .submenu {
        opacity: 1;
        pointer-events: auto;
        transition: opacity .3s 0s;
      }
    }

    &.has-child {
      .navbar-root-link:after { 
        @include poa(0.4rem, 1.5rem);
        content: '\e907';
        color: color(primary, light);
        display: block;
        font-family: default(font-family, icon);
        padding-top: 12px;
        
        @media (min-width: $md-width) {
          display: none;
        }
      }
    }

    &.open {
      .submenu {
        display: block;

        .navbar-universe-link {
          display: inline-block;
        }
      }
    }
  }

  &-cta {
    margin-top: 3rem;

    @media (min-width: $md-width) {
      margin-top: 0;
    }

    li {
      margin-bottom: 1rem;
    }

    .btn {
      text-align: center;
      width: 100%;
    }
  }
  

}

.see-all {
  &-link {
    border-bottom: 1px solid color(border, dark);
    color: color(text, light);
    font-weight: default(weight, bold);

    &:hover {
      color: color(text);
    }
  }
}

.submenu {
  display: none;
  opacity: 1;
 
  @media (min-width: $md-width) {
    @include position(fixed, height(header, stack) + height(banner, stack), 0, null, 0);
    border-top: 1px solid color(primary, light);
    display: block;
    overflow: visible;
    padding-top: 0;
    opacity: 0;
    pointer-events: none;
    text-align: left;
    transition: opacity .2s 0s;
    z-index: 1;
  }

  @media (min-width: $md-width) {
    &::before {
      bottom: 0;
      content: '';
      height: 30px;
      left: 0;
      position: absolute;
      right: 0;
      top: -30px;
      z-index: 1;
    }
  }

  &::after {
    background-color: color(shadow, overlay);
    content: '';
    height: 100vw;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  &-inner {
    background-color: color(mono);

    @media (min-width: $md-width) {
      background-color: color(background);
      max-height: 70vh;
      overflow: auto;
    }
  }

  &-row {
    margin: 0 auto;
    max-width: 100%;
    padding: 1.5rem 0 6rem;
    position: relative;
    width: 430px;

    @media (min-width: $sm-width) {
      padding: 1.5rem 1.5rem 6rem;
      width: 460px;
    }

    @media (min-width: $md-width) {
      margin: 0 (-$gutter / 2);
      max-width: none;
      padding: 0 0 $gutter;
      width: auto;
    }
  }

  &-item {
    &:last-child {
      margin-bottom: 2rem;
    }
    &:hover {
      .submenu-img-desk {
        transform: scale(1.05);
      }
    }
  }

  &-title {
    border-bottom: 1px solid transparent;
    transition: .3s;
  }

  &-list {
    border-top: 1px solid color(primary, light);
    margin: -1px -1rem 0 -1rem;
    padding: 2rem 1rem 0 1rem;

    @media (min-width: $md-width) {
      border: 0;
      padding: 0;
      margin: 0;
    }
  }

  &-link {
    background-color: color(background);
    border-radius: default(radius);
    font-size: 1.4rem;
    font-weight: default(weight, base);
    margin-bottom: 2rem;
    padding: 0 0.5rem;
    text-transform: uppercase;
    color: color(secondary);

    &:hover { color: color(secondary); }
    @media (min-width: $md-width) {
      background: transparent;
      padding: 0;
    }

    .submenu-link {
      margin-bottom: 1rem;
    }
  }

  .advsubmenu-row {

    @media (min-width: $md-width) {
      padding: 4.5rem 1rem;
    }
  }

  a.submenu-link {
    &:hover {
      .submenu-title {
        border-color: color(border, dark);
      }
    }
  }

  &-img-desk {
    border-radius: default(radius);
    transition: 1.3s;

    &-ctn {
      border-radius: default(radius);
      line-height: 0;
      overflow: hidden;
    }
  }

  &-child {
    &-menu {
      @media (min-width: $md-width) {
        padding-left: 0;
        padding-right: 0;
      }
      .submenu-link {
        text-transform: none;
        color: color(mono, dark);
        margin-bottom: 1rem;

        &:hover {
          color: color(secondary)
        }
      }
    }

    &-link {
      display: inline-block;
      margin-bottom: .9rem;
    }
  }

  .ctn {
    @media (max-width: $md-width) {
      padding: 0;
    }
  }

  .close-tab {
    background: color(background);

    @media (min-width: $md-width) {
      display: none;
    }

    &-link {
      display: block;
      margin: 0 auto;
      max-width: 100%;
      padding: 1rem 1.5rem;
      width: 400px;

      @media (min-width: $md-width) {
        padding: 0;
        width: 100%;
      }
    }

    .icon {
      font-size: 1.1rem;
      margin-right: 1rem;
    }
  }
}
