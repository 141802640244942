// ACCOUNT MENU
.sidebar {
  .block-menu-account {
    background: color(background, grey);
    margin-bottom: 30px;
    padding: 3rem 1.5rem 1.5rem;

    nav {
      border-bottom: 0;
    }

    h3 {
      border-bottom: .1rem solid color(border);
      padding: 0 0 1.5rem 0;
      margin-bottom: 3rem;
      height: auto;

      a {
        padding: 0;
        &:after {
          display: none;
        }
      }
      &:after {
        display: none;
      }
    }
    ul {
      display: block !important;
    }
  }
}
