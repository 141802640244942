.alert {
  background-image: none;
  border: 0;
  border-radius: 0; 
  box-shadow: none;
  display: block;
  font-weight: default(weight, light);
  font-size: 1.4rem;
  margin: 1.5rem 0 0 0;
  padding: 0.8rem 1rem;
  text-align: left;
  text-shadow: none;
  
  @media (min-width: $md-width) { 
    font-size: 1.6rem;
    padding: 1.2rem 1.5rem;
  }

  &-fw {
    text-align: center;
    width: 100%;
  }

  &-success {
    background-color: color(alert, success);
    color: color(primary);
  }

  &-warning {
    background-color: transparentize(color(alert, warning), 0.8);
    color: color(alert, warning);
  }
  
  &-warning2 {
    background-color: transparentize(color(secondary), 0.8);
    color: color(secondary);
  }

  &-danger {
    background-color: transparentize(color(alert, danger), 0.8);
    color: color(alert, danger);
  }

  &-info {
    background-color: transparentize(color(alert), 0.9);
    color: color(alert);
  }
}

.label {
  &-success {
    color: color(alert, success);
  }

  &-warning {
    color: color(alert, warning);
  }

  &-danger {
    color: color(alert, danger);
  }
}
