.line {
  margin: 0;
  padding: 1.5rem;

  & + .line {
    border-top: 1px solid color(border);
  }
}

.cart-summary {
  .price {
    color: color(primary);
    font-size: 1.8rem;
    font-weight: default(weight, bold);
  }

  &-prices-first {
    &-inner {
      background-color: color(background);
      border-bottom: 1px solid color(border);
      margin: 0 -1.5rem 2rem;

      @media (min-width: $md-width) {
        border: 1px solid color(border);
        border-radius: default(radius);
        margin: 0 0 2rem;
      }
    }

    .shipping {
      display: none;
      margin-bottom: 1rem;
    }

    .cart-navigation {
      margin-bottom: 2.5rem;

      &-mobile {
        display: none;

        &.stack {
          @include position(fixed, null, 1.5rem, 0, 1.5rem);

          display: block;
          margin-bottom: 1.5rem;
          transition: .3s;
          z-index: 100;
        }
      }
    }
  }

  .total-with-taxes {
    .price {
      font-size: 2.5rem;

      @media (min-width: $md-width) {
        font-size: 3rem;
      }
    }
  }

  .total-without-taxes {
    &,
    .price {
      color: color(text, light);
    }
  }
}

//************** discount / voucher ******************//
.discount-block {
  .discount-ctn {
    display: none;
    padding: 0;
  }

  &.open {
    .discount-ctn {
      display: block;
    }

    .discount-form {
      display: none;
    }
  }
}

.discount-connexion {
  margin-bottom: 3rem;

  .btn {
    margin-top: 1rem;
  }
}

.voucher-name-code {
  color: color(secondary);
  font-weight: bold;
}

.voucher-description {
  font-size: 1.4rem;
}

.voucher-item {
  background-color: color(mono, light);
  border: 1px solid color(border);
  margin-bottom: 1rem;
  padding: 1rem 1.5rem;
}

.discount-delete {
  font-size: 14px;
}

.code-promo {
  margin-bottom: 1.5rem;

  &-item {
    margin-bottom: 1rem;
  }
}

.discount-featured {
  .label {
    line-height: 1.7rem;
    margin-bottom: 1rem;
  }
}

.discount-form-ctn {
  position: relative;

  form {
    position: relative;
  }

  .label {
    margin-bottom: .5rem;
  }

  .form-control {
    padding-left: 1.5rem;
  }

  .submit-add-discount {
    position: absolute;
    right: 1rem;
    top: .5rem;

    @media (min-width: $md-width) {
      top: 1rem;
    }
  }
}

.order-recap-cart {
  background-color: color(background);
  border: 1px solid color(border);
  margin: 0 -1.5rem 3rem;
  position: relative;

  @media (min-width: $md-width) {
    margin: 0 0 3rem;
  }

  @media (min-width: $md-width) {
    border-radius: default(radius);
  }

  .show-details {
    display: none;
    padding-left: 1rem;
    padding-right: 1rem;
    text-transform: none;

    @media (min-width: $md-width) {
      display: inline-block;
    }

    &::after {
      color: inherit;
      content: icon('arrow-down');
      display: inline-block;
      font-family: default(font-family, icon);
      font-size: 1rem;
      margin-left: 1rem;
      transition: transform .3s;
      vertical-align: middle;
    }

    &:hover {
      &::after {
        color: color(mono, light);
      }
    }

    &.open {
      &::after {
        margin-bottom: .5rem;
        transform: rotate(180deg);
      }
    }
  }

  .resume-products {
    color: color(text, light);
    font-weight: default(weight, bold);
    padding-bottom: 0;

    &-header {
      background-color: color(background);
      border-bottom: 1px solid color(border);
      padding: 1.5rem 1.5rem 1rem;
      position: relative;
      text-transform: uppercase;

      @media (min-width: $md-width) {
        text-transform: initial;
      }

      .icon-close {
        @include poa(50%, 15px);

        color: color(text, light);
        font-size: 1.4rem;
        transform: translateY(-50%);

        @media (min-width: $md-width) {
          position: static;
          transform: none;
        }
      }
    }

    .products-lines {
      display: none;
    }

    &.open {
      .products-lines {
        display: block;
      }
    }
  }

  .products-lines {
    background-color: color(mono, light);
    border-bottom: 1px solid color(border);
  }

  .cart-reassurance {
    @media(min-width: $md-width) {
      margin-bottom: 20rem;
      margin-top: 6rem;
    }
  }
}

.recap-product {
  color: color(text);
  padding: 15px;
  position: relative;

  > .start-xxs {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      padding-right: 52px;
      width: 100%;

      .real-price {
        white-space: nowrap;
      }
    }
  }

  .product-name {
    margin-bottom: .5rem;
    max-width: 215px;
  }

  .product-img {
    @include poa(null, 0);

    @media (min-width: $md-width) {
      position: static;
    }
  }

  .product-quantity {
    font-weight: initial;
  }

  .product-infos {
    @media (min-width: $md-width) {
      padding-left: 0;
    }
  }

  .product-prices {
    margin-top: 5.5rem;

    @media (min-width: $md-width) {
      margin-top: 0;
    }
  }

  .real-price {
    font-size: 1.8rem;
  }

  .old-price {
    font-weight: initial;
  }

  & + & {
    border-top: 1px solid color(border);
  }
}

.order-recap-carrier {
  background: color(background);
  font-size: 1.3rem;
  margin-bottom: 0;
  padding: 15px;

  .carrier-name {
    display: block;
    font-weight: bold;
  }
}

.adresses-section {
  .block-bordered {
    position: relative;
  }

  .ajax-loader {
    &::before {
      @include poa(0, 0, 0, 0);

      background-color: color(mono, light);
      content: '';
      opacity: .8;
      z-index: 1;
    }
  }
}

//******** recap mobile *******//

.cart-summary-prices {
  @media(max-width: $md-width) {
    bottom: 100%;
    left: 0;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    transition: .3s;
    z-index: 1000;

    .order-recap-cart {
      .resume-products {
        .products-lines {
          display: block;
        }
      }
    }

    &.open {
      background-color: color(mono, light);
      bottom: 0;
      padding-bottom: 3rem;
    }
  }

  .order-recap-address {
    .link {
      @include poa(null, 1.5rem, 1.5rem);

      color: color(text, light);

      &:hover {
        color: color(text);
      }
    }
  }
}

// subscription cart 
.subcription-lines {
  dl {
    display: block!important;
    margin-top: 0.3rem;
    dt {
      color: color(primary);
      display: inline;
      font-size: 1.4rem;
      line-height: 2.2rem;
      &:nth-child(2):before {
        content: ', ';
      }
    }
  }
}