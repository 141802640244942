[data-name="Rbs_Simpleform_Form"] {
  @extend .ctn;

  @media (min-width: $md-width) {
    padding: 0 25rem;
  }

  .form-group {
    @extend .flex;
    @extend .middle-xxs;

    label {
      margin-bottom: 0.5rem;
    }
  }

  .col-sm-offset-4.col-md-offset-3 {
    margin: 0;
    text-align: center;
  }

  .text-success {
    @extend .alert;
    @extend .alert-success;

    margin-bottom: 15px;
  }
}    