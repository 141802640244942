.product-ctn {
  margin-bottom: 15px;

  @media (min-width: $xs-width) {
    margin-bottom: 20px;
  }
  @media (min-width: $sm-width) {
    margin-bottom: 30px;
  }
  @media (min-width: $md-width) {
  }
  @media (min-width: $lg-width) {
  }

  .inner {
    border: 1px solid color(border);
    position: relative;
  }

  .product-pins {
    @include poa(0, null, null, 0);

    li {
      @include rem(12);
      background: color(background, dark);
      color: color(mono, light);
      padding: 7px;
      text-transform: uppercase;
    }
  }

  .product-infos {
    background: color(background);
    padding: 20px 10px;

    .btn {
      padding: 10px 0;

      @media (min-width: $xs-width) {
        padding: 10px 20px;
      }
      @media (min-width: $sm-width) {
        padding: 10px 30px;
      }

      span {
        font-size: 12px;

        @media (min-width: $xs-width) {
          font-size: 13px;
        }
      }

      &.ajax_add_to_cart_button {
        margin-bottom: 5px;
      }
    }
  }

  .product-name {
    @include rem(16);
    color: color(mono, dark);
    display: block;
    font-weight: default(weight, bold);
    height: 35px;
  }

  .product-details {
    padding: 7px 0;
  }

  &.alt {
    .inner {
      border-color: color(border);
    }

    .product-infos {
      background: color(background)
    }

    .product-name {
      color: color(text);
    }
  }
}
