// Breakpoint
$xl-width: 1320px;
$lg-width: 1200px;
$md-width: 992px;
$sm-width: 768px;
$xs-width: 480px;
$xxs-width: 360px;
$gutter: 30px;
$maxContainer: 1730px;

// Grid
$grid-columns: 12;
$column-width: 100 / $grid-columns;
$breakpoints: (
  xxs: (
    container: 100%,
    min-width: false,
    gutter: 30px
  ),
  xs: (
    container: 100%,
    min-width: $xs-width,
    gutter: $gutter
  ),
  sm: (
    container: 720px,
    min-width: $sm-width,
    gutter: 30px
  ),
  md: (
    container: 960px,
    min-width: $md-width,
    gutter: 30px
  ),
  lg: (
    container: 1170px,
    min-width: $lg-width,
    gutter: 30px
  ),
  xl: (
    container: 1290px,
    min-width: $xl-width,
    gutter: 30px
  )
);

$colors: (
  mono: (
    base: #fff,
    dark: #12375E
  ),
  grey: (
    base: #88939c,
    light: #f3f4f5,
    dark: #637583,
  ),
  primary: (
    base: #003a5e,
    light: #afcbdd
  ),
  secondary: (
    base: #caa354
  ),
  text: (
    base: #88939c,
    dark: #003a5e,
    light: #fff
  ),
  alert: (
    base: #666666,
    warning: #de9056,
    warning2: #cca65c,
    danger: #de5656,
    success: #d7f4e8, 
    error: #df5555,
    success2: #29be8d
  ),
  shadow: (
    overlay: rgba(0, 0, 0, .5),
    primary: rgba(0, 58, 94, .7)
  ),
  border: (
    base: #88939c,
    light: #fff,
    dark: #12375E,
    lighter: #afcbdd,
    lighter2: #ccd8df
  ),
  button: (
    base: #fff,
    light: #f6f6f6,
    dark: #12375E
  ),
  background: (
    base: #ffffff,
    grey: #f3f4f5,
    pink: #fdf1f3,
    dark: #12375E
  ),
  logo: (
    base: #12375E,
    light: #ffffff
  )
);

@function color($color, $tone: 'base') {
  @return map-get(map-get($colors, $color), $tone);
}

$default: (
  font-size: (
    base: 1.4rem
  ),
  font-family: (
    base: 'Hind',
    secondary: 'Brother 1816',
    tertiary: 'Bauer',
    icon: 'icomoon'
  ),
  weight: (
    base: 400,
    light: 300,
    book: 500,
    bold: 700,
    black: 900
  ),
  radius: (
    base: 3px
  ),
  line-height: (
    base: 1.1
  )
);

@function default($property, $value: 'base') {
  @return map-get(map-get($default, $property), $value);
}

$heights: (
  banner: (
    'base': 35px,
    'xs': 35px,
    'sm': 35px,
    'md': 35px,
    'lg': 35px,
    'stack': 35px
  ),
  header: (
    'base': 110px,
    'xs': 120px,
    'sm': 125px,
    'md': 125px,
    'lg': 125px,
    'nomenu': 136px,
    'stack': 125px
  )
);

@function height($type, $size: 'base') {
  @return map-get(map-get($heights, $type), $size);
}

$icons: (
  arrow-down: '\f078',
);

@function icon($icon) {
  @return map-get($icons, $icon);
}
