[data-layout-rbs-geo-manage-addresses] {
  h1 {
    display: none;
  }
  h2 {
    margin-bottom: 30px;
  }
}

.address-block {
  position: relative;
  margin-bottom: 30px;

  h3 {
    margin-top: 15px;
    @include heading(color(text, dark), 15, 15, true, true);
  }

  > * {
    z-index: 2;
    position: relative;
    padding: 0 15px;
  }

  &:before {
    content: "";
    background-color: color(background, grey);
    left: 15px;
    right: 15px;
    bottom: 0;
    top: 0;
    position: absolute;
    z-index: 0;
  }

  .address-delete {
    float: right;
    height: 44px;
  }

  .address-default-btn {
    .glyphicon {
      display: none;
    }
    span {
      font-size: 1.6rem;
      color: color(secondary);
    }
    .btn {
      @extend .btn-wipe;
      text-transform: none;
      text-decoration: underline;
    }
  }

  .btn-link {
    background: none;
    padding: 0;
    margin: 0.8rem 0 0;
    height: auto;
  }
}

[data-rbs-geo-address-editor] {
  [name="addressForm"] {
    margin-bottom: 30px;

    label {
      width: 100% !important;
      display: block;
      text-align: left;
    }
    &:before {
      background: none;
    }
    em {
      float: none;
    }
  }
}

.block-account {
  h1 {
    @include heading(color(text, dark), 24, 0, false, false);
    font-family: default(font-family, tertiary);

    @media (min-width: $md-width) {
      font-size: 3.8rem;
    }
  }

  &[data-name="Rbs_Geo_ManageAddresses"] {
    h1 {
      display: none;
    }
  }

  &[data-name="Rbs_Productreturn_ReturnProcess"] {
    .line-quantity {
      a {
        color: color(primary);
        text-decoration: underline;
      }
    }

    .panel-body {
      border: 1px solid color(primary);

      .dl-horizontal {
        margin-bottom: 0;
      }
    }
    .form-group [class*="col-"] {
      margin: 0;
    }

    .form-group[data-ng-if="getReasonById(returnLine.reason).precisionsField"] {
      margin-top: 0 !important;
    }

    .custom-file {
      width: 100%;
      @media (min-width: $sm-width) {
        width: 65%;
      }
    }
  }

  [data-ng-if="returnRequest.common.statusInfos.code == 'VALIDATION'"] {
    .panel-body {
      border: 0;
      padding-top: 30px;
    }
  }

  &[data-name="Rbs_Productreturn_ReturnProcess"],
  &[data-name="Rbs_Order_OrderDetail"] {
    h1 {
      display: flex;
      flex-direction: column;
      margin-bottom: 1.5rem;
      span {
        float: none !important;
        order: 2;
        color: color(secondary);
        font-size: 1.8rem;
        font-family: default(font-family);
        padding: 1.5rem 0 0;
      }
    }

    .pull-right.list-unstyled {
      float: none !important;
    }

    h3,
    h2 {
      @extend %panel-header;
      text-align: center;
      border: 0;
      padding: 2.5rem 1.5rem 0;
    }

    div:not(.table-vertical-responsive) table,
    .one-line-bottom-margin,
    address,
    dl {
      padding: 2.5rem 1.5rem;
      background: color(background, grey);
      margin-bottom: 3rem;
      font-size: 1.4rem;

      strong {
        margin-bottom: 0;
      }
      dt {
        font-weight: default(weight, bold);
      }
      dd {
        margin-bottom: 1rem;
      }
      td {
        text-align: left;
        border: 0;
        padding: 1.5rem;

        &.price {
          text-align: right;
        }
      }
    }
  }
  fieldset,
  .fieldset {
    background: color(background, grey);
    margin-bottom: 3rem;
    padding-bottom: 15px;

    h1,
    h3,
    h2 {
      @extend %panel-header;
      border: 0;
      padding: 2.5rem 1.5rem 0;
      text-align: center;
    }
    .col-sm-offset-4.col-md-offset-3.col-sm-8.col-md-9 {
      margin: 0;
    }
    .form-group {
      padding: 1.5rem 1.5rem 0;
      margin: 0 !important;

      .control-label {
        font-family: default(font-family, secondary);
        font-weight: default(weight, book);
        font-size: 1.4rem;
        margin-bottom: 5px;
      }
    }
  }
  // styles added with subscription
  .fieldset {
    .is-light {
      background: color(mono);
      &.padded {
        padding: 2rem 2rem 4rem;
        .form-group {
          @media (max-width: $xs-width) {
            padding-left: 0;
            padding-right: 0;
          }
        }
      }
    }
    &.padded {
      padding: 1rem 1.3rem;

      &.activate-new-subscription {
        padding: 4rem 6rem;
        text-align: center;
      }
    }

    @media (min-width: $md-width) {
      .is-light {
        &.padded {
          padding: 2rem 2.5rem 4rem;
        }
      }
      &.padded {
        padding: 1rem 6rem;
      }
    }
  }
}

.block-account {
  color: color(primary);
  h2 {
    margin-bottom: 15px;
  }

  .code-form {
    display: none;
    @media (min-width: $sm-width) {
      display: flex;
    }
  }

  #subscription_code {
    display: block;
    background-color: color(grey, light);
    border-radius: default(radius);
    border: 0;
    box-shadow: none;
    height: 5.8rem;
    font-size: 3rem;
    line-height: 1.2;
    padding: 0.5rem;
    text-align: center;
    width: 100%;
    margin: auto;
    letter-spacing: 1.5rem;
    box-sizing: border-box;

    @media (min-width: $md-width) {
      width: 4.3rem;
    }
    @media (min-width: $sm-width) {
      display: none;
    }
  }
  .col-sm-6 {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: $xs-width) {
      width: 50%;
    }

    h2,
    h3 {
      flex-grow: 0;
    }
    .one-line-bottom-margin,
    address,
    .dl-inline-items {
      flex-grow: 1;
    }
  }

  .subscription-info {
    margin: 3rem 0;
    h4 {
      font-weight: 700;
    }
    ul {
      margin: 1rem 0 2rem 0;
    }
  }

  .chronorelay-form {
    .form-group {
      padding: 1.5rem 0 2rem 0;

      .input-group {
        width: 100%;
        @media (min-width: 600px) {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
        }

        &-item {
          margin-bottom: 2rem;

          @media (min-width: 600px) {
            margin-bottom: 0;
          }

          &:nth-child(1) {
            @media (min-width: 600px) {
              width: 45%;
            }
          }

          &:nth-child(2) {
            @media (min-width: 600px) {
              width: 24%;
            }
          }
          &:nth-child(3) {
            margin-top: 4rem;
            @media (min-width: 600px) {
              width: 24%;
              margin-top: 0;
            }
          }
        }

        .form-control {
          float: none;
        }
      }

      .btn-search {
        width: 100%;
        position: relative;
        display: block;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  [data-ng-if="edit == subscription.id"] {
    .form-horizontal {
      margin: auto;
    }
  }

  [name="shippingAddressForm"] {
    max-width: 60rem;
    width: 100%;

    .btn {
      width: 100%;
      max-width: 40rem;
      margin-top: 2rem;
    }
  }
  [data-project-subscription-select-date=""] {
    text-align: center;
    .delivery_datepicker_ctn {
      margin-top: 1.5rem;
    }
    /* .uib-datepicker {
      display: inline-block;
      max-width: 100%;
      width: 37.5rem;
    } */
  }
  /*   [data-project-subscription-select-date=""] {

    text-align: center;

    .uib-daypicker {

      

      table {
        margin: 2rem auto;
        border: 1px solid color(border);

        th {
          vertical-align: top;
          border: 1px solid color(border);
        }
        td {
          border: 1px solid color(border);
        }
        tbody > tr > td:first-child {
          background: transparent; 
          color:color(mono);
          font-size: 0.9rem;
        }
        thead > tr:last-child th {
          border: 1px solid color(border);
          background: transparent; 
          color:color(mono, dark);
          font-size: 1.2rem !important;
          padding: 5px 3px !important
        }
      }
    
      .btn {
        border-width: 0;
        height: auto;
        font-size: 1.2rem !important;
        line-height: 1;
        padding: 5px 3px !important;
        padding: 1.5rem !important;
    
        .text-muted {
          opacity: 0.5;
        }
      }
      .btn-info.active {
        background: color(primary);
        .text-info {
          color: color(mono);
        }
      }
      
    }

    [data-ng-click="validateRelayAddress()"] {
      width: 32rem;
    }

    [data-ng-click="chooseFirstDelivery()"] {
      margin-top: 2rem;
    }
  }
 */
  .next-delivery {
    &-info {
      padding-bottom: 2rem;
      border-bottom: 2px solid color(border, lighter2);
      margin-bottom: 2rem;
      p {
        padding: 0.2rem 0;
      }
    }
    p {
      text-align: left;
    }

    .btn {
      margin-top: 1.5rem;
      margin-right: 1rem;
    }
  }

  .address-delivery {
    &-info {
      padding: 0 1.5rem;

      .btn {
        margin-top: 1rem;
        padding: 0 !important;
        text-transform: none;
        height: auto;
      }

      p {
        padding: 0.2rem 0;
      }
    }
  }
}

.chrono-relay-tabs {
  padding: 1rem 0 2rem 0;
  .chrono-relay-tab {
    font-size: 2rem;
    margin-right: 2rem;
    cursor: pointer;

    &.active {
      font-weight: 700;
      text-decoration: underline;
    }
  }
}

.chrono-relay-tab-content {
  display: none;

  &.active {
    display: block;
  }
  .list-group-item.active,
  .list-group-item.active:focus,
  .list-group-item.active:hover {
    background-color: color(background, grey);
    background-image: none;
    border-color: #ddd;
  }
}

#chrono-relay-map {
  .gm-style-iw {
    width: 400px !important;
    max-height: 150px !important;
    height: 150px !important;

    .gm-style-iw-d {
      max-height: 150px !important;
      height: 150px !important;

      .marker-popup-content {
        .text-center {
          padding-bottom: 1rem;
        }
      }

      td {
        padding-top: 1rem;
        padding-bottom: 1rem;
      }
    }
  }
}
