/* grid & space */
@media(min-width: $lg-width) {
  .ctn-fluid.padded {
    padding-left: 7%;
    padding-right: 7%;
  }
}

.fh {
  height: 100%;
}

.fw {
  width: 100%;
}

.nw {
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.upper {
  text-transform: uppercase;
}

.img-responsive {
  max-width: 100%;
}

.cover {
  background-size: cover;
}

.valign-middle {
  > * {
    display: inline-block;
    float: none;
    vertical-align: middle;
  }
}

.medium-ctn {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 107rem;
}

.small-ctn {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 65rem;
}

.xs-ctn {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  width: 45rem;
}

.unvisible {
  display: none;
}

/* styles */
.underline {
  text-decoration: underline;
}

.hidden {
  display: none;
}

.text-right {
  text-align: right;
}

/* bg color */
.bg-white {
  background-color: color(mono, light);
}

.bg-black {
  background-color: color(mono, dark);
  color: color(mono, light);
}

.bg-grey-light {
  background-color: color(background);
}

/* links */
.link {
  &:hover {
    text-decoration: underline;
  }
}

/* video iframe */
.video-ctn {
  margin-bottom: 15px;
  padding-bottom: 56.25%;
  position: relative;

  iframe {
    border: none;
    height: 100%;
    left: 0;
    margin: 0 0 0 0;
    overflow: hidden;
    padding: 0 0 0 0;
    position: absolute;
    top: 0%;
    width: 100%;
  }
}

.mention {
  font-size: 1.2rem;
  margin-bottom: .5rem;

  @media (min-width: $md-width) {
    font-size: 1.4rem;
  }
}

.quote-icon {
  position: relative;
  
  @media (min-width: $sm-width) {
    padding-left: 10%;
  }

  &:before {
    @include poa(0, null, null, 50%);
    content: "\e90f";
    color: color(secondary);
    display: inline-block;
    font-family: default(font-family, icon);
    font-size: 6rem;
    line-height: 1;
    transform: translate(-50%, -140%);

    @media (min-width: $sm-width) {
      font-size: 4em;
      left: 0;
      top: -1.5rem;
      transform: translate(0, 0);
    }

    @media (min-width: $md-width) {
      font-size: 5.5em;
      left: 0rem;
      top: -3.5rem;
    }
  }
}

address {
  font-size: 1.4rem;
  color: color(text);
  line-height: 1.2;
  margin-bottom: 10px;

  strong {
    @extend %panel-header;
  }
  > div {
    margin-bottom: 2px;
  }

  .half-line-top-margin,
  [data-rbs-geo-phone-formatter] {
    margin-top: 20px;

    a {
      color: color(text, dark);
    }
    img,
    abbr {
      display: none;
    }
  }
}

.block.smooth {
	opacity: 0;
	transform: translateY(65px);
  transition: all 1.2s cubic-bezier(0, 0, 0, 1) 0s;
   
  &.in-view {
    opacity: 1;
    transform: translateY(0);
  }
}

.img-thumbnail, .thumbnail {
  box-shadow: 0;
  border: 0;
  background-color: transparent;
}