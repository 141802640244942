.subscription-register {
  width: 100%;
  max-width: 1730px;
  margin: auto;
  display: flex;
  padding-top: 5rem;

  @media (min-width: 1290px) {
    width: 1290px;
  }

  .col-md-6 {
    width: 100%;
    margin-bottom: 7rem;

    @media (min-width: $md-width) {
      width: 50%;
      margin-bottom: 0;
    }
  }

  h1 {
    display: block;
    color: color(primary);
    font-size: 3rem;
    text-align: center;
    margin-top: -10rem;
    margin-bottom: 5rem;
  }

  .content,
  .ctn {
    border: 1px solid color(border, lighter2);
    padding: 5rem 0;
    form {
      width: 70%;
      margin: auto;
    }
  }

  input {
    border: 1px solid color(border, lighter2);
  }

  .custom-select {
    span {
      border: 1px solid color(border, lighter2);
      &::after {
        transform: rotate(180deg);
      }
    }
  }

  .col-sm-offset-4 {
    margin: auto;
  }

  .btn {
    width: 100%;
  }

  .ctn {
    width: auto;

    .xxs12 {
      width: 100% !important;
      max-width: 100% !important;
      flex-basis: 100%;
      padding: 0;
    }
  }

  label {
    font-family: default(font-family);
    font-size: 1.6rem;
    margin-bottom: 1rem !important;
    color: color(text, dark);
  }

  [data-layout-rbs-user-create-account] {
    [data-rbs-user-generic-fields] {
      .form-group {
        &:nth-child(n + 4) {
          display: none;
        }
      }
    }
  }

  .authenticated {
    text-align: center;
    p,
    a {
      font-weight: 500;
      font-size: 1.4rem;
      color: color(primary, base);
      line-height: 2rem;
    }
    p {
      margin-bottom: 1rem;
    }
    a {
      display: block;
      text-decoration: underline;
    }
  }
}
