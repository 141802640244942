.ctn-fluid,
.ctn {
  margin-left: auto;
  margin-right: auto;
}

.flex, .col, .row {
  box-sizing: border-box;
  display: flex;
  flex: 0 0 auto;
  flex-wrap: wrap;
  justify-content: space-between;
}

.row {
  flex-direction: row;
  &:before, &:after {
    display: block; 
  }
}

.row.reverse {
  flex-direction: row-reverse;
}

.col {
  flex-direction: column;
}

.col.reverse {
  flex-direction: column-reverse;
}

.wrap {
  flex-wrap: wrap;
}

.nowrap {
  flex-wrap: nowrap;
}

@mixin flex-styles($breakpoint, $data, $gutter) {
  $container: map-get($data, container);
  $gutter: map-get($data, gutter);

  .ctn-fluid, .ctn {
    padding-right: #{$gutter/2};
    padding-left: #{$gutter/2};
  }
  .ctn-fluid {
    max-width: $maxContainer;
  }
  .ctn {
    width: $container;
    max-width: $maxContainer;
  }
  .row,
  .col {
    margin-right: -#{$gutter/2};
    margin-left: -#{$gutter/2};
  }

  .row-#{$breakpoint} {
    box-sizing: border-box;
    display: flex;
    flex: 0 0 auto;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: row;
  }

  .col-#{$breakpoint} {
    flex-direction: column;
  }

  @for $i from 1 through $grid-columns {
    .#{$breakpoint}#{$i}, .offset-#{$breakpoint}#{$i} {
      @extend %flex-global-styles-#{$breakpoint};
    }
    .#{$breakpoint}#{$i} {
      flex-basis: #{$column-width * $i+'%'};
      max-width: #{$column-width * $i+'%'};

    }
    .offset-#{$breakpoint}#{$i} {
      margin-left: #{$column-width * $i+'%'};
    }
  }

  .#{$breakpoint} {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding-right: #{$gutter/2};
    padding-left: #{$gutter/2};
  }

  
  .start-#{$breakpoint} {
    justify-content: flex-start;
    text-align: start;

  }
  
  .center-#{$breakpoint} {
    justify-content: center;
    text-align: center;
  }
  
  .end-#{$breakpoint} {
    justify-content: flex-end;
    text-align: end;
  }
  .start-#{$breakpoint},
  .center-#{$breakpoint},
  .end-#{$breakpoint},
  .top-#{$breakpoint},
  .middle-#{$breakpoint},
  .bottom-#{$breakpoint},
  .around-#{$breakpoint},
  .between-#{$breakpoint} {
    &:before, &:after {
      display: none;
    }
  }
none
  .around-#{$breakpoint} {
    justify-content: space-around;
  }
 
  .between-#{$breakpoint} {
    justify-content: space-between;
  }
 
  .top-#{$breakpoint} {
    align-items: flex-start;
    align-self: start;
  }

  .middle-#{$breakpoint} {
    align-items: center;
    align-self: center;
  }

  .bottom-#{$breakpoint} {
    align-items: flex-end;
    align-self: end;
  }

  .first-#{$breakpoint} {
    order: -1;
  }

  .last-#{$breakpoint} {
    order: 1;
  }

  .hide-#{$breakpoint} {
    display: none;
  }

  .show-#{$breakpoint} {
    display: block;
  }

  .show-ib-#{$breakpoint} {
    display: inline-block;
  }

  %flex-global-styles-#{$breakpoint} {
    box-sizing: border-box;
    padding-left: #{$gutter/2};
    padding-right: #{$gutter/2};
  }

  .nopadding-#{$breakpoint} {
    padding: 0;
  }
}

@each $breakpoint, $data in $breakpoints {
  $min-width: map-get($data, min-width);
  $gutter: map-get($data, gutter);

  @if ($min-width) {
    @media (min-width: $min-width) {
      @include flex-styles($breakpoint, $data, $gutter);
    }
  } @else {
    @include flex-styles($breakpoint, $data, $gutter);
  }

}

.nogrow {
  flex-basis: 0;
  flex-grow: 0;
}

.grow {
  flex-basis: 0;
  flex-grow: 1;
}

@for $i from 0 through 5 {
  .grow#{$i} {
    flex-grow: #{$i};
    flex-basis: 0;
  }
}