// Import des styles communs
@import '_communs/_init/_variables';
@import '_communs/_init/_reset';
@import '_communs/_init/_mixins';
@import '_communs/_init/_placeholders';
@import '_communs/_init/_flexivarious';
@import '_communs/_k2000/_alert';
@import '_communs/_k2000/_typo';
@import '_communs/_k2000/_block';
@import '_communs/_k2000/_helper';
@import '_communs/_k2000/_breadcrumb';
@import '_communs/_k2000/_btn';
@import '_communs/_k2000/_form';
@import '_communs/_k2000/_owl';
@import '_communs/_k2000/_modal';
@import '_communs/_k2000/_page-heading';
@import '_communs/_k2000/_panels';
@import '_communs/_k2000/_popover';
@import '_communs/_k2000/_product-ctn';
@import '_communs/_k2000/_product-row';
@import '_communs/_k2000/_table-div';
@import '_communs/_k2000/_navbar';
@import '_communs/_layout';
@import '_communs/_plugins';

// Import styles de pages
@import '_pages/_404';
@import '_pages/_authentication';
@import '_pages/account/_menu-account';
@import '_pages/account/_account';
@import '_pages/account/_order-detail';
@import '_pages/account/_subscription';
@import '_pages/_category';
@import '_pages/_contact-form';
@import '_pages/_cms';
@import '_pages/_index';
@import '_pages/order-process/_order-process';
@import '_pages/order-process/_order-summary';
@import '_pages/order-process/_order-steps';
@import '_pages/_product';
@import '_pages/_manufacturer';
@import '_pages/_sitemap';
@import '_pages/_stores';