
.order {
  .richtext {
    color: color(primary);
  }
  .bg-grey {
    background-color: transparent;
    padding: 1.5rem 0;
    border: 1px solid color(border, lighter2);
  }
  .header-main {
    height: 100px;
    @media (min-width: $sm-width) {
      height: 155px;
    }
    .header-top {
      height: auto;
    }
    .header-logo {
      flex-basis: 0;
      flex-grow: 0;
      a span {
        font-size: 4.5rem;
        @media (min-width: $sm-width) {
          font-size: 10rem;
        }
      }
    }
  }
  .site-content {
    padding-top: 100px;

    @media (min-width: $sm-width) {
      padding-top: 160px;
    }
  }
}

.cart-empty,
.order-process h1,
[data-rbs-commerce-cart-deliveries=""] h1 {
    padding: 25px 15px;
    margin-bottom: 30px;
    text-align: center;
    border-bottom: 1px solid color(primary, light);

    .subtitle {
      margin-top: 40px;
      color: color(secondary);
      letter-spacing: 2px;
      margin-bottom: 20px;
      text-transform: uppercase;
      @include rem(15);
    }
}
.cart-empty h1,
.order-process h1,
[data-rbs-commerce-cart-deliveries=""] h1 {
  color: color(text, dark);
  @include rem(50);
  @media (max-width: $sm-width) {
    @include rem(25);
  }
}

[data-rbs-commerce-process-delivery-lines] {
  & > .row {
    .col-sm-6 {
      width: 100%;

      @media (min-width: $xs-width) {
        width: 50%;
      }

      & + .col-sm-6 {
        margin-top: 15px;
      }
    }
  }
}

.order-process,
[data-rbs-commerce-cart-deliveries=""] {

  [data-rbs-commerce-cart-delivery=""] {
    margin-bottom: 15px;
    h3 {
      display: none;
    }

    [data-ng-if="showAllAmounts"] {
      .btn {
        display: none;
      }
      .vertical-show-hide-animation {
        display: block !important;
        height: auto !important;
      }
    }
  }

  .table-vertical-responsive {
    overflow-x: visible;
  }

  .table > thead > tr {
    display: none;
    @media (min-width: $sm-width) {
      display: table-row;
    }

    > th {
      padding-left: 0;
      padding-right: 0;

      &:first-child {
        padding-left: 25px;
      }
      &:last-child {
        padding-right: 25px;
      }
    }
  }
  .table > tbody > tr {
    display: block;
    margin-bottom: 15px;
    margin-left: -$gutter/2;
    margin-right: -$gutter/2;
    position: relative;
    border-bottom: 1px solid color(grey, light);
    min-height: 70px;

    @media (min-width: $sm-width) {
      margin-left: 0;
      margin-right: 0;
      display: table-row;
    }

    > td { 
      border: 0;
      display: block;
      padding: 0 15px 2px 100px;

      @media (min-width: $sm-width) {
        display: table-cell;
        padding: 15px 0;
        vertical-align: middle;
      } 
      @media (min-width: $md-width) {
        padding: 25px 0;
      }

      &.line-visual {
        @include poa(0, null, null, 0);
        padding: 0;
        width: 70px;
        padding-right: 15px;
        padding-left: 15px;
        box-sizing: content-box;

        @media (min-width: $sm-width) {
          padding: 15px 15px 15px 0;
          position: static;
        }
        @media (min-width: $md-width) {
          padding: 25px 30px 25px 0;
        }
    
        img {
          display: block;
          max-width: 100%;
          height: auto;

          @media (min-width: $md-width) {
            max-width: 85px;
          }
        }
      }
      &.line-detail {
        padding-right: $gutter/2;
        max-width: 370px;
      }
      &.line-prices {
        padding-right: 0;

        @media (min-width: $sm-width) {
          padding-right: 10px;
        }

        tr {
          margin-left: 0;
          margin-right: 0;
        }
      }

      &.main-price {
        font-weight: 500;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 5px;

        @media (min-width: $sm-width) {
          justify-content: flex-start;
          margin-top: 0;
          margin-left: 1rem;
        }

        .btn-responsive-toolbar {
          font-size: 0;
          line-height: 0;
        }

        .btn-icon {
          padding: 15px;
          font-size: 14px;
          list-style: 1;
          margin: 0;
          background-color: transparent;
          border: 0;
        }
      }
      &.hidden-xs {
        padding: 0;
      }
    }
  } 
  .line-details,
  .line-detail {
    li {
      margin-top: 10px;
    }
    li:first-child {
      @include heading(color(text, dark), 12, 0, false, true);
      font-family: default(font-family, secondary);
      margin-top: 0;

      @media (min-width: $md-width) {
        font-size: 1.5rem !important;
      }
    }
    a,
    dl {
      display: none;
    }
  }
  .line-quantity {
    display: inline-block !important;
    width: 65%;

    @media (min-width: $sm-width) {
      display: table-cell !important;
      width: auto;
    }

    & > .row {
      justify-content: flex-start;
      margin-top: 5px;
      
      .form-inline {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        input {
          width: 70px !important;
          text-align: center;

          @media (min-width: $lg-width) {
            width: 100px !important;
          }
        }
      }

      .col-xs-6 {
        width: 100%;
        float: none;
      }
    }

    .btn-responsive-toolbar {
      display: flex;
      flex-flow: column wrap;
      align-items: flex-start;
      @media (min-width: $xs-width) {
        flex-direction: row;
      }

      a {
        @extend .btn-link;
        @extend .inverse;
        margin-bottom: 10px;
        @media (min-width: $xs-width) {
          margin-left: 10px;
        }
        @media (min-width: $sm-width) {
          margin-left: 20px;
        }
      }
      .btn {
        @extend .first-xxs;
        @extend .btn-link;
        border: 0;
        height: auto;
        margin-bottom: 0;
        span {
          display: none;
        }
      }
    }
    [data-ng-click="updateQuantity()"] {
      color: color(text, dark);
      background: none;
      border: 0;
      padding: 0 0 0 15px;
    }
    .form-control {
      border: 1px solid color(border, lighter2);
      height: 35px;
      padding: 0 10px;
      width: 100%;
      border-radius: 0;
      background: none;
    }
  }

  .line-prices {
    @include rem(15);
    font-family: default(font-family, secondary);
    color: color(text, dark);
    display: inline-block !important;
    padding-left: 0 !important;
    width: 33%;

    @media (min-width: $sm-width) {
      padding: 15px 0 !important;
      display: table-cell !important;
      width: auto;
    }

    .table,
    .table > tbody > tr {
      margin-bottom: 0;
      border: 0
    }
  }

  .table-amounts {
    margin-bottom: 15px;
    & > tbody > tr {
      border: 0;
      display: table-row;
      border-bottom: 1px solid color(grey, light);
      
      & > td {
        display: table-cell;
        padding: 10px 0;
        @media (min-width: $md-width) {
          padding: 15px 0;
        }

        &:first-child {
          text-align: left;
        }
      }
      &.recap-schedule {
        border: 0;
      
        td {
          padding: 0;
          p {
            margin: 0;
          }
        }
      }
    }
  }

  @media (min-width: $sm-width) {
    [data-ng-if="showPrices"] {
      > .col-md-6 {
        width: 58.33333%;
        & + .col-md-6 {
          width: 41.66667%;
        }
      }
    }
  }
  .cta {
    .checkbox {
      text-align: left;
      .custom-checkbox {
        left: 0;
        position: absolute;
        top: 0;
      }
    }
    .btn {
      width: 100%;
    }
  }
}

.aside-content {
  padding: 1.5rem 1.5rem 2.5rem;
  border: 1px solid color(primary, light);

  @media (min-width: $md-width) {
    padding: 0.5rem 2.5rem 2.5rem;
  }

  .btn-lg {
    display: block;
  }
}

.order-process {
  hr {display: none;}
  h1 {
    margin-bottom: 0;
    display: none;
  }
  h2 {
    margin: 25px 0 10px 0;
    text-align: center;
    
    @media (min-width: $md-width) {
      margin: 40px 0 20px 0;
      text-align: left;
    }

  }
  h3 {
    @extend %panel-header;
    text-align: center;
  }
  hr {
    border: 0;
  }
  .process-title {
    padding: 20px 0;
    font-size: 30px;
    text-align: center;
    border-bottom: 1px solid color(primary, light);

    @media (min-width: $md-width) {
      padding: 25px 0;
      font-size: 50px;
    }
  }
  #identification {
    h1 {
      border-bottom: 1px solid color(border, lighter2);
    }
    h2 {
      display: none;
    }
    h2 ~ div > .row > div {
      width: auto;
      flex: 0 0 50%;
  
      &:empty {
        display: none;
      }
      @media (max-width: $sm-width) {
        flex: 0 0 100%;
      }
    }
    .process-field-remember-me {
      margin-bottom: 0;
    }
    .process-button-next-step {
      margin-top: 15px;
      width: 100%;
    }
    [name="confirmMobilePhone"] {
      margin-bottom: 15px;

      .process-field-token {
        margin: 0 15px 0 0 !important;

        @media (max-width: $sm-width) {
          margin: 15px 0 0 0 !important;

          input {
            width: 100% !important;
            margin-bottom: 15px; 
          }
        }
      }

      @media (max-width: $sm-width) {
        .btn {
          width: 100%;
          margin-bottom: 15px;
        }
      }
    }

    .panel {
      background-color: transparent;

      &-heading {
        background-color: transparent;
        font-family: default(font-family, tertiary);
        font-weight: default(weight);
        text-transform: none;
        margin-bottom: 1rem;
        font-size: 2rem;

        @media (min-width: $md-width) {
          font-size: 3rem;
        }

      }
      &-body {
        background-color: transparent;
        border: 1px solid color(border, lighter2);
        padding: 2rem 1.5rem;
        position: relative;

        @media (min-width: $md-width) {
          padding: 4rem 10rem;
        }

        .control-label {
          font-family: default(font-family);
          font-size: 1.6rem;
          margin-bottom: 0.4rem;
          color: color(text, dark);
        }

        .checkbox {
          margin-top: 1.5rem;
          
          label {
            font-family: default(font-family);
            font-size: 1.6rem;
            color: color(text, dark);
          }
        }

        .form-control {
          height: 3.5rem;
          border: 1px solid color(border, lighter2);
          padding: 1rem;
        }

        .custom-select>span {
            border: 1px solid color(border, lighter2);
            height: 3.5rem;
            line-height: 3.5rem;
            padding: 0 3.5rem 0 1rem;

            &.open:after {
              background-color: color(grey, dark);
              top: 17px;
          }
        }

        [data-rbs-user-forgot-password=""] {
          float: none;
          position: absolute;
          bottom: 2rem;
          left: 1.5rem;

          @media (min-width: $sm-width) {
            left: 10rem;
            bottom: 6rem;
          }

          a {
            text-decoration: underline;
            color: color(secondary);
            font-size: 1.4rem;

            @media (min-width: $sm-width) {
              font-size: 1.6rem;
            }
          }
        }
      }

      &.process-identification-login {
        .panel-body {
          padding-bottom: 5rem;

          @media (min-width: $sm-width) {
            padding-bottom: 10rem;
          }
        }
      }
    }
  }
  .process-identification-summary {
    background: color(background, grey);
    padding: 25px 5px;
    position: relative;
    margin-top: 1rem;
    display: none;

    &.row {
      margin: 0;

      > div {
        position: static;
        width: 100%;
      }
    }
    [data-rbs-commerce-customer-generic-fields-readonly] {
      strong {
        @extend %panel-header;
      }
    }

    .btn {
      @extend .btn-link;
      @include poa(1.5rem, 2rem);
      height: auto;
    }
  }

  [data-ng-if="!processData.addressEdition"] {
    .btn {
      @extend .btn-link;
      @include poa(null, 2rem, 0.5rem);
      height: auto;
    }
  }

  .process-address-summary {
    background: color(background, grey);
    padding: 25px 20px;
    position: relative;

    .col-md-4 {
      margin-bottom: 3rem;
      width: 100%;
    }

    .btn-link {
      @include poa(null, 2rem, 1.5rem);

      @media (min-width: $sm-width) {
        bottom: auto; 
        top: 1.5rem;  
      }
    }
  }

  .mode-selector {
    color: color(text, dark);
    margin-top: 1.5rem; 

    &-main {
      background: color(background, grey);
      padding: 1.5rem 1rem;
      
      @media (min-width: $sm-width) {
        padding: 1.5rem ;
      }

      label {
        cursor: pointer;
        @include heading(color(text, dark), 14, 0, true, true);
        font-family: default(font-family, secondary);
        margin: 0;
        padding: 0;
        float: left;
      }
      [data-ng-if="showPrices"] {
        font-family: default(font-family, secondary);
        font-size: 1.2rem;
        font-weight: 500;
        
        @media (min-width: $sm-width) {
          font-size: 1.5rem;
        }

        span:first-child {
          display: none;
        }
      }

      .custom-radio {
        margin-top: 0;
        order: -1;
      }
    }

    &-img {
      order: 1;
      margin: 0 1.5rem 0 0.5rem;

      @media (min-width: $xs-width) {
        order: unset
      }
    }

    &-title, &-description {
      display: block;
      font-size: 1.2rem;
      font-family: default(font-family);
      font-weight: 500;
      text-transform: none;

      @media (min-width: $xxs-width) {
        font-size: 1.4rem;
      }
    }

    &-description {
      color: color(text);
      margin-top: 1rem;
    }

    &-options {
      &-inner {
        border: 1px solid color(border);
        padding: 1.5rem 2rem 0.5rem 2rem;
      }

      .date-specific, .date-asap {
        padding-left: 26px;

        .custom-radio {
          position: absolute;
          left: 0;

          label {
            font-weight: bold;
          }
        }
        .asapDateTime {
          padding-top: 20px;
        }
      }
    }

    [data-rbs-commerce-wish-customer-receipt-date] {
      background: color(background, grey);
      padding: 1.5rem 1rem;
      padding-top: 0;
      
      @media (min-width: $sm-width) {
        padding: 1.5rem ;
        padding-top: 0;
      }
      .form-ctrl-ctn {
        margin-right: 15px !important;
        margin-top: 0 !important;
        .control-label, label {
          padding: 0;
        }
      }
      .form-inline { 
        > div {
          @extend .flex;
          @extend .start-xxs;
          @extend .top-xxs;

          margin-bottom: 10px;

          .custom-radio {
            @extend .first-xxs;
            margin-top: 0
          }
        }
      }
    }
  }

  [rbs-shipping-mode-summary] {
    .small {
      margin-bottom: 5px;
      font-size: 14px;
    }
    .chrono .receipt-time {
      display: none;
    }
    label {
      @extend %panel-header;
      padding: 0;
    }
    p {
      color: color(text, dark);

      > strong {
        font-weight: default(weight);
      }
    }
  }

  #shipping {
    position: relative;

    .ng-scope > h2 {
      @extend .order-process-title;
    }

    [data-rbs-commerce-summary-shipping-at-home-step] {
      address {
        margin: 0;
      }
    }
    [data-ng-if="!processData.isCurrent"],
    .process-shipping-deliverysummary {
      display: none !important;
    }
    @media(max-width:767px){
      .process-shipping-deliverysummary + div { 
        .process-button-edit-step {
          margin-top: -8rem;
          margin-right: 2rem;
        }
      }
    }

    .form-group, .form-ctrl-ctn {
      margin-top: 10px !important;

      @media(min-width: $sm-width) {
        margin-top: 25px !important;
      }
    }

    .control-label {
      font-family: default(font-family);
      font-size: 1.6rem;
      margin-bottom: 0.4rem;
      color: color(text, dark);
    }

    .form-control {
      height: 3.5rem;
      border: 1px solid color(border, lighter2);
      padding: 1rem;
    }

    .custom-select>span {
        border: 1px solid color(border, lighter2);
        height: 3.5rem;
        line-height: 3.5rem;
        padding: 0 3.5rem 0 1rem;

        &.open:after {
          background-color: color(grey, dark);
          top: 17px;
      }
    }

    .has-success {
      .control-label {
        color: #3c763d;
      }
  
      .form-control {
        border: 1px solid #3c763d;
      }
    }

  }

  #payment {
    .process-payment-coupons {
      display: none;
    }
    [data-ng-if="paymentInfo.started"] {
      [data-ng-click="cancelPayment()"] {
        top: -90px;
        right: 20px;
      } 
    }
    
    h2 {
      @extend .order-process-title;
    }

    [data-rbs-commerce-cart-line-visual] {
      a {
        pointer-events: none;
        cursor: default;
      }
    }

    [data-rbs-commerce-payment-step-transaction] {
      .panel-heading {
        padding-bottom: 1.5rem;
        .price {
          display: none;
        }
      }
      .list-group {
        background: color(background, grey);
        @extend .row;
        border-radius: 0;
        padding: 0 15px;
        
        .list-group-item {
          width: 50%;
          background: none;
          @include heading(color(text, dark), 15, 0, false, false);
          border : 0;
          text-shadow: none;
          margin: 0 0 15px ;
          position: relative;

          > div {
            padding: 0 15px;
            .col-sm-7 {
              @extend .flex;
              @extend .start-xxs;
              @extend .middle-xxs;

              .custom-radio {
                @extend .first-xxs;
                margin-top: 1px;
              }
            }
          }

          .col-sm-5.col-md-4.col-lg-3 {
            text-align: right;
          }
          .col-sm-7.col-md-8.col-lg-9 {
            
            br {
              display: none;
            }
            .small {
              display: none;
            }
          }
          &:before {
            content: '';
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            position: absolute;
            z-index: 0;
          }

          .connector-title {
            font-size: 14px;
            font-weight: bold;
            padding: 0 0 15px !important;
            margin: 0 0 20px;
            border-bottom: 1px solid color(border, lighter2);

            @media(min-width: $sm-width) {
              font-size: 16px;
            }

            .icon {
              margin-right: 5px;
            }

            .custom-radio {
              margin-top: 0;
            }
          }

          .connector-assets {
            margin: 0 0 20px;

            &::after {
              content: "";
              display: block;
              clear: both;
              width: 100%;
            }

            img {
              max-width: 180px;
            }

            .price {
               color: color(secondary);
               font-size: 25px;
               font-family: default(font-family, secondary);
               font-weight: default(weight, book);
            }
          }
        }
      }
    }
  }
}
.payment-cgv {
  background: color(grey, light);
  padding: 10px 10px 10px 36px;
  position: relative;

  @media(min-width: $sm-width) {
    padding: 15px 20px 15px 46px;
  }

  .checkbox {
    position: static;
    margin: 0;
    padding: 0;

  }
  label {
    min-height: auto;
  }
  .custom-checkbox {
    top: 10px !important;
    left: 10px;

    @media(min-width: $sm-width) {
      top: 15px !important;
      left: 20px;
    }
  }

  p {
    font-size: 12px;
    color: color(primary);
    font-family: default(font-family);
    font-weight: default(weight, book);

    @media(min-width: $sm-width) {
      font-size: 16px;
    }
  }
  a {
    text-decoration: underline;
  }

}
[data-rbs-commerce-shipping-at-home-step] [data-ng-hide="edition"] {
  .row {
    justify-content: flex-start;
    text-align: start;

    > div {
      margin: 5px 0;
      width: 100%;

      .btn {
        @media (min-width: $lg-width) {
          padding: 14px 10px;
          font-size: 1.4rem;
        }
      }
    }
  }
}
[rbs-storeshipping-store-mode-editor] {
  > div {
    background: color(background, grey);
    padding: 15px;

    [data-rbs-storeshipping-product-locator] {
      background: color(background);
      padding: 15px;
      margin-top: 25px;

      .storeshipping-stores-list {
        margin-top: 25px;
  
        h4 {
          display: none;
        }
        .storeshipping-stores-list-item {
          border-top: 1px solid color(border, lighter);
          padding: 15px 0;
          margin: 0 -15px;
    
          .glyphicon-star {
            display: none;
          }
          .opening-hours {
            margin-top: 15px;
            font-size: 1.8rem;
          }
        }
      }
    }
  }
}
[data-ng-if="!simplifiedProcess && isStepEnabled('shipping')"] {
  
  .custom-select > ul {
    max-height: 130px;
  }
  &[data-project-gtm-process-step] {
    padding-bottom: 0;
  }
  @media (min-width: $sm-width) {
    padding-bottom: 5rem;
  }
}

[data-rbs-commerce-shipping-at-home-step] [data-ng-show="edition"],
.process-payment-amounts [data-ng-if="processData.addressEdition"] {

  .bg-info {
    background: none;
  }
  > div > div {
    position: relative;
    color: color(text, dark);
    margin: 5px 0;
    width: 100%;

    @media (min-width: $sm-width) {
      margin: 10px 15%;
      width: 70%;
    }

    &.clearfix {
      height: 0;
      width: 0;
    }

    > * {
      z-index: 2;
      position: relative;
      padding: 0 15px;
    }

    h4 {
      margin-top: 15px;
      @include heading(color(text, dark), 15, 15, true, true);
    }
    > p {
      margin-bottom: 15px;
      .btn {
        width: 100%;
      }
    }

    
    &[data-rbs-geo-address-editor] {
      label {
        width: 100% !important;
        margin-bottom: 5px;
        display: block;
        text-align: left;
      }
      &:before {
        background: none;
      }
    }
  }
}

[data-ng-if="processData.addressEdition"] {
  margin-top: -42px;
  position: relative;
  z-index: 2;
}

.process-payment-amounts [data-ng-if="!processData.addressEdition"] {
  @media (max-width: $xs-width) {

    padding-bottom: 0;

    .row {
      .col-md-4, .col-md-8 {
        width: 100%;
      }
    }
  }
  padding-bottom: 80px;

  @media (min-width: $xs-width) {
    button[data-ng-if="!paymentInfo.started"] {
      position: absolute;
      margin-top: 5px;
    }
  }
}

.process-payment-coupons,
.coupon {

  .coupons-label {
    position: relative;
    padding: 0 2.5rem 0 0;
    cursor: pointer;
    word-break: break-word;

    .icon {
      position: absolute;
      right: 0;
      top: 0.4rem;
      transform: rotate(180deg);
      transition: 0.2s ease;
    }
  }
  
  &.open {
    .coupons-label {
      .icon {
        transform: none;
      }
    }

    .coupons-form {
      max-height: 400px;
      opacity: 1;
      padding-top: 15px;
    }
  }

  .coupons-form {
    max-height: 0;
    transition: 0.3s ease;
    opacity: 0;
    overflow: hidden;
    margin-top: 0 !important;
    padding-top: 0;
  }

  .form-control {
    padding: 0 15px;
    height: 35px;
    border-color: color(border, lighter2);
    border-right: 0;
  }

  .form-group {

    label {
      width: 100%;
      text-align: left;
      text-transform: none;
      font-weight: normal;
      margin-bottom: 10px;

      & + div {
        width: 100%;

        .btn {
          @extend .btn-primary;
          height: 30px;
          padding: 2px 25px 0;
          margin-left: 15px;

          &-default {
            line-height: 1;
            height: auto;
            padding: 5px;
            font-size: 0.8rem;
          }

          span {
            display: none;
          }
        }
      }
    }

    .input-group .input-group-btn .btn-secondary {
      border-color: transparent;
      font-size: 1.4rem;
      height: 35px;
      padding: 0.2rem 2rem 0 2rem;

      &:hover {
        border-color: color(secondary)
      }
    }
  }

  .glyphicon-remove { 
    display: block !important;

    &:before {
      @include size(8);
      content: "\e908";
      display: block;
      font-family: default(font-family, icon);
    }
  }

  .coupon_row {
    position: relative;
    padding-right: 5rem;

    .btn-icon {
      position: absolute;
      top: 3px;
      right: 0;
      padding: 0 15px;
      font-size: 14px;
      line-height: 0;
      margin: 0;
      background-color: transparent;
      border: 0;
    }
  }
}
.process-payment-coupons {
  margin-top: 0;
}

[data-rbs-user-forgot-password=""] {
  float: right; 
  margin-top: 10px;
}


[data-ng-click="savePayment()"] {
  height: auto;
  white-space: inherit;
}
 
@media (max-width: $xs-width) {
  [data-rbs-commerce-shipping-at-home-step] [data-ng-show="edition"] .bg-info, 
  .process-payment-amounts [data-ng-if="processData.addressEdition"] .bg-info {
    width: 100%;
    margin-bottom: 10px;
  }
  [data-shipping-mode="shippingModeData"] {
    .col-md-4, .col-md-8 {
      margin: 0 0 10px 0;
      width: 100%;
    }
    .col-md-8 {
      padding-bottom: 6rem
    }
  }
  .process-payment-amounts {
    .col-md-4, .col-md-8 {
      margin: 0 0 10px 0;
      width: 100%;
    }
    .col-md-8 {
      border-bottom: 20px solid #fff; 
      order: -1;
    }
    .col-md-4 {
      padding-top: 15px;
    }
  }
  .order-process #payment [data-rbs-commerce-payment-step-transaction] .list-group .list-group-item {
    width: 100%;
    margin: 0 0 15px 0;

    &:first-child {
      margin-top: 15px;
    }
  }

  [data-name="Rbs_Commerce_PaymentReturn"] { 
    .row > div {
      flex: 0 0 50%;
    }
    h4 {
      color: color(primary);
      margin-bottom: 10px;
    }
  }
}

[uib-datepicker-popup-wrap]  {
  .dropdown-menu {
    box-shadow: none;
    padding: 5px;
  }

  .uib-button-bar {
    padding: 5px 0;
    .btn {
      border-width: 1px;
      height: auto;
      font-size: 1.1rem;
      line-height: 1;
      padding: 6px 10px;
      margin: 0 2px;
    }
  }
}

.ng-invalid, .ng-invalid-mobile-number {
  > .input-group  {
    input {
      border-color: #a94442;
      box-shadow: inset 0 1px 1px rgba(0,0,0,.075)
    }
    .input-group-btn {
      button {
        border-color:#a94442;
      }
    }
  }
  
}

[uib-datepicker],
[uib-daypicker] {
  table {
    th {
      vertical-align: top;
    }
    td {
      border: 1px solid color(primary);
    }
    tbody > tr > td:first-child {
      background: color(primary); 
      color:color(mono);
      font-size: 0.9rem;
    }
    thead > tr:last-child th {
      border: 1px solid color(primary);
      background: color(primary); 
      color:color(mono);
      font-size: 1.2rem !important;
      padding: 5px 3px !important
    }
  }

  .btn {
    border-width: 0;
    height: auto;
    font-size: 1.2rem !important;
    line-height: 1;
    padding: 5px 3px !important;

    .text-muted {
      opacity: 0.5;
    }
  }
  .btn-info.active {
    background: color(primary);
    .text-info {
      color: color(mono);
    }
  }
}

[data-rbs-commerce-shipping-mode-gift] label {
  font-weight: 400;
}

[data-rbs-commerce-shipping-fees-evaluation] {
  display: none !important
}
#gift {
  display: none;
  @media (max-width: $xs-width){
    .process-gift-summary {
      .panel-body {
        padding-bottom: 7rem;
      }
      + div .process-button-edit-step {
        margin-top: -8rem;
        margin-right: 2rem;
      }
    }
  }
}

.stripe {
  padding: 0;

  /* Layout */
  .sr-root {
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    height: auto;
    padding: 0;
    margin: 0 auto;
  }
  .sr-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-self: center;
    padding: 0;
    background: transparent;
    width: 100%;
    border-radius: 0;
    box-shadow: none;
  }

  .sr-field-error {
    color: rgb(105, 115, 134);
    text-align: left;
    font-size: 13px;
    line-height: 17px;
    margin-top: 15px;
    margin-bottom: 5px;

    @media (min-width: $md-width){
      margin-top: 25px;
      margin-bottom: 5px;
    }
  }

  /* Inputs */
  .sr-input,
  input[type="text"] {
    border: 1px solid color(border, lighter2);
    border-radius: 0;
    padding: 5px 12px;
    height: 34px;
    width: 100%;
    transition: box-shadow 0.2s ease;
    background: white;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    font-family: default(font-family);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.2rem;
    margin-top: 15px;

    @media (min-width: $md-width){
      margin-top: 20px;
      font-size: 1.4rem;
    }
  }
  .sr-input:focus,
  input[type="text"]:focus,
  button:focus,
  .focused {
    box-shadow:0 0 10px 0px rgba(0, 0, 0,0.15);
    outline: none;
    z-index: 9;
  }
  .sr-input::placeholder,
  input[type="text"]::placeholder {
    color: #aab7c4;
  }
  
  .sr-result {
    height: 34px;
    -webkit-transition: height 1s ease;
    -moz-transition: height 1s ease;
    -o-transition: height 1s ease;
    transition: height 1s ease;
    color: rgb(105, 115, 134);
    overflow: auto;
  }
  .sr-result code {
    overflow: scroll;
  }
  .sr-result.expand {
    height: 350px;
  }

  .sr-combo-inputs-row {
    box-shadow: none;
    border-radius: 0;
  }

  /* Buttons and links */
  button {
    border: 0.2rem solid color(primary);
    border-radius: 0;
    box-shadow: none;
    cursor: pointer;
    display: inline-block;
    font-family: default(font-family, primary);
    font-size: 1.4rem;
    height: 44px; 
    letter-spacing: 1px;
    overflow: hidden;
    padding: 12px 10px 10px 10px;
    position: relative;
    text-decoration: none;
    text-transform: uppercase;
    text-shadow: none;
    transition: all 0.3s ease 0s;
    z-index: 3;
    background: color(primary);
    color: color(mono);
    width: 100%;

    @media (min-width: $md-width) {
      font-size: 1.6rem;
      height: 50px; 
      padding: 14px 30px 12px 30px;
    }

    &:hover,
    &:active {
      background-color: color(primary, dark);
      border-color: color(primary, dark);
      color: color(mono);
      text-decoration: none;
      text-shadow: none;
    }
  }
  button:disabled {
    opacity: 0.5;
    cursor: none;
  }

  a {
    color: #fff;
    text-decoration: none;
    transition: all 0.2s ease;
  }

  a:hover {
    filter: brightness(0.8);
  }

  a:active {
    filter: brightness(0.5);
  }

  /* Code block */
  code,
  pre {
    font-family: "SF Mono", "IBM Plex Mono", "Menlo", monospace;
    font-size: 12px;
  }

  /* Stripe Element placeholder */
  .sr-card-element {
    padding-top: 7px;
  }

  /* Responsiveness */
  @media (max-width: 720px) {
    padding: 0;

    .sr-header__logo {
      background-position: center;
    }

    .sr-payment-summary {
      text-align: center;
    }

    .sr-content {
      display: none;
    }

  }

  /* todo: spinner/processing state, errors, animations */

  .spinner,
  .spinner:before,
  .spinner:after {
    border-radius: 50%;
  }
  .spinner {
    color: #ffffff;
    font-size: 22px;
    text-indent: -99999px;
    margin: 0px auto;
    position: relative;
    width: 20px;
    height: 20px;
    box-shadow: inset 0 0 0 2px;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  .spinner:before,
  .spinner:after {
    position: absolute;
    content: "";
  }
  .spinner:before {
    width: 10.4px;
    height: 20.4px;
    background: #556CD6;
    border-radius: 20.4px 0 0 20.4px;
    top: -0.2px;
    left: -0.2px;
    -webkit-transform-origin: 10.4px 10.2px;
    transform-origin: 10.4px 10.2px;
    -webkit-animation: loading 2s infinite ease 1.5s;
    animation: loading 2s infinite ease 1.5s;
  }
  .spinner:after {
    width: 10.4px;
    height: 10.2px;
    background: #556CD6;
    border-radius: 0 10.2px 10.2px 0;
    top: -0.1px;
    left: 10.2px;
    -webkit-transform-origin: 0px 10.2px;
    transform-origin: 0px 10.2px;
    -webkit-animation: loading 2s infinite ease;
    animation: loading 2s infinite ease;
  }

  @-webkit-keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes loading {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  /* Animated form */

  .sr-root {
    animation: 0.4s form-in;
    animation-fill-mode: both;
    animation-timing-function: ease;
  }

  .hidden {
    display: none;
  }

  @keyframes field-in {
    0% {
      opacity: 0;
      transform: translateY(8px) scale(0.95);
    }
    100% {
      opacity: 1;
      transform: translateY(0px) scale(1);
    }
  }

  @keyframes form-in {
    0% {
      opacity: 0;
      transform: scale(0.98);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.blockrecap {
  order: -1;
  margin-bottom: 1.5rem;;

  @media (min-width: $sm-width) {
    padding-left: 0;
    order: 1
  }
  @media (min-width: $md-width) {
    padding-left: 1.5rem;
  }

  .trigger-show-product {
    cursor: pointer;
    border-bottom: 1px solid transparent;
    display: block;
    background: color(background, grey);
    padding: 1.5rem;
    position: relative;

    @media (min-width: $sm-width) {
      display: none;
    }

    &:before {
      @include poa(1.5rem, 3rem);
      color: color(mono, dark);
      content: "\e90d";
      font-family: default(font-family, icon);
      font-size: 1.2rem;
    }

    .hide-product {
      display: none;
    }

    &.open {
      border-color: color(primary, light);
      &:before {
        content: "\e909"
      }
      .show-product {
        display: none;
      }
      .hide-product {
        display: block;
      }

      & + .inner {
        display: block;
      }
    }
    
  }

  .inner {
    background: transparent;
    display: none;
    padding: 0 1.5rem;


    @media (min-width: $sm-width) {
      display: block;
      padding: 0 2.5rem;
      border: 1px solid color(border, lighter2);
    }

    thead{
      display: none;
    }

    [data-role="delivery-lines"] {
      tr {
        display: table-row !important;
      }
    }

    .line-quantity {
      display: table-cell !important;
      width: auto !important;
      vertical-align: middle;

      .visible-xs-inline {
        display: none !important;
      }
    }
    .line-visual {
      display: table-cell !important;
      position: static !important;
      padding: 1.5rem 0 !important;
      width: 50px !important;

      @media (min-width: $md-width) {
        width: 65px !important;
      }

      img {
        max-width: 100% !important;
      }
    }
    .line-details {
      display: table-cell !important;
      padding: 1.5rem 1rem !important;
      li {
        font-size: 1.2rem;
      }
    }
    .line-prices {
      display: table-cell !important;
      padding: 1.5rem 0 !important;
      
      .text-muted {
        display: none;
      }
    }
  }

  &-prices-line {
    .row {
      .col-sm-6 {
        margin: 0 !important;
        width: 100%;

        + .col-sm-6 {
          order: -1
        }

       .btn {
         @extend .btn-link;
         @include poa(null, 1.5rem, 1rem);
         height: auto;
       }
      }
    }
  }

  .table-amounts {
    > tbody > tr {
      > td {
        &:first-child {
          text-align: left;
        }
        &:last-child {
          padding-right: 0
        }
      }
      &:last-child {
        border: 0;
      }

      &.recap-schedule {
        border: 0;
      
        td {
          padding: 0;
          p {
            margin: 0;
          }
        }
      }
    } 
  }
}

.process-blockcart {
  &-products-line {
    cursor: pointer;

    .icon {
      transform: rotate(180deg);
      transition: 0.2s linear;
      margin: 0 5px;
      vertical-align: middle;
      display: none;
      
      @media (min-width: $sm-width) {
        display: inline-block;
      }
    }

    &.open {
      .icon {
        transform: rotate(0deg);
      } 
    }
  }
  &-products-recap {
    min-height: 0 !important;

    > td {
      padding: 0 !important;
    }

    .table-vertical-responsive {
      overflow: hidden;
      transition: 0.2s ease;
      
      @media (min-width: $sm-width) {
        max-height: 0;
      }

      [data-role="delivery-lines"] tr:last-child {
        border: 0 !important;
      }

      .line-details li:first-child {
        font-family: default(font-family);
        text-transform: none;
      }
      .line-quantity {
        padding: 0 1rem 0 0 !important;
      }
    }

    &.open {
      .table-vertical-responsive {
        max-height: 1000px;
      }
    }
  }
}

.blockshipping-address {
  padding: 2.5rem 1.5rem;
  border: 1px solid color(border, lighter2);

  @media (min-width: $md-width) {
    padding: 4rem 6rem;
  }
  @media (min-width: $lg-width) {
    padding: 4rem 10rem;
  }

  .process-button-next-step {
    margin-top: 1.5rem;

    @media (min-width: $md-width) {
      margin-top: 2.5rem;
    }
  }

  &-delivery {
    color: color(secondary);
    font-size: 1.4rem;
    text-align: center;
    margin-bottom: 2rem;

    @media (min-width: $md-width) {
      font-size: 1.6rem;
      margin-bottom: 5rem;
    }

    span {
      text-decoration: underline;
      display: block;

      @media (min-width: $md-width) {
        display: inline;
      }
    }
  }

  &-invalid {
    margin-bottom: 1rem;
    text-align: center;
    @extend .richtext;
  }
}

.shipping_message_box {
  .title-centered {
    font-size: 20px;
    margin-bottom: 10px;
    text-align: center;

    @media (min-width: $md-width) {
      font-size: 30px;
      margin-bottom: 15px;
    }
  }

  .shipping_message_form {
    padding: 10px 15px;
    background-color: color(grey, light);

    @media (min-width: $md-width) {
      padding: 30px 20px;
    }

    #giftMessage {
      height: auto;
    }
  }
}

.process-gift {

  .process-gift-delivery {
    padding: 20px 15px;
    border: 1px solid color(border, lighter2);

    @media (min-width: $md-width) {
      padding: 50px 100px;
    }

    .panel-heading {
      display: none;
    }

    .panel-body {
      background: transparent;
      padding: 0;

      table {
        thead {
          display: none;
        }

        tr {
          border-top: 1px solid color(grey, light);
          margin: 0;

          &:last-child {
            border-bottom: 1px solid color(grey, light);
          }

          td {
            &.line-visual {
              padding: 15px 10px 15px 0;
              vertical-align: top;
              
              @media (min-width: $md-width) {
                padding: 20px 40px 20px 0;
              }
            }
            &.line-details {
              padding: 15px 0 15px 90px;

              @media (min-width: $md-width) {
                padding: 25px 0;
              }
            }
            &.line-quantity {
              display: none !important;
            }
          }
        }

      }
    }
  }
}

.cart-locked {
  h2 {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .btn {
    margin-bottom: 20px;
  }
}

.billing-address {
  background-color: color(background, grey);
  padding: 15px;
  text-align: center;
  margin-bottom: 20px;
  height: calc(100% - 20px);

  @media (min-width: $md-width) {
    padding: 30px 20px;
    margin-bottom: 25px;
    height: calc(100% - 25px);
  }

  h3 {
    
  }

  address {
    font-size: 14px;
    font-weight: default(weight);
    color: color(primary);

    @media (min-width: $md-width) {
      font-size: 16px;
    } 
  }

  strong {
    font-size: 14px;
    margin-bottom: 0;
    font-weight: default(weight);
    letter-spacing: normal;
     
    @media (min-width: $md-width) {
      font-size: 16px;
      text-transform: none;
      font-family: default(font-family);
    }
  }

  .billing-address-btn {
    color: color(secondary);
    text-decoration: underline;
    border: 0;
    outline: 0;
    background-color: transparent;
    font-size: 14px;
    margin-bottom: 25px;

    @media (min-width: $md-width) {
      font-size: 16px;
    }
  }

  .panel-body {
    address {
      min-height: 125px;
    }
  }

  [data-ng-if="processData.addressEdition"] {
    margin-top: 0;
  }
}

.payment-transaction-step {
  border: 1px solid color(border, lighter2);
  padding: 30px 15px;
  .btn {
    width: 100%;
  }

  @media (min-width: $md-width) {
    padding: 30px 40px;
  }
  @media (min-width: $lg-width) {
    padding: 50px 100px;
  }

  .panel {
    margin-bottom: 0;
  }

  .panel-heading {
    display: none;
  }

  .list-group {
    margin: 0 !important;
    padding: 0 !important;
    background: transparent !important;
    box-shadow: none;

    .list-group-item {
      margin: 0 !important;
      width: 100% !important;
      padding: 0;

      > div {
        padding: 0 !important;
      }
    }
  }
}
// subscription : shipping => change if bypass
.bypass-process-title {
  display: none;
  position: absolute;
  top: 0;
  @media(min-width:$lg-width){
    margin: -10rem 0 5rem -10rem!important;
    position: static;
  }
  
}
.bypass-process {
  .order-process #shipping [data-ng-if="processData.isCurrent"] > .std-process-title, .blockshipping-address-invalid {
    display: none;
  }
  .process-shipping-delivery {
    margin-top: 8rem;
  }
  .bypass-process-title {
    display: block;
  }
  @media(min-width:$lg-width){
    .blockshipping {
      margin-top: 8rem;
    }

    .process-shipping-delivery {
      margin-top: 0;
    }
  }
}
// subscription : cart line
.process-blockcart-products-recap {
  .subcription-lines {
    dl {
      display: none!important;
    }
  }
}