.table-div {
  line-height: 20px;
  margin-bottom: 20px;
  overflow: hidden;

  @media (min-width: $md-width) {
    border: 1px solid color(border);
    border-radius: default(radius);
    margin-bottom: 30px;
  }

  .table-head {
    background: none;
    color: color(text);
    display: block;
    font-weight: default(weight, bold);
    overflow: hidden;
    padding: 5px 0;
    text-transform: uppercase;

    @media (min-width: $md-width) {
      background: color(background);
      display: flex;
      padding: 15px 0;
    }

    &-cell {
      display: none;

      @media (min-width: $md-width) {
        display: inline-block;
      }
    }
  }

  .table-row {
    border: 1px color(border, dark);
    overflow: hidden;
    padding: 5px 0;
    position: relative;
    width: 100%;

    @media (min-width: $md-width) {
      border: 0;
      display: flex;
      padding: 0;
    }

    .table-cell {
      padding: 5px 10px;

      @media (min-width: $md-width) {
        display: inline-block;
        padding: 15px;
      }
    }

    ul {
      margin: 0;
    }
  }

  .title-mobile {
    color: color(mono, dark);
    display: block;

    @media (min-width: $md-width) {
      display: none;
    }
  }

  .label-mobile {
    color: color(text);
    display: inline-block;
    font-weight: default(weight, bold);

    @media (min-width: $md-width) {
      display: none;
    }
  }
}

.table.table-bordered,
.table {
  border: 0;

  > thead {
    > tr > th {
      background-color: color(primary);
      border: 0;
      font-family: default(font-family, secondary);
      text-transform: uppercase;
      color: color(text, light);
      letter-spacing: 1px;
      padding: 13px 25px 13px;
      @include rem(15);
    }
  }
  > tbody {
    > tr {
      background: none;
      > td {
        padding: 25px 15px;
        background: none;
        border: 0;
        border-bottom: 1px solid color(border, lighter);

        &:first-child {
          padding-left: 0;
        }
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
  &.table-striped {
    > tbody {
      > tr {
        background: none;
        > td {
          background: none;
        }
      }
    }
  }
  &.table-transparent {
    background: none;
    > tbody {
      > tr {
        > td {
          border: 0;
          padding: 0;

          &.main-price {
            @include rem(15);
            font-family: default(font-family, secondary);
            color: color(text, dark);
          }
        }
      }
    }
  }
  &.table-amounts {
    margin-bottom: 0;
    
    > tbody > tr {
      > td {
        border: 0;
        color: color(text, dark);
        font-weight: default(weight, book);
        padding: 0;
        @include rem(14);

        @media (min-width: $md-width) {
          @include rem(16);
        }
  
        & + td {
          font-family: default(font-family, secondary);
          @include rem(15);
        }
      }

      &:last-child:not([data-ng-if="totalTaxesAmount"]) {
        > td {
          strong {
            font-weight: default(weight, book);
          }
    
          // & + td {
          //   @include rem(20);
          // }
        }
      }
    }
  }
}

.table-vertical-responsive {
  overflow-x: auto;
  text-align: left;
}
