/*********************** FONTS ********************************/

@font-face {
  font-family: 'Brother 1816';
  src: url('../fonts/brother/Brother1816-Bold.eot');
  src: local('Brother 1816 Bold'), local('Brother1816-Bold'),
      url('../fonts/brother/Brother1816-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/brother/Brother1816-Bold.woff2') format('woff2'),
      url('../fonts/brother/Brother1816-Bold.woff') format('woff'),
      url('../fonts/brother/Brother1816-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Brother 1816';
  src: url('../fonts/brother/Brother1816-Medium.eot');
  src: local('Brother 1816 Medium'), local('Brother1816-Medium'),
      url('../fonts/brother/Brother1816-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/brother/Brother1816-Medium.woff2') format('woff2'),
      url('../fonts/brother/Brother1816-Medium.woff') format('woff'),
      url('../fonts/brother/Brother1816-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Brother 1816';
  src: url('../fonts/brother/Brother1816-BoldItalic.eot');
  src: local('Brother 1816 Bold Italic'), local('Brother1816-BoldItalic'),
      url('../fonts/brother/Brother1816-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/brother/Brother1816-BoldItalic.woff2') format('woff2'),
      url('../fonts/brother/Brother1816-BoldItalic.woff') format('woff'),
      url('../fonts/brother/Brother1816-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Brother 1816';
  src: url('../fonts/brother/Brother1816-Regular.eot');
  src: local('Brother 1816 Regular'), local('Brother1816-Regular'),
      url('../fonts/brother/Brother1816-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/brother/Brother1816-Regular.woff2') format('woff2'),
      url('../fonts/brother/Brother1816-Regular.woff') format('woff'),
      url('../fonts/brother/Brother1816-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Brother 1816 Book';
  src: url('../fonts/brother/Brother1816-Book.eot');
  src: local('Brother 1816 Book'), local('Brother1816-Book'),
      url('../fonts/brother/Brother1816-Book.eot?#iefix') format('embedded-opentype'),
      url('../fonts/brother/Brother1816-Book.woff2') format('woff2'),
      url('../fonts/brother/Brother1816-Book.woff') format('woff'),
      url('../fonts/brother/Brother1816-Book.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Brother 1816';
  src: url('../fonts/brother/Brother1816-Light.eot');
  src: local('Brother 1816 Light'), local('Brother1816-Light'),
      url('../fonts/brother/Brother1816-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/brother/Brother1816-Light.woff2') format('woff2'),
      url('../fonts/brother/Brother1816-Light.woff') format('woff'),
      url('../fonts/brother/Brother1816-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Brother1816 Printed';
  src: url('../fonts/brother/Brother1816Printed-BoldItalic.eot');
  src: local('Brother1816 Printed Bold Italic'), local('Brother1816Printed-BoldItalic'),
      url('../fonts/brother/Brother1816Printed-BoldItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/brother/Brother1816Printed-BoldItalic.woff2') format('woff2'),
      url('../fonts/brother/Brother1816Printed-BoldItalic.woff') format('woff'),
      url('../fonts/brother/Brother1816Printed-BoldItalic.ttf') format('truetype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Brother1816 Printed';
  src: url('../fonts/brother/Brother1816Printed-BlackItalic.eot');
  src: local('Brother1816 Printed Black Italic'), local('Brother1816Printed-BlackItalic'),
      url('../fonts/brother/Brother1816Printed-BlackItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/brother/Brother1816Printed-BlackItalic.woff2') format('woff2'),
      url('../fonts/brother/Brother1816Printed-BlackItalic.woff') format('woff'),
      url('../fonts/brother/Brother1816Printed-BlackItalic.ttf') format('truetype');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Brother1816 Printed';
  src: url('../fonts/brother/Brother1816Printed-MediumItalic.eot');
  src: local('Brother1816 Printed Medium Italic'), local('Brother1816Printed-MediumItalic'),
      url('../fonts/brother/Brother1816Printed-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/brother/Brother1816Printed-MediumItalic.woff2') format('woff2'),
      url('../fonts/brother/Brother1816Printed-MediumItalic.woff') format('woff'),
      url('../fonts/brother/Brother1816Printed-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Brother 1816';
  src: url('../fonts/brother/Brother1816-Regulartalic.eot');
  src: local('Brother 1816 Regular talic'), local('Brother1816-Regulartalic'),
      url('../fonts/brother/Brother1816-Regulartalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/brother/Brother1816-Regulartalic.woff2') format('woff2'),
      url('../fonts/brother/Brother1816-Regulartalic.woff') format('woff'),
      url('../fonts/brother/Brother1816-Regulartalic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Brother 1816';
  src: url('../fonts/brother/Brother1816-Black.eot');
  src: local('Brother 1816 Black'), local('Brother1816-Black'),
      url('../fonts/brother/Brother1816-Black.eot?#iefix') format('embedded-opentype'),
      url('../fonts/brother/Brother1816-Black.woff2') format('woff2'),
      url('../fonts/brother/Brother1816-Black.woff') format('woff'),
      url('../fonts/brother/Brother1816-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Brother 1816';
  src: url('../fonts/brother/Brother1816-Thin.eot');
  src: local('Brother 1816 Thin'), local('Brother1816-Thin'),
      url('../fonts/brother/Brother1816-Thin.eot?#iefix') format('embedded-opentype'),
      url('../fonts/brother/Brother1816-Thin.woff2') format('woff2'),
      url('../fonts/brother/Brother1816-Thin.woff') format('woff'),
      url('../fonts/brother/Brother1816-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Brother 1816';
  src: url('../fonts/brother/Brother1816-ExtraBold.eot');
  src: local('Brother 1816 ExtraBold'), local('Brother1816-ExtraBold'),
      url('../fonts/brother/Brother1816-ExtraBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/brother/Brother1816-ExtraBold.woff2') format('woff2'),
      url('../fonts/brother/Brother1816-ExtraBold.woff') format('woff'),
      url('../fonts/brother/Brother1816-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Brother 1816';
  src: url('../fonts/brother/Brother1816-MediumItalic.eot');
  src: local('Brother 1816 Medium Italic'), local('Brother1816-MediumItalic'),
      url('../fonts/brother/Brother1816-MediumItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/brother/Brother1816-MediumItalic.woff2') format('woff2'),
      url('../fonts/brother/Brother1816-MediumItalic.woff') format('woff'),
      url('../fonts/brother/Brother1816-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Brother 1816';
  src: url('../fonts/brother/Brother1816-ThinItalic.eot');
  src: local('Brother 1816 Thin Italic'), local('Brother1816-ThinItalic'),
      url('../fonts/brother/Brother1816-ThinItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/brother/Brother1816-ThinItalic.woff2') format('woff2'),
      url('../fonts/brother/Brother1816-ThinItalic.woff') format('woff'),
      url('../fonts/brother/Brother1816-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Brother 1816';
  src: url('../fonts/brother/Brother1816-LightItalic.eot');
  src: local('Brother 1816 Light Italic'), local('Brother1816-LightItalic'),
      url('../fonts/brother/Brother1816-LightItalic.eot?#iefix') format('embedded-opentype'),
      url('../fonts/brother/Brother1816-LightItalic.woff2') format('woff2'),
      url('../fonts/brother/Brother1816-LightItalic.woff') format('woff'),
      url('../fonts/brother/Brother1816-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Bauer';
  src: url("../fonts/bauer/BauerBodoniRoman.eot"); /* IE9*/
  src: url("../fonts/bauer/BauerBodoniRoman.eot?#iefix") format("embedded-opentype"), /* IE6-IE8 */
  url("../fonts/bauer/BauerBodoniRoman.woff2") format("woff2"), /* chrome、firefox */
  url("../fonts/bauer/BauerBodoniRoman.woff") format("woff"), /* chrome、firefox */
  url("../fonts/bauer/BauerBodoniRoman.ttf") format("truetype"), /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
  url("../fonts/bauer/BauerBodoniRoman.svg#Bauer") format("svg"); /* iOS 4.1- */
}

@font-face {
  font-family: 'Hind';
  src: url('../fonts/hind/Hind-Regular.eot');
  src: url('../fonts/hind/Hind-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/hind/Hind-Regular.woff2') format('woff2'),
      url('../fonts/hind/Hind-Regular.woff') format('woff'),
      url('../fonts/hind/Hind-Regular.ttf') format('truetype'),
      url('../fonts/hind/Hind-Regular.svg#Hind-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Hind';
  src: url('../fonts/hind/Hind-Medium.eot');
  src: url('../fonts/hind/Hind-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/hind/Hind-Medium.woff2') format('woff2'),
      url('../fonts/hind/Hind-Medium.woff') format('woff'),
      url('../fonts/hind/Hind-Medium.ttf') format('truetype'),
      url('../fonts/hind/Hind-Medium.svg#Hind-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Hind';
  src: url('../fonts/hind/Hind-Bold.eot');
  src: url('../fonts/hind/Hind-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/hind/Hind-Bold.woff2') format('woff2'),
      url('../fonts/hind/Hind-Bold.woff') format('woff'),
      url('../fonts/hind/Hind-Bold.ttf') format('truetype'),
      url('../fonts/hind/Hind-Bold.svg#Hind-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Hind';
  src: url('../fonts/hind/Hind-Light.eot');
  src: url('../fonts/hind/Hind-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/hind/Hind-Light.woff2') format('woff2'),
      url('../fonts/hind/Hind-Light.woff') format('woff'),
      url('../fonts/hind/Hind-Light.ttf') format('truetype'),
      url('../fonts/hind/Hind-Light.svg#Hind-Light') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?lp9nfe');
  src:  url('../fonts/icomoon.eot?lp9nfe#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.woff2?lp9nfe') format('woff2'),
    url('../fonts/icomoon.ttf?lp9nfe') format('truetype'),
    url('../fonts/icomoon.woff?lp9nfe') format('woff'),
    url('../fonts/icomoon.svg?lp9nfe#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-lock:before {
  content: "\e915";
}
.icon-cart:before {
  content: "\e916";
}
.icon-check:before {
  content: "\e914";
}
.icon-quote-reverse:before {
  content: "\e90e";
}
.icon-quote:before {
  content: "\e90f";
}
.icon-chevron:before {
  content: "\e907";
}
.icon-arrow-left:before {
  content: "\e912";
}
.icon-arrow-right:before {
  content: "\e913";
}
.icon-plus:before {
  content: "\e909";
}
.icon-moins:before {
  content: "\e90d";
}
.icon-croix:before {
  content: "\e908";
}
.icon-close:before {
  content: "\e908";
}
.icon-fb:before {
  content: "\e90a";
}
.icon-insta:before {
  content: "\e90b";
}
.icon-instagram:before {
  content: "\e910";
}
.icon-pt:before {
  content: "\e90c";
}
.icon-logo:before {
  content: "\e906";
}
.icon-gift:before {
  content: "\e901";
}
.icon-menu:before {
  content: "\e902";
}
.icon-paiement:before {
  content: "\e903";
}
.icon-boutiques:before {
  content: "\e900";
}
.icon-user:before {
  content: "\e911";
}
.icon-panier:before {
  content: "\e904";
}
.icon-shop:before {
  content: "\e905";
}




/*********************** TITRES ********************************/
h1,
.h1 {
  @include heading(color(secondary), 25, 0, false, false);
  font-family: default(font-family, tertiary);

  @media (min-width: $xs-width) {
    font-size: 3.5rem;
  }
  @media (min-width: $sm-width) {
    font-size: 4.5rem;
  }
  @media (min-width: $md-width) {
    font-size: 7.5rem;
  }
  @media (min-width: $lg-width) {
    font-size: 10rem;
  }
}

h2,
.h2 {
  @include heading(color(text, dark), 24, 0, false, false); 
  font-family: default(font-family, tertiary);

  @media (min-width: $md-width) {
    font-size: 3.8rem;
  }
}

h3,
.h3 {
  @include heading(color(text), 15, 40, false, true);
  font-family: default(font-family, secondary);
}

.h4 {
  @include heading(color(text), 18, 10, true, true);
}

.h5 {
  @include heading(color(text), 16, 10, true, false);
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;

  @include animated();

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

strong {
  font-weight: default(weight, bold);
}

.barre {
  text-decoration: line-through;
}

.order-process-title {
  @include heading(color(primary), 30, 20, false, false);
  font-family: default(font-family, tertiary);
  padding: 20px 15px;
  text-align: center;
  border-bottom: 1px solid color(border, lighter2);

  @media (min-width: $md-width) {
    font-size: 5rem;
    margin-bottom: 30px;
    padding: 25px 15px;
  }
}
.richtext {
  p {
    line-height: 2.2rem;
    font-size: 1.4rem;
    @media(min-width:$md-width){
      line-height: 1.9rem;
      font-size: 1.6rem;
    }
  }
}
