.modal {
    &-content {
        border-radius: 0;

        h3 {
            color: color(primary); 
            font-family: default(font-family, secondary);
            font-size: 1.4rem;
            text-transform: none;
            margin: 0 0 1.5rem 0;

            @media (min-width: $md-width) {
                font-size: 1.6rem;
                margin: 2rem 0 3rem 0;
            }
        }
    }
    &-header {
        border-color: color(primary, light)
    }
    &-title {
        color: color(primary); 
        font-family: default(font-family, secondary);
        font-size: 1.6rem;
    }
    &-footer {
        border-color: color(primary, light);
        .glyphicon {
            display: none;
        }

        .btn {
            width: 100%;

            @media (min-width: $xs-width) {
                width: 49%;
            }

            + .btn {
                margin: 1rem 0 0 0;

                @media (min-width: $xs-width) {
                    margin: 0;
                }
            }
        }
    }

    [data-rbs-catalog-add-to-cart-confirmation-modal] {
        .col-sm-4 {
            padding: 0 1rem;
            width: 30%;
        }
        .col-sm-8 {
            padding: 0 1rem 0 0;
            width: 70%;
        }

        .dl-inline-items {
            dt {
                display: none;
            }
        }
    }

    .ctn { 
        width: 100%;
    }
}

.dropdown-menu {
    border-radius: 0;
    border: 1px solid color(border);

    > li {
        > a {
            color: color(text, dark);
            &:hover {
                background: none;
                color: color(text, dark);
            }
        }
    }
}