[data-name="Rbs_User_CreateAccount"] {
  h1 {
    display: none;
  }
}
[data-name="Rbs_User_Login"] {
  .checkbox {
    width: 60%
  }
  
}