.sitemap {

  .tree {
    line-height: 25px;

    > .tree-list {
      margin-bottom: 30px;

      > a {
        display: inline-block;
        font-weight: default(weight, bold);
      }
    }

    .subtree {
      padding-left: 25px;

      li {
        position: relative;

        &::before {
          content: "\f054";
          font-family: default(font-family, icon);
          @include rem(10);
          @include poa(null, null, null, -15px);
        }
      }
    }
  }
}
