[data-name="Rbs_Catalog_Product"],
[data-name="Rbs_Catalog_CrossSelling"] {
  @extend %block-spacing;
}

.product-details {
  > .ctn {
    position: relative;
    .richtext {
      b {
        font-weight: bold;
      }
      i {
        font-style: italic;
      }
      a {
        text-decoration: underline;
      }
    }
  }
}
.product {
  &-details {
    padding-top: 4.5rem;
  }

  &-imgs {
    flex-flow: row nowrap;
    overflow-x: scroll;
    margin-bottom: 3rem;
    position: relative;

    @media (min-width: $sm-width) {
      flex-wrap: wrap;
      overflow-x: visible;
      min-width: 100%;
      margin-bottom: 0;
    }

    img {
      height: 100%;
      min-width: 85%;
      padding: 0 1rem;
      width: 100%;

      @media (min-width: $xs-width) {
        min-width: 45%;
        padding: 0 1.5rem;
      }

      @media (min-width: $sm-width) {
        margin-bottom: 1.5em;
        min-width: 100%;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .delivery-info-on-visuals {
      position: absolute;
      top: 10px;
      left: 20px;
      padding: 10px 20px;
      background: color(mono);
      font-size: 12px;
      color: color(secondary);
      text-transform: uppercase;
      font-family: default(font-family, secondary);

      @media (min-width: $xs-width) {
        left: 25px;
      }
      @media (min-width: $sm-width) {
        left: auto;
        right: 25px;
      }
    }
  }

  &-caption {
    color: color(primary);
    height: fit-content;
    transition: top 0.05s ease 0s;

    @media (min-width: $xl-width) {
      padding: 0;
    }

    h1 {
      color: inherit;
      font-family: default(font-family, secondary);
      font-size: 1.8rem;
      text-transform: uppercase;

      @media (min-width: $md-width) {
        font-size: 2.5rem;
      }
    }

    .price {
      color: color(secondary);
      font-family: default(font-family, secondary);
      font-size: 1.8rem;
      margin: 1.5rem 0 3rem 0;

      @media (min-width: $md-width) {
        font-size: 2.5rem;
        margin-top: 2.5rem;
      }
    }

    .product-qty {
      margin: 0.5rem 0 1.5rem 0 !important;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (min-width: $sm-width) {
        margin-bottom: 2.5rem;
        padding: 0;
      }
      @media (min-width: $md-width) {
        margin-bottom: 0 !important;
        padding: 0;
        padding-bottom: 2rem;
      }
      input {
        width: 30%;
      }
      label {
        color: color(grey);
        font-size: 1.4rem;
        margin-right: 1rem;
      }
    }

    .add-to-cart {
      background: transparent;
      padding: 0;
      position: relative;
      margin-top: 1.5rem;
      margin-bottom: 1.5em;
      text-align: center;

      @media (min-width: $sm-width) {
        border-bottom: 0.1rem solid color(border, lighter2);
        padding-bottom: 4rem;
      }

      &-title {
        font-size: 1.8rem;
        font-family: default(font-family, tertiary);
        display: none;

        @media (min-width: $md-width) {
          font-size: 3rem;
        }
      }

      &-stock {
        color: color(grey);
        font-size: 1.4rem;
        margin-top: 1rem;

        @media (min-width: $md-width) {
          font-size: 1.6rem;
        }
      }

      &-availability {
        &-title {
          display: block;
          font-family: default(font-family, secondary);
          letter-spacing: 0.05rem;
        }
        &-input {
          border: 1px solid color(border, lighter2);
          font-family: default(font-family);
          font-size: 1.6rem;
          font-weight: 300;
          margin: 1rem 0 2.5rem 0;
        }
        &-lighter {
          color: color(grey);
          font-size: 1.4rem;
          font-weight: 300;
          flex-grow: 0;
          padding-left: 2.5rem;
          position: relative;
          margin: 1.5rem 1.5rem 0 1.5rem;
          white-space: nowrap;

          @media (min-width: $lg-width) {
            font-size: 2rem;
          }

          &-ctn {
            display: inline-block;
            padding: 1rem 0;
            text-align: left;

            @media (min-width: $lg-width) {
              display: flex;
              justify-content: center;
            }
          }

          &:before {
            @include size(13, 13);
            @include poa(50%, null, null, 0);
            margin-top: -7px;
            background: color(grey);
            border-radius: 50%;
            content: "";
          }

          &.success {
            color: color(mono, dark);
            &:before {
              background: color(alert, success2);
            }
          }
          &.error {
            color: color(alert, error);
            &:before {
              background: color(alert, error);
            }
          }
        }
      }

      &-actions {
        margin-top: 1rem;
        @media (min-width: $lg-width) {
          margin-top: 3rem;
          padding: 0 11rem;
        }
      }

      .change-store {
        span {
          display: block;
          margin: 1rem 0 0 0;
        }
        a {
          @include poa(null, 1rem, 1rem, null);
          text-decoration: underline;
        }
      }
    }

    .store-availability {
      margin: 1.5rem 0;
      text-align: center;
      display: none;
    }

    &.stacked {
    }
  }

  &-resume {
    font-size: 1.4rem;
    font-weight: default(weight, light);
    line-height: 1.2;
    margin-top: 1.5rem;

    @media (min-width: $md-width) {
      font-size: 1.6rem;
      margin-top: 3rem;
    }
  }

  &-variants {
    &-axes {
      border-top: 0.1rem solid color(border, lighter2);
      padding: 1.5rem 0 1rem;

      @media (min-width: $md-width) {
        padding: 1.5rem 0 2rem 0;
      }

      &-title {
        display: block;
        font-family: default(font-family);
        font-size: 1.5rem;
        text-align: center;
        font-weight: default(weight, bold);

        @media (min-width: $md-width) {
          font-size: 1.6rem;
        }
      }
      /* swatch */
      .form-control-swatch {
        @extend .row;
        margin-left: -8px;
        margin-right: -8px;
        justify-content: center;
        .tooltip {
          display: none !important;
        }
        li {
          @extend .xxs4;
          margin-top: 2rem;
          padding: 0 8px;
          span {
            display: block;
          }
        }

        @media (max-width: $sm-width) {
          flex-wrap: nowrap;
          overflow: scroll;
          margin-left: -15px;
          margin-right: -30px;
          padding-left: 0;
          justify-content: flex-start;
        }
        @media (max-width: $sm-width) {
          li {
            max-width: 33%;
            flex-basis: 33%;
            min-width: 33%;
            padding: 0 5px;
          }
        }
        @media (max-width: $xs-width) {
          li {
            max-width: 55%;
            flex-basis: 55%;
            min-width: 55%;
            padding: 0 5px;
          }
        }
      }
      .btn-swatch {
        background-color: transparent !important;
        border-color: color(grey, light);
        border-width: 0.1rem;
        background-size: auto !important;
        background-position: 8px 8px !important;
        height: 8rem;
        min-height: 8rem;
        max-height: 8rem;
        padding: 1rem 1.5rem 1rem 5.5rem;
        width: 100%;
        white-space: normal;

        .sr-only {
          font-size: 1.5rem;
          line-height: 1;
          height: auto;
          position: relative;
          text-transform: none;
          width: auto;

          @media (min-width: $lg-width) {
            font-size: 1.6rem;
          }
        }
        .visible-hover {
          display: none;
        }
        .glyphicon-ok {
          position: static;
          &:before {
            content: "";
            @include poa(0, 0, 0, 0);
            border: 1px solid color(primary);
            display: block;
          }
        }
        &:hover {
          color: inherit;
          .sr-only {
            font-weight: bold;
          }
        }
      }
      .axis-value-selector-container {
        width: 100%;
      }

      .form-group {
        .control-label {
          display: none;
        }

        & > div {
          width: 100%;
        }

        .form-control-buttons {
          display: flex;
          flex-wrap: wrap;
          margin: 1.5rem -1rem 0 -1rem;
          li {
            margin-bottom: 1rem;
          }

          @media (min-width: $xs-width) {
            margin: 1.5rem -1.5rem 0 -1.5rem;
          }
          @media (min-width: $md-width) {
            margin: 2rem -1.5rem 0 -1.5rem;
          }

          li {
            flex-basis: 50%;
            flex-grow: 1;
            padding: 0 1rem;

            @media (min-width: $xs-width) {
              padding: 0 1.5rem;
            }

            button {
              border-width: 0.1rem;
              border-color: color(grey, dark);
              display: inline-block;
              font-size: 1.4rem;
              line-height: 1;
              font-weight: default(weight, light);
              text-transform: none;
              width: 100%;

              @media (min-width: $md-width) {
                font-size: 1.8rem;
              }

              &.btn-primary {
                background: color(grey, dark);
              }
            }
          }
        }
      }
    }
  }

  &-tabs {
    line-height: 1.2;
    .panel-group {
      .panel {
        border-radius: 0;
        box-shadow: none;
      }
    }
    .panel-default {
      background: transparent;
      border: 0;

      & > .panel-heading {
        background: transparent;
        border: 0;
        font-family: default(font-family, secondary);
        font-size: 1.2rem;
        font-weight: default(weight);
        padding: 0;
        text-align: left;
        text-transform: none;

        @media (min-width: $md-width) {
          font-size: 1.5rem;
        }

        a {
          border-bottom: 1px solid color(border, lighter2);
          color: color(primary);
          display: block;
          padding: 1.2rem 1.5rem 1.2rem 0;
          position: relative;
          text-decoration: none;

          &:hover {
            color: color(primary);
            text-decoration: none;
          }

          &:after {
            @include poa(1.4rem, 1rem);
            content: "\e90d";
            font-family: default(font-family, icon);
            font-size: 1.2rem;
          }

          &.collapsed {
            &:after {
              content: "\e909";
            }
          }
        }

        & + .panel-collapse > .panel-body {
          border: 0;
          font-size: 1.4rem;
          font-weight: default(weight, light);
          padding: 1.5rem 0 2.5rem 0;

          @media (min-width: $md-width) {
            font-size: 1.8rem;
            padding: 1.5rem 0 3rem 0;
          }
        }
      }
    }
  }

  &-accessories {
    img {
      display: block;
      max-width: 100%;
      height: auto;
    }
    .carousel-control {
      background-image: none !important;
    }
  }
}

.modal {
  [data-name="Rbs_Catalog_Product"] {
    margin-bottom: 0;
  }
  .product-details {
    padding: 0;

    h1 {
      &:before,
      &:after {
        display: none;
      }
    }
    .product-imgs img {
      min-width: 50%;
      width: 50%;

      &:first-child {
        min-width: 100%;
        width: 100%;
      }
    }
  }
  .product-caption .add-to-cart {
    padding: 2.5rem 0;

    @media (min-width: $sm-width) {
      padding: 2.5rem;
    }

    .btn {
      font-size: 1.4rem;
      padding: 14px;
    }
  }
  .product-caption .add-to-cart-availability-lighter-ctn {
    display: inline-block;
  }
  .product-caption .add-to-cart-actions {
    padding: 0;

    @media (min-width: $sm-width) {
      padding: 3rem;
    }
  }
  .product-variants-axes .btn-swatch {
    padding: 60% 1.5rem 1rem;
    background-position: center 10px !important;
    height: auto;
    min-height: auto;
    max-height: none;
    white-space: nowrap;

    @media (min-width: $sm-width) {
      padding: 50% 1.5rem 1rem;
    }

    @media (min-width: $md-width) {
      padding: 75% 1.5rem 1rem;
    }
  }
}

[data-name="data-layout-rbs-catalog-product-added-to-cart"] {
  .row::before,
  .row::after {
    display: none;
  }
}

.pac-container {
  z-index: 9999 !important;
}

.delivery_datepicker_ctn {
  margin-bottom: 1rem;

  * {
    &:focus,
    &:active {
      outline: 0;
    }
  }

  .uib-daypicker,
  .uib-monthpicker,
  .uib-yearpicker,
  .dropdown-menu {
    position: static;
    border: 0;
    padding: 0;

    table {
      width: 100%;
      outline: 0;

      &:focus,
      &:active {
        outline: 0;
      }

      thead > tr {
        &:first-child th {
          padding: 0 !important;
          vertical-align: middle;
        }
        th {
          border: 1px solid color(border, lighter2);
          background: color(background);
          color: color(text, dark);
          font-size: 1.3rem !important;
          padding: 10px 2px !important;

          @media (min-width: $sm-width) {
            font-size: 1.6rem !important;
            padding: 14px 5px !important;
          }

          .uib-title {
            font-weight: default(weight, bold);
          }

          .uib-right,
          .uib-left {
            padding: 8px 0 !important;
            font-size: 1.7rem !important;

            @media (min-width: $sm-width) {
              padding: 10px 0 !important;
              font-size: 1.9rem !important;
            }
          }
        }
      }

      td {
        border: 1px solid color(border, lighter2);
        width: 12.5%;
        vertical-align: top;
      }

      .btn,
      a {
        border: 0;
        outline: 0;
        font-size: 1.3rem !important;
        padding: 10px 2px !important;

        @media (min-width: $sm-width) {
          font-size: 1.6rem !important;
          padding: 14px 5px !important;
        }

        &.disabled,
        &[disabled] {
          background-color: #e0e0e0 !important;
          color: color(text, dark) !important;
          &:hover,
          &:focus {
            color: color(text, dark);
            background-color: #e0e0e0 !important;
          }
        }
      }

      .btn.active,
      .btn.active:focus,
      .btn.active:hover,
      .btn:active:focus,
      .btn:active:hover {
        background-color: color(primary);
        color: color(mono);
      }

      tbody > tr > td:first-child {
        background: transparent;
        color: color(text, dark);
        font-size: 1.3rem;
        vertical-align: middle;

        @media (min-width: $sm-width) {
          font-size: 1.6rem;
        }
      }

      .glyphicon-chevron-left:before {
        content: "\e912";
        font-family: default(font-family, icon);
      }
      .glyphicon-chevron-right:before {
        content: "\e913";
        font-family: default(font-family, icon);
      }
    }
  }

  .text-muted,
  .text-info {
    &:hover,
    &:focus {
      color: color(text, light);
    }
  }

  .btn {
    &:hover,
    &:focus {
      .text-muted,
      .text-info {
        color: color(text, light);
      }
    }
  }
}

.time-slot-btns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;

  .btn {
    padding: 10px 15px;
    line-height: 1.2;
    flex-grow: 1;
    height: auto;
    border-radius: 50px;
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }

  .slot {
    display: block;
  }
}
.add-to-cart-btn {
  .icon {
    padding-right: 1.5rem;
  }
}
.delivery-panel-label {
  font-size: 1.4rem;
  margin-bottom: 1rem;
  text-align: left;

  @media (min-width: $sm-width) {
    font-size: 1.6rem;
  }
}

.product-delivery-panel-alert {
  margin: 0 0 15px;

  span {
    display: block;
  }

  &.alert-info {
    margin-top: 10px;
    font-size: 13px;
  }
}

.modal-rbs-catalog-add-to-cart-confirmation.hidden {
  display: none !important;
}

.complete_description_link {
  text-decoration: underline;
  cursor: pointer;
}

.modal {
  .complete_description_link {
    display: none;
  }
}

/// product subscription
[data-rbs-catalog-product-project-options].form-inline {
  border-top: 1px solid color(border, lighter2);
  margin-top: 4rem;
  padding-top: 2rem;
  .control-label {
    margin-top: 4rem;
    font-size: 1.6rem;
  }
  .radio {
    display: flex;
    margin: 2rem -1rem 0 !important;
    label {
      font-family: "Hind";
      margin: 0 1rem;
    }
  }
  .form-hidden {
    .subscription-gift-text {
      margin-top: 4rem;
    }

    .gift-message-length {
      margin-top: 0.5rem;
      font-size: 1.2rem;
    }
    .form-group {
      display: block;
      margin-top: 4rem;
      .control-label-sub,
      .form-control {
        display: block;
        width: 100%;
      }
      .form-control,
      .form-control + span {
        margin-top: 0.5rem;
      }
      .control-label-sub {
        font-weight: default(weight);
        margin-top: 3rem;
      }
      .textarea {
        resize: none;
        height: 19rem;
      }
    }
  }
}
.product-subscription {
  .richtext {
    margin-top: 1.5rem;
    @media (min-width: $md-width) {
      margin-top: 3rem;
    }
  }
  .link {
    display: inline-block;
    margin-top: 1.5rem;
  }

  .product-variants-axes {
    border: 0;
    &-title {
      display: none;
    }
    .form-group {
      margin-top: 3rem !important;
      .control-label {
        display: block;
        font-size: 1.6rem;
        text-align: left;
      }
    }
    .form-control-buttons {
      margin-left: -1rem;
      margin-right: -1rem;
    }
    .form-control-buttons li {
      flex-basis: auto;
      flex-grow: 0;
      padding: 0 1rem;
      .btn-swatch {
        color: color(primary);
        height: 3.8rem;
        font-size: 1.6rem;
        font-weight: default(weight);
        letter-spacing: normal;
        min-height: 3.8rem;
        padding: 0 1.5rem;
        width: auto;
        @media (max-width: 350px) {
          font-size: 15px !important;
          padding: 0 0.7rem !important;
        }

        &.btn-default {
          border-color: color(grey, light);
          &:hover {
            border-color: color(primary);
          }
        }
      }
    }
  }
}
// product-option
.product-option {
  &-ctn {
    margin-top: 1.5rem;
    .control-label {
      margin-bottom: 0.5rem;
    }
    .price {
      margin-top : 0.2rem;
    }
    .price-old-value {
      font-size: 1.4rem;
    }
    .discount-detail {
      display: none;
    }
    [data-rbs-catalog-product-project-options] {
      label {
        line-height: 1.5;
      }
    }
  }
  &-inner {
    margin-top: -2rem;
  }
  @media(min-width:$xs-width){
    &-inner {
      margin-top: 0;
    }
  }
  
}
