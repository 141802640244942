.section-block {
  padding: 50px 0;

  .section-block-header {
    text-align: center;

    .section-block-header-title {
      margin-bottom: 0;
    }

    .section-block-header-subtitle {
      margin-bottom: 30px;
    }
  }
} 

// like addresses / CBs blocks (account and cart)
.block-info {
  background: color(background);
  border: 1px solid color(border);
  border-radius: default(radius);
  margin-bottom: 1.5rem;
  padding: 1.5rem;
  position: relative;

  @media (min-width: $md-width) {
    margin-bottom: 3rem;
  }

  &-inner {
    margin: 0;
  }

  &-title {
    color: color(mono, dark);
    display: block;
    font-weight: default(weight, bold);
    margin-bottom: .5rem;
  }

  &-footer {
    margin-top: 1.5rem;
  }
}

.block {
  display: block;
}

.block-keep {
  
  &-content {
    margin-top: 2rem;
    position: relative;

    @media (min-width: $md-width) {
      left: 5rem;
    }
    @media (min-width: $xl-width) {
      left: 10rem;
    }

    img {
      display: none;

      @media (min-width: $sm-width) {
        display: block;
        margin-top: 3rem;
        max-width: unset;
        width: 27.5rem;
      }
      @media (min-width: $md-width) {
        margin-top: 3rem;
        max-width: 100%;
        width: auto;
      }
      
      @media (min-width: $xl-width) {
        margin-top: 6rem;
      }
    }
  }

  &-maintitle {
    font-size: 4.5rem;
    margin: 0 0 2rem 0;
    padding-left: 55px;
    width: 30rem;  
    
    @media (min-width: $md-width) {
      line-height: 1;
      padding-left: 70px;
    }
    @media (min-width: $xl-width) {
      font-size: 6.5rem;
      width: auto; 
    }

    &:before {
      @include poa(0.5rem, null, null, 1rem);
      @include size(50, 90);
      background: url('../img/bouquet.png') no-repeat left center;
      background-size: cover;
      content: "";
      

      @media (min-width: $xl-width) {
        @include size(80, 144);
        left: -20px;
      }
    }
  }
  &-steps {
    background: color(background, pink);
    margin: 0 -1.5rem;
    padding: 1.5rem 0;

    @media (min-width: $sm-width) {
      padding-left: 6rem;
    }
    @media (min-width: $md-width) {
      padding: 2rem 3rem 2rem 6rem;
    }
    @media (min-width: $xl-width) {
      padding: 2rem 5rem  2rem 15rem;
    }
  }

  &-step {
    color: color(text, dark);
    display: flex;
    align-items: flex-start;
    padding: 1rem 1.5rem;

    @media (min-width: $md-width) {
      padding: 2rem 0;
    } 

    img {
      height: auto;
      flex: 0 0 40px;

      @media (min-width: $md-width) {
        flex: 0 0 auto;
      }
    }

    &-content {
      font-size: 1.2rem;
      line-height: 1.2;
      padding-left: 0.5rem;
      
      @media (min-width: $md-width) {
        padding: 0 1.5rem;
        font-size: 1.4rem;
      }
    }
    
    &-title {
      display: block;
      letter-spacing: 2px;
      margin: 2rem 0 1rem 0;
      text-transform: uppercase;

      @media (min-width: $md-width) {
        margin: 3rem 0 1.5rem 0;
      }

      @media (min-width: $md-width) {
        font-size: 1.5rem;
      }
    }
  }
}

.block-smallword {
  line-height: 1.2;
  padding: 8rem 1.5rem 9rem 1.5rem;
  position: relative;

  @media (min-width: $xs-width) {
    padding: 10rem 1.5rem 12rem 1.5rem;
  }

  &:before {
    @include poa(0, null, null, 0.5rem);
    @include size(130, 130);
    background-image: url('../img/flower.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    content: "";

    @media (min-width: $xs-width) {
      @include size(200, 200);
    }

    @media (min-width: $sm-width) {
      @include poa(1rem, 25%, null, auto);
      transform: translateX(50%);
    }

    @media (min-width: $md-width) {
      right: 28%;
    }
    
    @media (min-width: $lg-width) {
      @include size(260, 260);
    }
  }
  &:after {
    @include poa(null, 3rem, -2rem);
    @include size(120, 175);
    background-image: url('../img/flower2.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    content: "";
    transform: rotate(-70deg);

    @media (min-width: $xs-width) {
      @include size(180, 260);
    }
    @media (min-width: $sm-width) {
      @include poa(auto, auto, 6rem, -5rem);
      transform: rotate(0);
    }
    @media (min-width: $md-width) {
      left: 0;
    }
    @media (min-width: $lg-width) {
      @include size(210, 300);
      left: 5rem
    }
  }

  &-img {
    padding: 0;
    position: relative;
    text-align: center;
    z-index: 1;

    @media (min-width: $sm-width) {
      left: 5rem;
    }
    @media (min-width: $lg-width) {
      left: 10rem;
    }

    img {
      display: inline-block;
      height: auto;
      max-width: 100%;
    }
  }

  &-content {
    background: color(background, dark);
    color: color(mono);
    margin: -5rem 1.5rem 0 1.5rem;
    padding: 2rem 4rem;
    position: relative;
    text-align: center;
    z-index: 2;
    
    @media (min-width: $xs-width) {
      margin: -15rem 3rem 0 3rem;
      padding: 3rem 5rem;
    }

    @media (min-width: $sm-width) {
      margin: 0;
    }
    
    @media (min-width: $lg-width) {
      padding: 5rem 8rem;
    }
  }

  &-title {
    color: color(mono);
    font-size: 2.5rem;
    margin-top: 0;
    padding: 0 4rem;

    @media (min-width: $md-width) {
      font-size: 4rem;
      padding: 0 2rem;
    }

    @media (min-width: $xl-width) {
      font-size: 5rem;
      padding: 0 4rem;
    }
  }
  &-subtitle {
    color: color(mono);
    display: block;
    font-size: 1rem;
    letter-spacing: 2px; 
    margin-top: 1rem;
    opacity: 0.7;
    text-transform: uppercase;

    @media (min-width: $md-width) {
      font-size: 1.5rem;
      margin-top: 1.5rem;
    }
  }
  &-description {
    color: color(mono);
    font-size: 1.4rem;
    margin-top: 2rem;

    @media (min-width: $lg-width) {
      font-size: 1.6rem;
      margin-top: 2.5rem;
    }
  }
}