.owl-carousel {
  .owl-nav {
    @include poa(50%, 0, null, 0);

    .owl-prev,
    .owl-next {
      @include animated();
      font-size: 30px;
      opacity: 0;
      position: absolute;

      i {
        opacity: .3;
      }

      &:hover i {
        opacity: 1;
      }

      &.disabled {
        opacity: 0;
      }
    }

    .owl-prev {
      left: -30px;
    }

    .owl-next {
      right: -30px;
    }
  }

  .owl-dots {
    margin-top: 15px;
    text-align: center;

    .owl-dot {
      @include animated(background);
      @include size(10px, 10px);
      background: color(background);
      border-radius: 50%;
      display: inline-block;
      margin: 0 5px;

      &:hover, &.active {
        background: color(background, dark);
      }
    }
  }

  &:hover {
    .owl-nav .owl-prev:not(.disabled) {
      left: 0;
      opacity: 1;
    }

    .owl-nav .owl-next:not(.disabled) {
      opacity: 1;
      right: 0;
    }
  }
}
