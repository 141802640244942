.page-heading {
  .inner {
    background: color(background);
    margin-bottom: 15px;
    padding: 25px 0;
    @media (min-width: $xs-width) {
      margin-bottom: 20px;
    }
    @media (min-width: $sm-width) {
      margin-bottom: 25px;
      padding: 35px 0;
    }
    @media (min-width: $md-width) {
      margin-bottom: 35px;
    }
    @media (min-width: $lg-width) {
      margin-bottom: 45px;
      padding: 45px 0;
    }
  }

  .page-heading-title {
    margin-bottom: 15px;
    @media (min-width: $xs-width) {
      margin-bottom: 20px;
    }
    @media (min-width: $sm-width) {
    }
    @media (min-width: $md-width) {
      margin-bottom: 30px;
    }
    @media (min-width: $lg-width) {
    }
  }

  .page-heading-desc {
    @include rem(13);
    @media (min-width: $xs-width) {
    }
    @media (min-width: $sm-width) {
      font-size: default(font-size);
    }
    @media (min-width: $md-width) {
    }
    @media (min-width: $lg-width) {
    }
  }

  &.order-process, &.authentication {
    margin-bottom: 3rem;
    @media(min-width: $md-width) {
      margin-bottom: 1.5rem;
      padding-top: 0;
    }

    .title {
      margin: auto;
      @include title-process;
      margin-bottom: 1.5rem;
      margin-top: 2rem;
      text-align: center;
      @media(min-width: $md-width) {
        margin-bottom: 4.5rem;
        margin-top: 4rem;
        max-width: none;
      }

      + .short-desc {
        margin-bottom: 4rem;
        margin-top: 1.5rem;
        @media(min-width: $xs-width) {
          margin-top: -1rem;
        }
      }
    }

    .mention {
      font-family: default(font-family);
      font-size: 1.5rem;
      font-weight: default(weight);
      margin-top: 0.5rem;
      text-transform: none;
    }

    .short-desc {
      font-family: default(font-family);
      font-size: 1.5rem;
      font-weight: default(weight);
      line-height: 2rem;
      margin-bottom: 2rem;
      margin-top: 0.5rem;
      text-transform: none;
    }
  }
}
