.cms-block {
    @extend %block-spacing;

    line-height: 1.2;
    @media (min-width: $md-width) {
        line-height: 1.5;
    }

    h2 {
        margin: 1.5rem 0;

        @media (min-width: $xs-width) {
            margin: 0 0 1.5rem 0;    
        }
        @media (min-width: $md-width) {
            margin-bottom: 5rem;  
        }
    }

    &-default {
        padding: 0 6%;
    }

    &-img {

    }

    &-color {
        color: color(mono);
        padding: 1rem 0;
        position: relative;
        margin-bottom: 3rem;

        @media (min-width: $xs-width) {
            padding: 1.5rem 0;
        }
        @media (min-width: $sm-width) {
            padding: 3rem 0;
            margin-bottom: 5rem;
        }
        @media (min-width: $md-width) {
            margin-bottom: 22rem;
        }

        &:before {
            @media (min-width: $sm-width) {
                @include poa(0, 0, 0, 60%);
                background: color(mono);
                content: "";
                z-index: 1;
            }
        }

        .ctn {
            position: relative;
            z-index: 2;
        }

        h2 {
            color: inherit
        }

        img {
            position: relative;

            @media (min-width: $sm-width) {
                top: 15rem;
            }
        }

        &-content {
            @media (min-width: $sm-width) {
                padding-right: 7rem
            }
        } 

        .btn {
            background: transparent;
            border-color: color(mono); 
            color: color(mono);

            &:hover {
                background: color(mono);
                color: color(primary);
            }
        }
    }

    &-dbl {
        h2 {
            font-size: 2.4rem;
            margin: 2rem 0;
            
            @media (min-width: $sm-width) {
                font-size: 3rem;
            }
        }

        &-ctn {
            margin-bottom: 3rem;

            @media (min-width: $xs-width) {
                margin-bottom: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

        &-inner {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            height: 100%;

            .cursor, .btn-link  {
                cursor: pointer;
            }
        }

        &-link {
            color: inherit;

            &:hover {
                
            }
        }

        &-content {
            padding-bottom: 1rem;
        }

        .btn-link {
            margin: auto 0 0 0;
        }
    }

    &-title {
        > div {
            
        }
    }
} 
.block:last-child .cms-block { margin-bottom: 0; }