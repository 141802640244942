.homepage {
    .header-main {
        .navbar-content {
            color: color(primary);

            @media (min-width: $sm-width) {
                color: inherit;
            }
        }
        &.stack {
            .header-top {
                > .ctn-fluid > div {
                    @media (min-width: $md-width) {
                        align-items: flex-end;
                    }
                }
            }
        } 
        &:not(.stack) {
            background: transparent;
            color: color(mono); 

            .header-top {
                height: 160px;
                background: transparent;
                padding: 1rem;

                .header-logo {
                    height: 100px;
                    a {
                        color: color(logo, light);
                    }

                    span {
                        @include animated(max-width);
                        display: block;
                        width: 100%;
                        @include rem(80);
                    
                        @media (min-width: $xs-width) {
                            @include rem(135);
                        }
                        @media (min-width: $sm-width) {
                            @include rem(135);
                        }
                        @media (min-width: $md-width) {
                            @include rem(135);
                        }
                    }
                }

                .header-top-right {
                    [data-rbs-storeshipping-short-store=""] > div a,
                    .dropdown-toggle {
                        color: inherit;

                        .badge,
                        .header-pin {
                            background: color(mono);
                            color: color(primary);

                            @media (min-width: $md-width) {
                                background: inherit;
                                color: inherit;
                            }
                        }
                    }
                }
            }
        }

        &:hover {
            .header-top {
                background: color(mono);
                color: color(primary);

                .header-logo span {
                    color: color(primary);
                    @media (min-width: $md-width) {
                        
                        @include rem(100);
                    } 
                }

                .header-top-right {
                    [data-rbs-storeshipping-short-store=""] > div a,
                    .dropdown-toggle {
                        color: inherit;

                        .badge,
                        .header-pin {
                            background: color(primary);
                            color: color(mono);

                            @media (min-width: $md-width) {
                                background: inherit;
                                color: inherit;
                            }
                        }
                    }
                }
            }
        }
    }
    .site-content {
        padding-top: 0;

        .block-reinsurance {
            border-bottom: 1px solid color(primary, light);
            padding-bottom: 4rem
        }

        .block {
            @extend %block-spacing;

            &:last-child {
                margin-bottom: 0;
            }

            &.quote {
                margin-bottom: 4.5rem; 
            }
        }
        [data-name="Rbs_Highlight_Highlight"] {
            margin-bottom: 3rem; 
        }

        
    }

    

    h1 {
        display: inline;
        position: relative;

        &:before, &:after {
            font-family: default(font-family, icon); 
            font-size: 0.45em;
            padding: 0 0.5em;    
            position: relative;
            top: -1em;
        }
        &:before {
            content: "\e90f";
        }
        &:after {
            content: "\e90e";
        }
    }

    .footer-top {
        border: 0;
        margin-top: -3rem;

        @media (min-width: $sm-width) {
            border-top: 1px solid color(primary, light);
        }
    }
}

.slider-hp {
    filter: blur(30px);
    opacity: 0;
    position: relative;
    transition: all 0.7s ease 0s; 

    .owl-item > li {
        background-size: cover;
        background-position: center center;
        background-repeat: none;
        width: 100%;
        height: 50vh;

        @media (min-width: $lg-width) {
            height: 100vh
        }

        a {
            @include poa(0, 0, 0, 0)
        }
    }
   
    &.in-view {
        filter: blur(0);
        opacity: 1;
    }

    .js-arrow-anchor {
        @include animated(background);
        cursor: pointer;
        position: absolute;
        bottom: 3.5rem;
        left: 50%;
        z-index: 1;
        border: .2rem solid color(mono);
        border-radius: 100%;
        margin-left: -2.5rem;
        
        &:after {
            @include animated(color);
            color: color(mono);
            display: block;
            font-family: default(font-family, icon);
            transform: rotate(180deg);
            height: 5rem;
            width: 5rem;
            text-align: center;
            content: '\e907';
            font-size: 2rem;
            line-height: 4.3rem;
        }

        &:hover {
            background: color(mono);

            &:after {
                color: color(mono, dark)
            }
        }
    }
}

.block-reinsurance {
    &-item{
        text-align: center;
        &:hover {
            .block-reinsurance-img {
                transform: translateY(-5px);
            }
        }
    }
    &-img {
        height: 90px;
        align-items: center;
        display: flex;
        justify-content: center;
        transform: translateY(0);
        transition: transform 0.4s cubic-bezier(0, 0, 0, 1) 0s;
    }
    &-title {
        color: color(mono, dark);
        font-family: default(font-family, secondary);
        margin-bottom: 15px;
        @include rem(15);
    }
    &-subtitle {
        @include rem(14);
        font-weight: default(weight, light);
    }

    
}

.dbl-img {
    margin: 0 -1rem;

    @media (min-width: $sm-width) {
        margin: 0;
    }
    
    &-block {
        overflow: hidden;
        padding: 3rem 4rem;
        position: relative;
        margin-bottom: 1.5rem;

        &:last-child {
            margin-bottom: 0;
        }

        @media (min-width: $xs-width) {
            margin-bottom: 0;
            padding: 5%;
        }
        @media (min-width: $md-width) {
            padding: 0 1.5rem;

            &:hover {
                img {
                    transform: scale(1.5);
                }
            }
            
        }

    }
    
    &-ctn {
        overflow: hidden;
    }

    img {
        @include poa(0, 0, null, 50%);
        z-index: 1;
        transform: translateX(-50%);
        min-width: 500px;
        transition: transform 10s ease 0s;

        @media (min-width: $sm-width) {
            left: auto;
            top: 50%;
            transform: translateY(-50%) translateX(0);
        }
        @media (min-width: $md-width) {
            position: relative;
            top: auto;
            transform: translateY(0);
        }
    }
    &-caption {
        background-color: color(background);
        padding: 2rem 1.5rem;
        position: relative;
        z-index: 2;
        @media (min-width: $xs-width) {
            height: 100%;
            padding: 1.5rem 2rem;
        }

        @media (min-width: $md-width) {
            @include poa(10%, 20%, 10%, 20%);
            display: flex;
            flex-direction: column;
            height: auto;
            padding: 2rem 3rem;
        }

        @media (min-width: $xl-width) {
            @include poa(10%, 25%, 10%, 25%);
            padding: 4rem 5rem;
        }

        h2 {
            font-family: default(font-family, tertiary);
            font-size: 2.5rem;
            padding-bottom: 10px;

            @media (min-width: $lg-width) {
                font-size: 3.5rem;
            }
            @media (min-width: $xl-width) {
                font-size: 4.5rem;
            }
        }
        .btn-link {
            @media (min-width: $md-width) {
                align-self: center;;
                margin-top: auto;
            }
        }
    }
    &-subtitle {
        font-family: default(font-family, secondary);
        color: color(primary, light);
        display: block;
        font-size: 1.2rem;
        text-transform: uppercase;
        margin-bottom: 2rem;

        @media (min-width: $lg-width) {
            font-size: 1.4rem;
            margin-bottom: 2rem;
        }
        @media (min-width: $xl-width) {
            font: 1.6rem;
        }
    }
    &-text {
        color: color(grey);
        font-weight: default(weight, light);
        margin-bottom: 1rem;

        @media (min-width: $lg-width) {
            font-size: 1.6rem;

        }
    }
    &-link {
        color: color(mono, gold);
        border-bottom-color: color(mono, gold);
    }

    &-list {
        overflow-x: auto;
        text-align: left;
        > div {
            display: flex;
            flex-wrap: nowrap;
    
            & > div {
                min-width: 100%;
                width: auto;

                @media (min-width: $xs-width) {
                    min-width: 45%;
                }
                @media (min-width: $sm-width) {
                    min-width: 33.33%
                }
            }
        }
    }

    .owl-carousel .owl-nav {
        top: calc(50% - 20px);

        .owl {
            &-prev, 
            &-next {
                @include size(40);
                background: color(mono);
                border: 0;
                border-radius: 50%;
                box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
                color: color(secondary);
                font-size: 14px;
                font-weight: bold;
                opacity: 1;
                padding: 0;
                text-align: center;
                line-height: 40px;

                i {
                    display: block;
                    opacity: 1;
                }
            }
    
            &-prev {
                left: 5% !important;
                padding-right: 2px;
            }
            &-next {
                right: 5% !important;
                padding-left: 2px;
            }
        }
    } 
}

.featured-products {
    position: relative;
    text-align: center;

    .header-product-list {
        margin-bottom: 1.5rem;

        @media (min-width: $md-width) {
            margin-bottom: 2.5rem;
        }

        @media (min-width: $sm-width) {
            padding-right: 260px;
        }
    }

    .product-list {
        overflow-x: auto;
        padding: 0;
        text-align: left;

        > div {
            display: flex;
            flex-wrap: nowrap;
    
            & > div {
                min-width: 80%;
                width: auto;

                @media (min-width: $xs-width) {
                    min-width: 45%;
                }
                @media (min-width: $sm-width) {
                    min-width: 33.33%
                }
            }

            @media (max-width: $xs-width) {
                flex-wrap: wrap;
            }
        }
    }
    .clearfix {
        display: none !important;
    }

    .btn-link {
        position: relative;
        top: -1.5rem;

        @media (min-width: $sm-width) {
            @include poa(0, 1.5rem);
        }
    }
}

.block-product-image {
    &-image {
        flex-basis: 100%;

        @media (min-width: $md-width) {
            max-width: 523px;
        }
        @media (min-width: $lg-width) {
            max-width: 642px;
        }
        @media (min-width: $xl-width) {
            max-width: 708px;
        }
    }
}

.univers-block {
    &-inner {
        img {
            display: block;
            background: #000;
            transition: opacity 0.3s ease 0s;
        }
    }
    .btn {
        margin-top: -25px;
    }

    &-item { 
        margin-bottom: 1.5rem; 

        @media (min-width: $md-width) {
            margin-bottom: 0;
        }

        &:hover {
            .univers-block-inner {
                img {
                    opacity: 0.7
                }
            }
            .btn {
                transform: translateY(-10px);
            }
        }
    }

    
}

.color-block {
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
    padding-bottom: 45%;

    @media (min-width: $sm-width) {
        background-position: right center;
        background-size: auto 100%;
        padding-bottom: 0
    }

    h2 {
        text-transform: uppercase;
        margin-bottom: 30px;
        
        @media (min-width: 1500px) {
            padding-right: 10rem;
        }
    }
    p {
        font-size: 1.2rem;
        font-weight: default(weight, light);
        line-height: 1.3;
        margin-bottom: 30px;
        
        @media (min-width: $xs-width) {
            font-size: 1.6rem;
        }
        @media (min-width: $md-width) {
            font-size: 1.8rem;
            line-height: 23px;
        }
    }
    &-caption {
        padding: 3rem 1.5rem;
        text-align: center;
        font-size: 1.2rem;

        @media (min-width: $sm-width) {
            padding: 3rem 10% 3rem 1.5rem;
            text-align: left;
        }   
        @media (min-width: $lg-width) {
            padding: 6rem 8rem 6rem 1.5rem;
        }
        @media (min-width: 1500px) {
            padding: 12rem 16rem 12rem 1.5rem;
        }
    }
    .btn {
        padding: 13px 45px;
        width: 100%;
        @media (min-width: $xs-width) {
            width: auto;
        }
    }
}

.watercolor {
    padding: 2.5rem 1.5rem;
    position: relative;

    @media (min-width: $xs-width) {
        padding: 0 12%;
    }
    @media (min-width: $sm-width) {
        padding: 0;
        left: 5%;
    }

    &-img {
        display: block;
        margin: 0 auto 1rem auto;
        max-width: 100%;

        @media (min-width: $xs-width) {
            max-width: 60%
        }
        @media (min-width: $sm-width) {
            margin: 0 0 0 auto;
        }
        @media (min-width: $lg-width) {
            margin: 0 auto;
            position: relative;
            left: 5%;
        }
    }

    &-content {
        text-align: center;

        @media (min-width: $sm-width) {
            @include poa(50%, null, null, 5%);
            text-align: left;
            transform: translateY(-50%);
            width: 50%;
        }
        @media (min-width: $lg-width) {
            width: 40%
        }
    }

    h2 {
        @include heading(color(secondary), 50, 20, false, false);
        line-height: 1;

        @media (min-width: $lg-width) {
            font-size: 8rem;
            margin-bottom: 4rem;
        }
    }

    &-subtitle {
        font-family: default(font-family, secondary);
        color: color(primary, light);
        font-size: 1rem;
        letter-spacing: 1px;
        text-transform: uppercase;
        display: block;
        padding-bottom: 17px;

        @media (min-width: $xs-width) {
            font-size: 1.5rem;
        }
    }
    &-text {
        font-weight: default(weight, light);
        line-height: 1.4;
        @media (min-width: $md-width) {
            padding-bottom: 50px;
        }
    }

    .btn {
        margin-top: 1.5rem;
        width: 100%;

        @media (min-width: $sm-width) {
            width: auto;
        }
    }
}

.newsletter {
    margin: 0 -1rem; 

    @media (min-width: $xs-width) {
        margin: 0 -1.5rem;
    }
    @media (min-width: $sm-width) {
        margin: 0;
    }

    & > .ctn {
        padding: 0 1rem;

        @media (min-width: $sm-width) {
            padding: 0 1.5rem;
        }
    }

    &-mailinglist{
        background-color: color(background, dark);
        text-align: center;
        padding: 3rem 1.5rem;

        @media (min-width: $xs-width) {
            padding: 3rem 20%
        }
        @media (min-width: $md-width) {
            padding: 3rem 1.5rem
        }

        &-header {
            &-title {
                @include heading(color(mono), 18, 35, false, false);
                line-height: 1.4; 

                @media (min-width: $xs-width) {
                    font-size: 2.4rem;
                    line-height: 1.2; 
                }
                @media (min-width: $md-width) {
                    font-size: 3rem;
                    text-align: center;
                }
            }
        }

        &-submit {
            @include poa(1px, 1px, 1px);
            border-left: 1px solid color(mono);
            color: color(mono);
            padding: 0 1.5rem;
            text-transform: uppercase;

            &:hover {
                background: color(mono);
            }
        }
        .form-group {
            margin: 0;
            position: relative;

            .form-control::placeholder {
                color: color(mono);
            }
        }
    }
    &-social {
        background-color: color(background, dark);
        padding: 0 0 25px;
    }
    &-devis {
        background-color: color(background, grey);
        color: color(text, dark);
        font-family: default(font-family, secondary);
        font-size: 1.2rem;
        font-weight: 400;
        letter-spacing: 1px;
        padding: 1.5rem 3rem;
        text-transform: uppercase;
        
        @media (min-width: $md-width) {
            padding: 2.5rem 3rem 2rem 3rem;
            font-size: 1.5rem;
        }

        span {
            display: block;
            margin-bottom: 0.5rem;
            padding: 0 0.5rem;
        }

        .icon {
            font-size: 3rem;
            margin-bottom: 1rem;
            
        }
        a {
            text-decoration: underline;
            color: color(text, dark);
        }
    }
}

.social {
    .icon {
        color: color(text, light);
        font-size: 2.6rem;

        @media (min-width: $sm-width) {
        font-size: 3rem;
        }
        @media (min-width: $md-width) {
        font-size: 3.5rem;
        }
    }
}

.instagram {
    text-align: center;

    @media (min-width: $sm-width) {
        text-align: left;
    }

    > .ctn {
        @media (min-width: $lg-width) {
            padding: 0 15rem
        }
    }

    h2 {
        @include heading(color(secondary), 50, 10, false, false);
        padding: 4.5rem 0 4.5rem 8.5rem;
        text-align: left;

        @media (min-width: $xs-width) {
            padding: 2rem 0 8rem 7rem;
        }
        @media (min-width: $sm-width) {
            font-size: 6rem;
            padding: 6rem 0 6rem 0;
        }
        @media (min-width: $md-width) {
            font-size: 10rem;
        }
    }
    &-caption {
        position: relative;
        z-index: 2;

        @media (min-width: $sm-width) {
            font-size: 6rem;
            padding-left: 8.5rem;
        }
        @media (min-width: $md-width) {
            padding-left: 6.5rem;
        }

        &:after {
            @include poa(0, null, null, 1.5rem);
            color: color(secondary);
            content: "\e910";
            font-family: default(font-family, icon);
            font-size: 14rem;
            opacity: 0.15;

            @media (min-width: $sm-width) {
                font-size: 18rem;
            }
            @media (min-width: $md-width) {
                font-size: 22rem;
            }
        }
    }
    &-image {
       margin-top: 1.5rem;
       @media (min-width: $xs-width) {
            margin-top: 10rem;
       }
       @media (min-width: $md-width) {
           margin-top: 13rem;
       }
    }
}

.seo {
    line-height: 1.4; 
    font-weight: default(weight, light);
}

#trackers-ask-consent-gdpr {
    background: #ddd;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
    z-index: 10;

    > a {
        font-size: 1.4rem;
        text-decoration: underline;
        margin-right: 1rem;
    }
}

.normal-row.checkbox {
    display: table-row;
    .custom-checkbox {
        position: relative;
    }
}