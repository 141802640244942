#pagenotfound {

  form {
    padding: 15px 0;

    label {
      margin-bottom: 10px;
      @include rem(13);
    }

    .form-control {
      margin-bottom: 10px;

      @media (min-width: $xs-width) {
        margin: 0;
      }
    }

    .btn {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
