%block-spacing {
  margin-bottom: 30px;
  @media (min-width: $sm-width) {
    margin-bottom: 50px;
  }
  @media (min-width: $md-width) {
    margin-bottom: 100px;
  }
}

%panel-header {
  @include heading(color(text, dark), 15, 20, true, true);
  font-family: default(font-family, secondary);
  font-weight: 500;
  letter-spacing: 1px;
}

%horizontal-center {
  left: 50%;
  position: absolute;
  @include transform(translateX(-50%));
}

%vertical-center {
  position: absolute;
  top: 50%;
  @include transform(translateY(-50%));
}

%full-center {
  left: 50%;
  position: absolute;
  top: 50%;
  @include transform(translate(-50%, -50%));
}
