/*************************************************************************************************************
*****************************************            Owl           *******************************************
**************************************************************************************************************/
.owl-carousel, .owl-carousel .owl-item {
  position: relative;
  -webkit-tap-highlight-color: transparent;
}

.owl-carousel {
  display: none;
  width: 100%;
  z-index: 1
}

.owl-carousel .owl-stage {
  -moz-backface-visibility: hidden;
  position: relative;
  -ms-touch-action: pan-Y;
}

.owl-carousel .owl-stage::after {
  clear: both;
  content: ".";
  display: block;
  height: 0;
  line-height: 0;
  visibility: hidden;
}

.owl-carousel .owl-stage-outer {
  overflow: hidden;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item, .owl-carousel .owl-wrapper {
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  float: left;
  min-height: 1px;
  -webkit-touch-callout: none
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%
}

.owl-carousel .owl-dots.disabled, .owl-carousel .owl-nav.disabled {
  display: none
}

.no-js .owl-carousel, .owl-carousel.owl-loaded {
  display: block
}

.owl-carousel .owl-dot, .owl-carousel .owl-nav .owl-next, .owl-carousel .owl-nav .owl-prev {
  cursor: pointer;
  cursor: hand;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none
}

.owl-carousel.owl-loading {
  display: block;
  opacity: 0;
}

.owl-carousel.owl-hidden {
  opacity: 0
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden
}

.owl-carousel.owl-drag .owl-item {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab
}

.owl-carousel.owl-rtl {
  direction: rtl
}

.owl-carousel.owl-rtl .owl-item {
  float: right
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both
}

.owl-carousel .owl-animated-in {
  z-index: 0
}

.owl-carousel .owl-animated-out {
  z-index: 1
}

.owl-carousel .fadeOut {
  animation-name: fadeOut
}

@keyframes fadeOut {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

.owl-height {
  transition: height .5s ease-in-out
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s ease
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d
}

.owl-carousel .owl-video-wrapper {
  background: color(mono, dark);
  height: 100%;
  position: relative;
}

.owl-carousel .owl-video-play-icon {
  -webkit-backface-visibility: hidden;
  background: url(owl.video.play.png) no-repeat;
  cursor: pointer;
  height: 80px;
  left: 50%;
  margin-left: -40px;
  margin-top: -40px;
  position: absolute;
  top: 50%;
  transition: transform .1s ease;
  width: 80px;
  z-index: 1;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3)
}

.owl-carousel .owl-video-playing .owl-video-play-icon, .owl-carousel .owl-video-playing .owl-video-tn {
  display: none
}

.owl-carousel .owl-video-tn {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  opacity: 0;
  transition: opacity .4s ease
}

.owl-carousel .owl-video-frame {
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 1;
}

.owl-theme .owl-dots, .owl-theme .owl-nav {
  -webkit-tap-highlight-color: transparent;
  text-align: center;
}

.owl-theme .owl-nav {
  margin-top: 10px
}

.owl-theme .owl-nav [class*=owl-] {
  background: #d6d6d6;
  border-radius: 3px;
  color: color(mono, light);
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
}

.owl-theme .owl-nav [class*=owl-]:hover {
  background: #869791;
  color: color(mono, light);
  text-decoration: none
}

.owl-theme .owl-nav .disabled {
  cursor: default;
  opacity: .5;
}

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px
}

.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1
}

.owl-theme .owl-dots .owl-dot span {
  -webkit-backface-visibility: visible;
  background: #d6d6d6;
  border-radius: 30px;
  display: block;
  height: 10px;
  margin: 5px 7px;
  transition: opacity .2s ease;
  width: 10px;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #869791
}

/*************************************************************************************************************
*************************************            Flexslider           ****************************************
**************************************************************************************************************/
@font-face {
  font-family: 'flexslider-icon';
  src: url('fonts/flexslider-icon.eot');
  src: url('fonts/flexslider-icon.eot?#iefix') format('embedded-opentype'), url('fonts/flexslider-icon.woff') format('woff'), url('fonts/flexslider-icon.ttf') format('truetype'), url('fonts/flexslider-icon.svg#flexslider-icon') format('svg');
  font-weight: default(weight);
  font-style: normal;
}

.flex-container a:hover,
.flex-slider a:hover,
.flex-container a:focus,
.flex-slider a:focus {
  outline: none;
}

.slides,
.slides > li,
.flex-control-nav,
.flex-direction-nav {
  list-style: none;
  margin: 0;
  padding: 0;
}

.flex-pauseplay span {
  text-transform: capitalize;
}

.flexslider {
  margin: 0;
  padding: 0;
}

.flexslider .slides > li {
  display: none;
}

.flexslider .slides img {
  display: block;
  width: 100%;
}

.flexslider .slides::after {
  clear: both;
  content: "\0020";
  display: block;
  height: 0;
  line-height: 0;
  visibility: hidden;
}

html[xmlns] .flexslider .slides {
  display: block;
}

* html .flexslider .slides {
  height: 1%;
}

.no-js .flexslider .slides > li:first-child {
  display: block;
}

/* DEFAULT THEME */
.flexslider {
  position: relative;
  zoom: 1;
}

.flexslider .slides {
  zoom: 1;
}

.flexslider .slides img {
  height: auto;
}

.flex-viewport {
  max-height: 2000px;
  transition: all 1s ease;
}

.loading .flex-viewport {
  max-height: 300px;
}

.carousel li {
  margin-right: 5px;
}

.flex-direction-nav {
  *height: 0;
}

.flex-direction-nav a {
  color: rgba(0, 0, 0, 0.8);
  cursor: pointer;
  display: block;
  height: 40px;
  margin: -20px 0 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  text-decoration: none;
  text-shadow: 1px 1px 0 rgba(255, 255, 255, 0.3);
  top: 50%;
  transition: all 0.3s ease-in-out;
  width: 40px;
  z-index: 10;
}

.flex-direction-nav .flex-prev {
  left: -50px;
}

.flex-direction-nav .flex-next {
  right: -50px;
  text-align: right;
}

.flexslider:hover .flex-direction-nav .flex-prev {
  left: -10px;
  opacity: 0.7;
}

.flexslider:hover .flex-direction-nav .flex-prev:hover {
  opacity: 1;
}

.flexslider:hover .flex-direction-nav .flex-next {
  opacity: 0.7;
  right: -10px;
}

.flexslider:hover .flex-direction-nav .flex-next:hover {
  opacity: 1;
}

.flex-direction-nav .flex-disabled {
  cursor: default;
  filter: alpha(opacity=0);
  opacity: 0 !important;
}

.flex-pauseplay a {
  bottom: 5px;
  color: color(mono, dark);
  cursor: pointer;
  display: block;
  height: 20px;
  left: 10px;
  opacity: 0.8;
  overflow: hidden;
  position: absolute;
  width: 20px;
  z-index: 10;
}

.flex-pauseplay a::before {
  content: '\f004';
  display: inline-block;
  font-family: "flexslider-icon";
  font-size: 20px;
}

.flex-pauseplay a:hover {
  opacity: 1;
}

.flex-pauseplay a.flex-play::before {
  content: '\f003';
}

.flex-control-nav {
  bottom: -40px;
  position: absolute;
  text-align: center;
  width: 100%;
}

.flex-control-nav li {
  display: inline-block;
  *display: inline;
  margin: 0 6px;
  zoom: 1;
}

.flex-control-paging li a {
  background: #666;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  display: block;
  height: 11px;
  text-indent: -9999px;
  width: 11px;
}

.flex-control-paging li a:hover {
  background: #333;
  background: rgba(0, 0, 0, 0.7);
}

.flex-control-paging li a.flex-active {
  background: color(mono, dark);
  background: rgba(0, 0, 0, 0.9);
  cursor: default;
}

.flex-control-thumbs {
  margin: 5px 0 0;
  overflow: hidden;
  position: static;
}

.flex-control-thumbs li {
  float: left;
  margin: 0;
  width: 25%;
}

.flex-control-thumbs img {
  cursor: pointer;
  display: block;
  height: auto;
  opacity: .7;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
  width: 100%;
}

.flex-control-thumbs img:hover {
  opacity: 1;
}

.flex-control-thumbs .flex-active {
  cursor: default;
  opacity: 1;
}

@media screen and (max-width: 860px) {
  .flex-direction-nav .flex-prev {
    left: 10px;
    opacity: 1;
  }
  .flex-direction-nav .flex-next {
    opacity: 1;
    right: 10px;
  }
}

/*************************************************************************************************************
*************************************            LightGallery           ***************************************
**************************************************************************************************************/
.lg-icon {
  font-family: default(font-family, icon)
}

.lg-actions .lg-next, .lg-actions .lg-prev {
  @include animated();
  color: #999;
  cursor: pointer;
  display: block;
  font-size: 22px;
  font-size: 40px;
  position: absolute;
  top: 50%;
  z-index: 1080;
}

.lg-actions .lg-next.disabled, .lg-actions .lg-prev.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.lg-actions .lg-next:hover, .lg-actions .lg-prev:hover {
  color: color(mono, dark);
}

.lg-actions .lg-next {
  right: 50px;
}

.lg-actions .lg-prev {
  left: 50px;
}

@-webkit-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}

@-moz-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}

@-ms-keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}

@keyframes lg-right-end {
  0% {
    left: 0;
  }
  50% {
    left: -30px;
  }
  100% {
    left: 0;
  }
}

@-webkit-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}

@-moz-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}

@-ms-keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}

@keyframes lg-left-end {
  0% {
    left: 0;
  }
  50% {
    left: 30px;
  }
  100% {
    left: 0;
  }
}

.lg-outer.lg-right-end .lg-object {
  animation: lg-right-end 0.3s;
  position: relative;
}

.lg-outer.lg-left-end .lg-object {
  animation: lg-left-end 0.3s;
  position: relative;
}

.lg-toolbar {
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1080;
}

.lg-toolbar .lg-icon {
  color: #999;
  cursor: pointer;
  float: right;
  font-size: 24px;
  height: 47px;
  line-height: 27px;
  outline: medium none;
  padding: 10px 0;
  text-align: center;
  text-decoration: none !important;
  width: 50px;
}

.lg-toolbar .lg-icon:hover {
  color: color(mono, dark);
}

.lg-toolbar .lg-download {
  display: none;
}

.lg-sub-html {
  background-color: rgba(0, 0, 0, 0.45);
  bottom: 0;
  color: #eee;
  font-size: 16px;
  left: 0;
  padding: 10px 40px;
  position: fixed;
  right: 0;
  text-align: center;
  z-index: 1080;
}

.lg-sub-html h4 {
  font-size: 13px;
  font-weight: default(weight, bold);
  margin: 0;
}

.lg-sub-html p {
  font-size: 12px;
  margin: 5px 0 0;
}

#lg-counter {
  color: #999;
  display: inline-block;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 12px;
  vertical-align: middle;
}

.lg-toolbar, .lg-prev, .lg-next {
  opacity: 1;
  transition: transform 0.35s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.35s cubic-bezier(0, 0, 0.25, 1) 0s;
}

.lg-hide-items .lg-prev {
  opacity: 0;
  transform: translate3d(-10px, 0, 0);
}

.lg-hide-items .lg-next {
  opacity: 0;
  -webkit-transform: translate3d(10px, 0, 0);
  transform: translate3d(10px, 0, 0);
}

.lg-hide-items .lg-toolbar {
  opacity: 0;
  -webkit-transform: translate3d(0, -10px, 0);
  transform: translate3d(0, -10px, 0);
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-object {
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
  transform-origin: 50% 50%;
  transition: transform 250ms ease 0s, opacity 250ms !important;
}

body:not(.lg-from-hash) .lg-outer.lg-start-zoom .lg-item.lg-complete .lg-object {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
}

.lg-outer .lg-thumb-outer {
  bottom: 0;
  max-height: 350px;
  position: absolute;
  transform: translate3d(0, 100%, 0);
  transition: transform 0.25s cubic-bezier(0, 0, 0.25, 1) 0s;
  width: 100%;
  z-index: 1080;
}

.lg-outer .lg-thumb-outer.lg-grab .lg-thumb-item {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer .lg-thumb-outer.lg-grabbing .lg-thumb-item {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg-thumb-outer.lg-dragging .lg-thumb {
  -webkit-transition-duration: 0s !important;
  transition-duration: 0s !important;
}

.lg-outer.lg-thumb-open .lg-thumb-outer {
  transform: translate3d(0, 0%, 0);
}

.lg-outer .lg-thumb {
  height: 100%;
  margin-bottom: -5px;
  padding: 10px 0;
}

.lg-outer .lg-thumb-item {
  border: 2px solid color(mono, light);
  border-radius: 5px;
  border-radius: 4px;
  cursor: pointer;
  float: left;
  height: 100%;
  margin-bottom: 5px;
  overflow: hidden;
}

@media (min-width: 1025px) {
  .lg-outer .lg-thumb-item {
    transition: border-color 0.25s ease;
  }
}

.lg-outer .lg-thumb-item img {
  display: block;
  height: 100%;
  margin: 0 auto;
}

.lg-outer.lg-has-thumb .lg-item {
  padding-bottom: 120px;
}

.lg-outer.lg-can-toggle .lg-item {
  padding-bottom: 0;
}

.lg-outer.lg-pull-caption-up .lg-sub-html {
  transition: bottom 0.25s ease;
}

.lg-outer.lg-pull-caption-up.lg-thumb-open .lg-sub-html {
  bottom: 100px;
}

.lg-outer .lg-toogle-thumb {
  display: none;
}

.lg-progress-bar {
  background-color: #333;
  height: 5px;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.08s ease 0s;
  width: 100%;
  z-index: 1080;
}

.lg-progress-bar .lg-progress {
  background-color: #a90707;
  height: 5px;
  width: 0;
}

.lg-progress-bar.lg-start .lg-progress {
  width: 100%;
}

.lg-show-autoplay .lg-progress-bar {
  opacity: 1;
}

.lg-autoplay-button::after {
  content: "\e01d";
}

.lg-show-autoplay .lg-autoplay-button::after {
  content: "\e01a";
}

.lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-img-wrap, .lg-outer.lg-css3.lg-zoom-dragging .lg-item.lg-complete.lg-zoomable .lg-image {
  transition-duration: 0s;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-img-wrap {
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  transition: transform 0.3s ease 0s;
}

.lg-outer .lg-item.lg-complete.lg-zoomable .lg-image {
  backface-visibility: hidden;
  transform: scale3d(1, 1, 1);
  transform-origin: 0 0;
  transition: transform 0.3s ease 0s, opacity 0.15s !important;
}

#lg-zoom-in::after {
  content: "\f00e";
}

#lg-zoom-out {
  opacity: 0.5;
  pointer-events: none;
}

#lg-zoom-out::after {
  content: "\f010";
}

.lg-zoomed #lg-zoom-out {
  opacity: 1;
  pointer-events: auto;
}

.lg-outer .lg-pager-outer {
  bottom: 60px;
  height: 10px;
  left: 0;
  position: absolute;
  right: 0;
  text-align: center;
  z-index: 1080;
}

.lg-outer .lg-pager-outer.lg-pager-hover .lg-pager-cont {
  overflow: visible;
}

.lg-outer .lg-pager-cont {
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
  overflow: hidden;
  position: relative;
  vertical-align: top;
}

.lg-outer .lg-pager-cont:hover .lg-pager-thumb-cont {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.lg-outer .lg-pager-cont.lg-pager-active .lg-pager {
  box-shadow: 0 0 0 2px white inset;
}

.lg-outer .lg-pager-thumb-cont {
  background-color: color(mono, light);
  border-radius: 3px;
  bottom: 100%;
  color: color(mono, light);
  height: 83px;
  left: 0;
  margin-bottom: 20px;
  margin-left: -60px;
  opacity: 0;
  padding: 5px;
  position: absolute;
  transform: translate3d(0, 5px, 0);
  transition: opacity 0.15s ease 0s, transform 0.15s ease 0s;
  width: 120px;
}

.lg-outer .lg-pager-thumb-cont img {
  height: 100%;
  width: 100%;
}

.lg-outer .lg-pager {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.7) inset;
  display: block;
  height: 12px;
  transition: box-shadow 0.3s ease 0s;
  width: 12px;
}

.lg-outer .lg-pager:hover, .lg-outer .lg-pager:focus {
  box-shadow: 0 0 0 8px white inset;
}

.lg-outer .lg-caret {
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px dashed;
  bottom: -10px;
  display: inline-block;
  height: 0;
  left: 50%;
  margin-left: -5px;
  position: absolute;
  vertical-align: middle;
  width: 0;
}

.lg-fullscreen::after {
  content: "\e20c";
}

.lg-fullscreen-on .lg-fullscreen::after {
  content: "\e20d";
}

.group {
  *zoom: 1;
}

.group::before, .group::after {
  content: "";
  display: table;
  line-height: 0;
}

.group::after {
  clear: both;
}

.lg-outer {
  height: 100%;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity 0.15s ease 0s;
  width: 100%;
  z-index: 1050;
}

.lg-outer * {
  box-sizing: border-box;
}

.lg-outer.lg-visible {
  opacity: 1;
}

.lg-outer.lg-css3 .lg-item.lg-prev-slide, .lg-outer.lg-css3 .lg-item.lg-next-slide, .lg-outer.lg-css3 .lg-item.lg-current {
  transition-timing-function: inherit !important;
}

.lg-outer.lg-css3.lg-dragging .lg-item.lg-prev-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-next-slide, .lg-outer.lg-css3.lg-dragging .lg-item.lg-current {
  opacity: 1;
}

.lg-outer.lg-grab img.lg-object {
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}

.lg-outer.lg-grabbing img.lg-object {
  cursor: move;
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: -o-grabbing;
  cursor: -ms-grabbing;
  cursor: grabbing;
}

.lg-outer .lg {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.lg-outer .lg-inner {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  white-space: nowrap;
  width: 100%;
}

.lg-outer .lg-item {
  background: url("../img/loader.gif") no-repeat scroll center center transparent;
  display: none !important;
}

.lg-outer.lg-css3 .lg-prev-slide, .lg-outer.lg-css3 .lg-current, .lg-outer.lg-css3 .lg-next-slide {
  display: inline-block !important;
}

.lg-outer.lg-css .lg-current {
  display: inline-block !important;
}

.lg-outer .lg-item, .lg-outer .lg-img-wrap {
  display: inline-block;
  height: 100%;
  position: absolute;
  text-align: center;
  width: 100%;
}

.lg-outer .lg-item::before, .lg-outer .lg-img-wrap::before {
  content: "";
  display: inline-block;
  height: 50%;
  margin-right: -1px;
  width: 1px;
}

.lg-outer .lg-img-wrap {
  bottom: 0;
  left: 0;
  padding: 0 5px;
  right: 0;
  top: 0;
}

.lg-outer .lg-item.lg-complete {
  background-image: none;
}

.lg-outer .lg-item.lg-current {
  z-index: 1060;
}

.lg-outer .lg-image {
  display: inline-block;
  height: auto !important;
  max-height: 100%;
  max-width: 100%;
  vertical-align: middle;
  width: auto !important;
}

.lg-outer.lg-show-after-load .lg-item .lg-object, .lg-outer.lg-show-after-load .lg-item .lg-video-play {
  opacity: 0;
  transition: opacity 0.15s ease 0s;
}

.lg-outer.lg-show-after-load .lg-item.lg-complete .lg-object, .lg-outer.lg-show-after-load .lg-item.lg-complete .lg-video-play {
  opacity: 1;
}

.lg-outer .lg-empty-html {
  display: none;
}

.lg-outer.lg-hide-download #lg-download {
  display: none;
}

.lg-backdrop {
  background-color: color(mono, light);
  bottom: 0;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: opacity 0.15s ease 0s;
  z-index: 1040;
}

.lg-backdrop.in {
  opacity: 1;
}

.lg-css3.lg-no-trans .lg-prev-slide, .lg-css3.lg-no-trans .lg-next-slide, .lg-css3.lg-no-trans .lg-current {
  transition: none 0s ease 0s !important;
}

.lg-css3.lg-use-css3 .lg-item {
  backface-visibility: hidden;
}

.lg-css3.lg-use-left .lg-item {
  backface-visibility: hidden;
}

.lg-css3.lg-fade .lg-item {
  opacity: 0;
}

.lg-css3.lg-fade .lg-item.lg-current {
  opacity: 1;
}

.lg-css3.lg-fade .lg-item.lg-prev-slide, .lg-css3.lg-fade .lg-item.lg-next-slide, .lg-css3.lg-fade .lg-item.lg-current {
  transition: opacity 0.1s ease 0s;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide {
  transform: translate3d(-100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide {
  transform: translate3d(100%, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  transition: transform 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

.lg-css3.lg-slide.lg-use-left .lg-item {
  left: 0;
  opacity: 0;
  position: absolute;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide {
  left: -100%;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide {
  left: 100%;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  left: 0;
  opacity: 1;
}

.lg-css3.lg-slide.lg-use-left .lg-item.lg-prev-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-next-slide, .lg-css3.lg-slide.lg-use-left .lg-item.lg-current {
  transition: left 1s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.1s ease 0s;
}

/*************************************************************************************************************
*************************************            Scrollbar           *****************************************
**************************************************************************************************************/
/*************** SCROLLBAR BASE CSS ***************/
.scroll-wrapper {
  overflow: hidden !important;
  padding: 0 !important;
  position: relative;
}

.scroll-wrapper > .scroll-content {
  border: none !important;
  box-sizing: content-box !important;
  height: auto;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none !important;
  overflow: scroll !important;
  position: relative !important;
  top: 0;
  width: auto !important;
}

.scroll-wrapper > .scroll-content::-webkit-scrollbar {
  height: 0;
  width: 0;
}

.scroll-element {
  display: none;
}

.scroll-element, .scroll-element div {
  box-sizing: content-box;
}

.scroll-element.scroll-x.scroll-scrollx_visible, .scroll-element.scroll-y.scroll-scrolly_visible {
  display: block;
}

.scroll-element .scroll-bar, .scroll-element .scroll-arrow {
  cursor: default;
}

.scroll-textarea {
  border: 1px solid #ccc;
  border-top-color: #999;
}

.scroll-textarea > .scroll-content {
  overflow: hidden !important;
}

.scroll-textarea > .scroll-content > textarea {
  border: none !important;
  box-sizing: border-box;
  height: 100% !important;
  margin: 0;
  max-height: none !important;
  max-width: none !important;
  outline: none;
  overflow: scroll !important;
  padding: 2px;
  position: relative !important;
  top: 0;
  width: 100% !important;
}

.scroll-textarea > .scroll-content > textarea::-webkit-scrollbar {
  height: 0;
  width: 0;
}

/*************** SIMPLE INNER SCROLLBAR ***************/
.scroll-wrapper > .scroll-element, .scroll-wrapper > .scroll-element div {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10;
}

.scroll-wrapper > .scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%;
}

.scroll-wrapper > .scroll-element.scroll-x {
  bottom: 2px;
  height: 8px;
  left: 0;
  width: 100%;
}

.scroll-wrapper > .scroll-element.scroll-y {
  height: 100%;
  right: 10px;
  top: 0;
  width: 8px;
}

.scroll-wrapper > .scroll-element .scroll-element_outer {
  overflow: hidden;
}

.scroll-wrapper > .scroll-element .scroll-element_outer, .scroll-wrapper > .scroll-element .scroll-element_track, .scroll-wrapper > .scroll-element .scroll-bar {
  -moz-border-radius: 8px;
  -webkit-border-radius: 8px;
  border-radius: 8px;
}

.scroll-wrapper > .scroll-element .scroll-element_track,
.scroll-wrapper > .scroll-element .scroll-bar {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=40);
  opacity: 0.4;
}

.scroll-wrapper > .scroll-element .scroll-element_track {
  background-color: #e0e0e0;
}

.scroll-wrapper > .scroll-element .scroll-bar {
  background-color: #c2c2c2;
}

.scroll-wrapper > .scroll-element:hover .scroll-bar {
  background-color: #919191;
}

.scroll-wrapper > .scroll-element.scroll-draggable .scroll-bar {
  background-color: #919191;
}

/* update scrollbar offset if both scrolls are visible */
.scroll-wrapper > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
  left: -12px;
}

.scroll-wrapper > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
  top: -12px;
}

.scroll-wrapper > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
  left: -12px;
}

.scroll-wrapper > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
  top: -12px;
}

.scroll-wrapper > .scroll-element.scroll-x {
  display: none !important
}
