/* Global catégorie */

.facets-list {
  .facet-value-selector {
    h4 {
      @include heading(color(mono, dark), 16, 25, false, false);
      font-family: default(font-family, secondary);
      font-weight: default(weight);
      position: relative;
      cursor: pointer;

      button {
          display: none;
      }
      &:after {
          content: '\e907';
          font-family: icomoon;
          font-size: 1.6rem;
          position: absolute;
          right: 0;
          top: 3px;
          color: color(primary, light);
      }
    }

    li {
      padding-bottom: 5px
    }
    .checkbox label {
      color: color(grey)
    }
    [data-rbs-elasticsearch-facet-swatchsingle] {
      margin-top: -10px;
    }
    [data-rbs-elasticsearch-facet-swatch-item] {
      .btn {
        @include size(32);
        border: 0;
        border-radius: 50%;
        padding: 0;
        margin-right: 5px;
        line-height: 32px;
        text-align: center;

        .glyphicon::before {
          content: "\e914";
          color: color(mono);
          font-family: default(font-family, icon);
          font-size: 1.8rem;
          height: 1.8rem;
          line-height: 1.8rem;
        }
        .visible-hover {
          @include animated(opacity);
          opacity: 0;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
  }

  .badge {
    display: none;
  }

  [data-rbs-elasticsearch-facet-interval=""] {
    margin-top: 30px;
    padding: 0 0.5rem;

    .range-slider-labels {
      .label {
        display: none;
      }
    }

    .noUi-target {
      background: color(background, grey);
      border: 1px solid color(background, grey);
      box-shadow: none;
      border-radius: 0;

      .noUi-connect {
        background: color(primary, light);
      }
    }

    .noUi-horizontal {
      height: 6px;
      .noUi-handle {
        height: 11px;
        width: 8px;
        border: 1px solid color(background, dark);
        border-radius: 0;
        background: color(background, dark);
        box-shadow: none;
        top: -4px;

        .tooltip {
          opacity: 1;
          top: -20px;
          left: -5px;

          .tooltip-inner {
            background: none;
            color: color(text);
            font-family: default(font-family);
            font-weight: default(weight,light);
            @include rem(14);
            padding: 0;
          }
          .tooltip-arrow {
            display: none;
          }
        }
        &:before,
        &:after {
          display: none;
        }
      }
    }
  }

  hr {
    border-top: 1px solid color(primary, light);
  }

  .btn-responsive-toolbar {
    .btn {
      @extend .btn-link;
      border: 0;
      height: auto;
    }
  }

  @media (max-width: $sm-width) {
    display: none;
  }
}

.modal-rbs-elasticsearch-facet-responsive {
  .facets-list {
    display: block;
  }
}
.facets-responsive-buttons {
  .btn {
    width: 100%;
    margin-bottom: 15px;
  }
  @media (min-width: $sm-width) {
    display: none;
  }
}
html:not([dir=rtl]) .noUi-horizontal .noUi-handle { right: -4px; }

.page-heading-category {
  &-title {
    margin-bottom: 15px;
  }
  &-subtitle {
    color: color(secondary);
    font-family: default(font-family, secondary);
    letter-spacing: 1px;
    margin-bottom: 40px;
    text-transform: uppercase;
    @include rem(15);

    @media (max-width: $sm-width) {
      @include rem(10);
      margin-bottom: 0;
    }
  }
  &-text {
    color: color(text, dark);
    line-height: 2.3rem;
    margin-bottom: 20px;
    @include rem(18);
  }
}

.header-product-list {
  position: relative;
  color: color(mono, dark);
  margin-bottom: 15px;
  @media (min-width: $md-width) {
    margin-bottom: 4rem;
  }

  h2 {
    text-transform: uppercase;
  }
}

.thumbnail-announcement {
  box-shadow: none;
  border: 0;
  padding: 0;

  .announcement-visual img {
    margin-right: auto;
    margin-left: auto;
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.thumbnail-product {
  border: 0;
  box-shadow: none;
  border-radius: 0;
  margin-bottom: 30px;
  padding: 0;

  .caption {
    padding: 0;
    font-family: default(font-family, secondary);
    position: relative;
    @include rem(14);
    font-weight: default(weight);
    line-height: 1.2;
    margin-top: 10px;

    @media (min-width: $md-width) {
      font-size: 15px;
      line-height: 1.3;
      margin-top: 15px;
    }

    .product-label {
      text-transform: uppercase;
      margin-bottom: 5px;

      a {
        @include animated(color);
        color: color(text, dark);
      }
    }
    .product-availability {
      display: none;
    }
    .product-price {
      .price {
        @include animated(color);
        color: color(text, dark);
      }
    }
    .product-add {
      position: absolute;
      top: -4px;
      right: 0;

      .btn {
        background: none;
        border: 0;
        padding: 1px;
        margin: 0;
        height: auto;
        border-radius: 0;
        box-shadow: none;

        .icon {
          @include rem(18);
          color: color(mono, dark);
          
          @media (min-width: $md-width) {
            @include animated(color);
            @include rem(25);
          }
        }
      }
    }
  }
}

.product-list {
  @extend .start-xxs;
  margin-bottom: 20px;
  padding: 0 10%;

  @media (min-width: $xs-width) {
    padding: 0 20%
  }
  @media (min-width: $sm-width) {
    padding: 0;
  }
  @media (min-width: $md-width) {
    position: relative;
  }

  .thumbnail-product {
    text-align: left;
    .caption {
      padding: 0;
      transition: padding 0.4s cubic-bezier(0, 0, 0, 1) 0s;
    }
    .product-visual {
      overflow: hidden;

      img {
        transform: scale(1);
        transition: transform 0.4s cubic-bezier(0, 0, 0, 1) 0s;
      }
    }

    &:hover {
      .product-visual img {
        transform: scale(1.05);
      }
      .product-add .btn .icon,
      .product-price .price,
      .product-label a {
        color: color(secondary);
        text-decoration: none;
      }
    }
  }
}

/* Catégorie */
.category {
  .long-desc {
    background: color(background);
    font-size: 13px;
    padding: 25px 0;
    @media (min-width: $sm-width) {
      padding: 30px 0;
    }
    @media (min-width: $sm-width) {
      font-size: 14px;
      padding: 40px 0;
    }
    @media (min-width: $md-width) {
      padding: 45px 0;
    }
    @media (min-width: $lg-width) {
    }
  }
}

/* Catégorie Univers */
.category-univers {
  .children-categories {
    margin-bottom: 30px;
  }

  .children-category-ctn {
    margin-bottom: 15px;
    position: relative;
    @media (min-width: $sm-width) {
      margin-bottom: 20px;
    }
    @media (min-width: $sm-width) {
      margin-bottom: 25px;
    }
    @media (min-width: $md-width) {
      margin-bottom: 30px;
    }
    @media (min-width: $lg-width) {
      width: 25%;
    }

    .children-category-title {
      @include poa(50%, 15px, null, 15px);
      text-align: center;
      transform: translateY(-50%);
    }
  }
}
