/************************************          ==  CustomCheckbox ==          ***********************************/

.custom-checkbox {
  @include size(18);
  overflow: hidden;
  position: relative;

  input {
      cursor: pointer;
      height: 18px;
      left: 0;
      opacity: 0;
      margin: 0;
      position: absolute;
      top: 0; 
      width: 18px; 
  }

  /* Block */
  &:before { 
      content: "";
      background-color: transparent; 
      background-position: 20px -351px;
      border: 1px solid color(primary, light);
      border-radius: 0;
      cursor: pointer;
      display: block;
      height: 18px;
      left: 0;
      margin-top: -9px;
      margin-right: 10px;
      pointer-events: none;
      position: absolute;
      top: 50%;
      width: 18px;
  }

  &:after {
      content: "\e914";
      color: color(primary);
      cursor: pointer;
      display: block;
      font-family: default(font-family, icon);
      font-size: 1.8rem;
      height: 18px;
      opacity: 0;
      position: absolute;
      pointer-events: none;
      left: 0;
      top: 50%;
      transition: all 0.2s ease 0s;
      -moz-transition: all 0.2s ease 0s;
      -webkit-transition: all 0.2s ease 0s;
  }

  &.checked:after {
      opacity: 1;
  }

  &.inline {
      float: left;
      margin-right: 10px;
      margin-top: 4px;
      label {
          display: inline-block;
          max-width: 85%;
          vertical-align: middle;
      }
      input {
          left: 0;
          margin-left: 0;
      }
      &.custom-checkbox:after {
          margin-top: -14px;
      }
  }

  &:before {
      display: inline-block;
      margin-right: 10px;
      vertical-align: middle;
  }

}

.checkbox {
  position: relative;
  label {
      font-family: default(font-family);
      letter-spacing: 0;
      padding-left: 0;

      .custom-checkbox {
        top: 2px;
      }
  }

  &.checked, &:hover {
      label {
          color: color(text, dark);
      }
  }
  padding-left: 30px;
  input {
      position: absolute;
  }
  .custom-checkbox {
      bottom: 0;
      float: none;
      left: 0;
      margin-top: -2px;
      min-width: 15px;
      overflow: visible;
      position: absolute;
      top: 0;
  }
}

.form-inline .checkbox label {
  padding-left: 0;
}
.form-horizontal .checkbox {
  padding-top: 0;
  margin-top: 5px;
}

.checkbox label {
  color: color(text, dark);
}



/************************************          ==  CustomRadio ==          ***********************************/

.custom-radio {
  clear: both;
  position: relative;
  float: left;
  margin: 3px 8px 0 0;
}

.custom-radio input {
  cursor: pointer;
  height: 16px;
  left: 0;
  opacity: 0;
  margin-top: -8px;
  margin-left: 0 !important;
  position: absolute;
  top: 50%;
  width: 16px;
}

.custom-radio:before {
  content: "";
  background-color: #fff;
  border: 1px solid color(text, dark);
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 16px;
  width: 16px;
}

.custom-radio:after {
  background-color: color(text, dark);
  border-radius: 50%;
  content: "";
  cursor: pointer;
  display: block;
  height: 10px;
  left: 3px;
  top: 3px;
  margin: auto;
  opacity: 0;
  position: absolute;
  transform: scale(0);
  transition: all 0.2s ease 0s;
  width: 10px;
}

.custom-radio.checked:after {
  transform: scale(0.6);
  opacity: 1;
}

.custom-radio.inline:before,
.custom-radio.inline label {
  cursor: pointer;
  display: inline-block;
}

.custom-radio.inline label {
  margin-bottom: 0;
  padding-left: 10px;
  min-height: auto;
  position: relative;
  top: -3px;
}

.radio-box {
  cursor: pointer;
}
/* radio button */ 
.radio-button {
  align-items: center;
  display: flex;
  font-size: 1.6rem!important;
  font-weight: default(weight)!important;
  height: 3.8rem;
  justify-content: center;
  padding: 0 1.5rem!important;
  position: relative;

  .custom-radio {
    @include poa(0, 0, 0, 0);
    border: 1px solid color(grey, light);
    margin: 0!important;
    &:before, &:after {
      display: none;
    }
    input {
      left: 0;
      height: 100%;
      margin: 0;
      width: 100%;
      top: 0;
    }
    &.checked, &:hover {
      border-color: color(primary);
    }
  }
  @media(max-width:350px){
    font-size: 15px!important;
    padding: 0 0.7rem !important;
  }
  
}
/************************************          ==  CustomFile ==          ***********************************/

.custom-file {
  position: relative;
}

.custom-file>input {
  cursor: pointer;
  height: 49px;
  opacity: 0;
  position: absolute;
  width: 100%;
  font-size: 12px;
  color: color(grey);
}

.custom-file>label {
  display: block;
}

.custom-file .filename {
  font-size: 1.4rem;
  letter-spacing: 1px;
  color: color(grey);
  background: #fff;
  border: 1px solid color(text, dark);
  float: left;
  height: 48px;
  line-height: 46px;
  overflow: hidden;
  padding: 0 15px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 70%;  
} 

.custom-file .action {
  @extend .btn;
  @extend .btn-primary;
  float: right;
  height: 48px;
  line-height: 46px;
  padding: 0 15px;
  text-align: center;
  width: 30%;
  text-transform: uppercase;

  @media(max-width: 480px) {
      &:after {
          display: none;
      }
  }
}

@media(max-width: 480px) {
  .custom-checkbox.inline label {
      max-width: 75%
  }
}


/************************************          ==  CustomSelect ==          ***********************************/

.custom-select {
  position: relative;
}

.custom-select.disabled>span {
  cursor: not-allowed;
  opacity: 0.5;
}

.custom-select.disabled>label .label {
  cursor: not-allowed;
  opacity: 0.5;
}

.custom-select>select {
  display: none !important;
}

.custom-select>span {
  cursor: pointer;
  background: #fff;
  border: 1px solid color(border);
  display: block;
  font-size: 14px;
  height: 45px;
  line-height: 45px;
  padding: 0 35px 0 15px;
  position: relative;
  text-align: left;  
  white-space: nowrap;
  width: 100%;
  z-index: 1;
}

.custom-select>span.open {
  border-radius: 0;
}

.custom-select.disabled>span {
  cursor: not-allowed
}

.custom-select>span:after {
  content: "\e907";
  background-color: #fff;
  border-radius: 0 35px 35px 0;
  color: color(grey, dark);
  cursor: pointer;
  display: block;
  font-family: icomoon;
  font-size: 1.2rem;
  font-style: normal;
  height: 0;
  line-height: 0;
  opacity: 1;
  padding-left: 0;
  position: absolute;
  right: 1.5rem;
  text-align: center;
  transition: all 0.2s ease 0s;
  -moz-transition: all 0.2s ease 0s;
  -webkit-transition: all 0.2s ease 0s;
  top: 50%;
  width: 10px;
}


.custom-select>span.open:after {
  content: '';
  background-color: #4d5153;
  display: block;
  height: 1px;
  width: 10px;
  opacity: 1;
  top: 25px;
}

.custom-select>ul {
  background: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  display: none;
  margin: -1px 0 0 0;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0;
  position: absolute;
  z-index: 10;
  list-style-type: none;
}

.custom-select>ul.small {
  font-size: 70%;
}


.custom-select>ul.open {
  display: block
}

.custom-select>ul li {
  cursor: pointer;
  padding: 8px 10px;
  list-style-type: none;
}

.custom-select>ul li:hover,
.custom-select>ul li.selected {
  font-family: default(font-family);
  background-color: #f5f5f6;
}

.custom-select>ul li input {
  height: 30px;
  width: 100%;
}

.custom-select.inline>label {
  display: inline-block;
  font-size: 14px;
  margin-bottom: 5px;
}

@media (max-width: 480px) {
  .date-select .custom-select>span:after {
      padding-left: 2px;
      width: 25px;
  }

  .date-select .custom-select>span:before {
      right: 28px;
  }
}

/************************************          ==  FormControl ==          ***********************************/
/* champs */
.form-control {
  padding: 10px 20px;
  height: 45px;
  box-shadow: none;
  border: 1px solid color(border);
  border-radius: 0;
  appearance: none;
  color: color(text, dark);

  &:focus {
    box-shadow: none;
  }

  &.inverse {
    background: transparent;
    border-color: color(mono)
  }
}

.form-control-static {
  color: #8b8c8d;
  line-height: 1.3;
  min-height: 0;
  padding: 0;
}

.form-control-code {
  background-color: color(grey, light);
  border-radius: default(radius);
  border: 0;
  box-shadow: none;
  height: 5.8rem;
  font-size: 3rem;
  line-height: 1.2;
  padding: 0.5rem;
  text-align: center;
  width: 3rem;
 
  @media(min-width:$md-width){
    width: 4.3rem;
  }
  
}
/* input */
.input-group .form-control {
  letter-spacing: 1px;
}
.label {
  color: color(text, dark);
}
.form-label {
  font-size: 15px;
  color: color(text, dark);
  font-family: default(font-family);
}

/************************************          ==  FormControl focused ==          ***********************************/
/* type text, password, number, email */
.form-group,
.form-ctrl-ctn {
  margin: 0!important;
  margin-top: 15px!important;
  position: relative;
  text-align: left;
  

  [class*="col-"] { 
    float: none; padding: 0;
    &:not(label) {  width: 100%;  }
  }
  .control-label, label {
      letter-spacing: 0!important;
      width: auto!important;
      z-index: 1;
      padding-left: 0;
      font-family: default(font-family, secondary);
      font-weight: default(weight, book);
      font-size: 1.4rem;

      em {
          float:right;
          margin-left: 5px;
      }
  }
  [for="rbs-user-birthDate"].control-label {
      position: static!important;
      line-height: 1.3;
      height: auto;
      padding: 0;
  }
  
  input:-internal-autofill-selected, input:-webkit-autofill {
      box-shadow:0 0 0 50px white inset;
  }
  /* focused */
  &.focused {
      .control-label, label {
          background: none;
          pointer-events: none
      }
      input:-internal-autofill-selected {
          box-shadow: none;
      }
      
  }

  .input-group {
    .input-group-btn {
      button {
        height: 45px;
        border: 1px solid color(border);
      }
    }
  }
}

/* form-control-static */
.form-ctrl-static-ctn {
  padding: 15px 0 0 0;
  .control-label {
      font-size: 11px;
      padding-left: 0!important;
      position: static;
      height: auto;
      line-height: 1.2;
  }
}
/* custom-select */
.form-ctrl-select-ctn {
  .control-label {
      letter-spacing: 0;
      z-index: 2;
  }
  
}

select {
  &:after {
    content: "\e907";
    background-color: #fff;
    border-radius: 0 35px 35px 0;
    color: color(grey, dark);
    cursor: pointer;
    display: block;
    font-family: icomoon;
    font-size: 1.2rem;
    font-style: normal;
    height: 0;
    line-height: 0;
    opacity: 1;
    padding-left: 0;
    position: absolute;
    right: 1.5rem;
    text-align: center;
    transition: all 0.2s ease 0s;
    -moz-transition: all 0.2s ease 0s;
    -webkit-transition: all 0.2s ease 0s;
    top: 50%;
    width: 10px;
  }
}

