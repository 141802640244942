html {
  font-family: default(font-family);
  font-size: 62.5%;
  overflow-x: hidden;
  text-size-adjust: 100%;

  &.panel-open {
    overflow: hidden;
  }
}

body {
  font-size: default(font-size);
  line-height: default(line-height);
  color: color(text);
  overflow-x: hidden;
}

/* Header */
.header-main .stack,
.header-main:hover,
.header-main {
  @include animated(box-shadow);
  background: color(mono, light);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0);
  color: color(primary);
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 150;

  .block {
    margin-bottom: 0;
  }

  .overtop {
    background: color(primary);
    color: color(mono);
    font-weight: default(weight, light);
    text-align: center;
    @include rem(13);
    height: height(banner);
    @media (min-width: $xs-width) {
      height: height(banner, xs);
    }
    @media (min-width: $sm-width) {
      height: height(banner, sm);
    }
  }

  .header-links {
    display: none;
    width: 50%;

    @media (min-width: $sm-width) {
      display: block;
      float: left;
    }

    li {
      display: inline-block;
      padding-right: 12px;
      position: relative;
    }
  }

  .header-text {
    color: color(mono, light);
    display: block;
    text-align: center;
    width: 100%;
    @media (min-width: $sm-width) {
      float: right;
      text-align: right;
      width: 50%;
    }
  }

  .header-top {
    background: color(mono);
    height: height(header, base);
    padding: 1rem;
    position: relative;

    @media (min-width: $xs-width) {
      height: height(header, xs);
    }
    @media (min-width: $sm-width) {
      height: height(header, sm);
    }
    @media (min-width: $md-width) {
      height: height(header, md);
    }
    @media (min-width: $lg-width) {
      height: height(header, stack);
    }

    a {
      @media (min-width: $md-width) {
        letter-spacing: 1px;
      }
      transition: none;
    }
  }

  .header-logo {
    padding: 0;
    text-align: center;

    a {
      display: inline-block;
      color: color(logo, base);
      text-decoration: none;
      transition: none;

      span {
        @include animated(max-width);
        display: block;
        width: 100%;
        @include rem(80);
  
        @media (min-width: $xs-width) {
          @include rem(100);
        }
        @media (min-width: $sm-width) {
          @include rem(100);
        }
        @media (min-width: $md-width) {
          @include rem(100);
        }
      }
    }
  }

  .header-top-left {
    padding-bottom: 13px;
  }

  .header-top-right {
    text-align: right;
    padding-bottom: 13px;

    .flex {
      > div {
        padding: 0 1.5rem 0 0 ;
        @media (min-width: $sm-width) {
          padding: 0 3rem 0 0 ;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }

    [data-name="Rbs_Storeshipping_ShortStore"],
    [data-name="Rbs_User_AccountShort"],
    [data-name="Rbs_Commerce_ShortCart"] {
      .glyphicon {
        color: inherit;
        font-family: default(font-family, icon);
      }
    }

    [data-name="Rbs_Storeshipping_ShortStore"] .glyphicon:before { content: "\e900" }
    [data-name="Rbs_User_AccountShort"] .glyphicon:before { content: "\e911" }
    [data-name="Rbs_Commerce_ShortCart"] .glyphicon:before { content: "\e904" }

    .glyphicon,
    i {
      font-size: 2rem;

      @media (min-width: $xs-width) {
        @include rem(25)
      }
      @media (min-width: $sm-width) {
        @include rem(30)
      }
      @media (min-width: $md-width) {
        display: none;
      }
    }
  }
 
  .badge,
  .header-pin {
    @include poa(-3px, -9px);
    @include size(16);
    background: color(primary);
    border-radius: 50%;
    color: color(mono);
    font-weight: normal;
    font-size: 1rem;
    line-height: 16px;
    padding: 0;
    text-align: center;
    
    @media (min-width: $md-width) {
      background: transparent;
      position: static;
      color: inherit;
      font-size: inherit;

      &::before {
        content: "(";
        padding-right: 1px;
      }
      &::after {
        content: ")";
        padding-left: 1px;
      }
    }
  }

  .caret {
    display: none;
  }

  .title,
  .header-label {
    display: none;
    @media (min-width: $md-width) {
      border-bottom: 1px solid transparent;
      display: inline-block;
      @include rem(13);
      padding-bottom: 1rem;
      text-transform: uppercase;
      font-family: default(font-family, secondary);
      
      &:hover {
        border-bottom: 1px solid #003a5e;
      }
    }
  }
}

/* Site content */
.site-content {
  @include animated(padding);
  padding-top: height(header, base) + height(banner, base);

  .topContent > div {
    background-color: color(background, grey);
    padding: 20px 10px 15px;
    margin-bottom: 50px;
    text-align: center;

    @media (min-width: $md-width) {
      padding: 5rem 0
    }

    h1 {
      color: color(text, dark);
      font-size: 2.5rem;

      @media (min-width: $sm-width) {
        font-size: 3.5rem;
      }
      @media (min-width: $md-width) {
        font-size: 5rem;
      }

      + div {
        margin: 1.5rem 0 1rem 0;

        @media (min-width: $md-width) {
          margin: 3rem 0 1rem 0;
        }
      }
    }
    &:empty {
      padding: 0;
      margin-bottom: 0;
    }
    @media (max-width: $xs-width) {
      margin-bottom: 30px;
    }
  }
  .bottomContent {
    margin-bottom: 100px;
  }

  @media (min-width: $xs-width) {
    padding-top: height(header, xs) + height(banner, xs);
  }
  @media (min-width: $sm-width) {
    padding-top: height(header, sm) + height(banner, sm);
  }
  @media (min-width: $md-width) {
    padding-top: height(header, md) + height(banner, md);
  }
  @media (min-width: $lg-width) {
    padding-top: height(header, lg) + height(banner, lg);
  }
}

.footer {
  &-main {
    margin-top: 30px;

    @media (min-width: $sm-width) {
      margin-top: 50px;
    }
    @media (min-width: $md-width) {
      margin-top: 100px;
    }
  }

  &-top {
    border-top: 1px solid color(primary, light);
    margin: 0;

    .block {
      margin: 0;

      &-reinsurance {
        overflow-x: auto;
        padding: 30px 10px;

        .row {
          flex-wrap: nowrap;
        }

        &-item {
          min-width: 70%;

          @media (min-width: $xs-width) {
            min-width: 33.33%
          }

          @media (min-width: $sm-width) {
            min-width: 25%;
          }
        }
      }
    }
  }

  &-newsletter {
    margin-top: 1.5rem;

    .richtext {
      padding: 3rem 1.5rem
    }
  }

  &-middle {
    background-color: color(background, grey);
    padding: 0 0 3rem 0;

    @media (min-width: $sm-width) {
      padding: 3rem 0;
    }
    @media (min-width: $md-width) {
      padding: 5rem 0;
    }

    &-item {
      padding: 0 1.5rem;
      width: 100%;

      @media (min-width: $xs-width) {
        padding: 0 1.5rem
      }
      @media (min-width: $sm-width) {
        width: 25%;
      }
      @media (min-width: $md-width) {
        width: 20%;
      }
      @media (min-width: $lg-width) {
        width: 18%;
      }

      &:nth-child(3) {
        display: none;
      }

      &-social {
        padding: 0 1rem;
        width: 100%;

        @media (min-width: $xs-width) {
          padding: 0 1.5rem
        }
        @media (min-width: $sm-width) {
          margin-top: 2.5rem;
          text-align: center;
        }
        @media (min-width: $lg-width) {
          margin: 0 0 0 6%;
          text-align: left;
          width: 22%;
        }

        h3 {
          @media (min-width: $sm-width) {
            display: none;
          }
          @media (min-width: $lg-width) {
            display: block;
          }

          &:after {
            display: none;
          }
        }

        .social {
          .icon {
              color: color(primary);
              transition: opacity 0.3s ease 0s;

              &:hover {
                opacity: 0.5
              }
          }
        }
      }
    }
    .social {
      .icon {
        color: color(mono, dark);
      }
    }
  }
}

.site-content .sidebar,
.footer-main .footer-middle {
  nav {
    border-bottom: 0.1rem solid color(primary, light);

    @media (min-width: $md-width) {
      font-size: 1.5rem;
    }
    @media (min-width: $lg-width) {
      cursor: initial;
    }

    @media (min-width: $sm-width) {
      border: 0;
    }
  }


  h3 a,
  h3 {
    color: color(text, dark);
    font-size: 1.2rem;
    padding: 1.5rem 0;
    position: relative;
    margin: 0;

    @media (min-width: $sm-width) {
      font-size: 1.4rem;
      height: 6rem;
    }

    @media (min-width: $md-width) {
      font-size: 1.5rem;
    }

    &:after {
      @include poa(50%, 1rem);
      content: "\e907";
      color: color(primary, light);
      font-family: default(font-family, icon);
      transform: translateY(-50%) rotate(180deg);
      transform-origin: 50% 50%;

      @media (min-width: $sm-width) {
        display: none;
      }
    }
  }

  ul {
    display: none;
    font-size: 1.2rem;

    @media (min-width: $sm-width) {
      font-size: 1.4rem;
    }

    @media (min-width: $sm-width) {
      display: block !important;
    }
  }

  .open {
    h3:after {
      transform: translateY(-50%) rotate(0);
    }
    ul {
      display: block;
    }
  }

  li {
    padding-bottom: 10px;
    
    a {
      color: color(text, dark);

      &:hover {
        text-decoration: underline;
      }
    }
  }
  
}

/* Header-stck */
.header-stack {
  .header-main.stack {
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
  }
}

