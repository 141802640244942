.order-detail {
  &-ref {
    margin-bottom: 2rem;
  }

  &-status {
    margin-bottom: .5rem;
  }

  .price {
    display: block;
  }

  .product-qty {
    display: none;
  }

  .account-product-row {
    margin: 0 -1.5rem;
    border-bottom: 0;

    @media (min-width: $md-width) {
      margin: 0 0 1.5rem;
      border-bottom: 1px solid color(border);
    }
  }

  &-prices {
    background: color(background);
    border: 1px solid color(border);
    margin: 0 -1.5rem;

    @media (min-width: $md-width) {
      border-radius: default(radius);
      margin: 0;
    }
  }

  &-line {
    margin: 0;
    padding: 1.5rem 0;

    @media (min-width: $md-width) {
      padding: 15px 25px;
    }

    & + & {
      border-top: 1px solid color(border);
    }
  }

  &-total-price {
    font-size: 2.5rem;

    @media (min-width: $sm-width) {
      font-size: 3rem;
    }

    .current-price-tax-excl {
      color: color(text, light);
      font-size: 1.8rem;
      font-weight: default(weight, bold);
      line-height: 1.8rem;
    }

    .total-price-label {
      margin: 5px 0 7px;

      @media (min-width: $sm-width) {
        margin: 8px 0 10px;
      }
    }

    .total-price-ht-label {
      color: color(text, light);
    }
  }

  .return-checkbox {
    min-width: 35px;
  }

  &-return {
    &-textarea {
      min-height: 75px;
    }

    &-text {
      margin-bottom: 20px;
      max-height: 160px;
      overflow: hidden;
      padding: 0 15px;
      position: relative;
      transition: .3s;

      @media (min-width: $xs-width) {
        max-height: 100%;
        overflow: visible;
      }

      @media (min-width: $md-width) {
        padding: 0;
      }

      &-more {
        @include poa(null, null, 0, 0);

        background: linear-gradient(to bottom, transparentize(color(mono), .2) 0%, color(mono, light) 40%, color(mono, light) 100%);
        border: 0;
        color: color(text, light);
        display: block;
        font-size: 14px;
        padding: 40px 0 20px;
        text-align: center;
        text-transform: uppercase;
        width: 100%;
        z-index: 2;

        @media (min-width: $xs-width) {
          display: none;
        }

        .icon {
          margin-left: 10px;
        }
      }
    }
  }

  .block-address-row {
    margin-top: 1.5rem;
  }

  &-delivery {
    .account-title {
      margin-bottom: 20px;

      @media (max-width: $md-width) {
        border-bottom: 1px solid color(border);
        margin: 0 -15px 20px;
        padding: 0 15px 10px;
      }
    }
  }

  &-messages {
    padding-top: 50px;

    @media (max-width: $md-width) {
      padding: 50px 15px 0;
    }

    .account-title {
      margin-bottom: 20px;
    }

    .message {
      border: 1px solid color(border);
      margin-bottom: 20px;
      padding: 1.5rem 2.5rem;

      &-name {
        font-weight: default(weight, bold);
        line-height: 14px;
      }

      &-date {
        line-height: 14px;
      }

      &-content {
        margin-top: 10px;
      }
    }
  }

  &-contact {
    .account-title {
      margin-bottom: 20px;
    }

    &-textarea {
      min-height: 140px;
    }
  }
}
