/* Modal Stores */
[data-rbs-storeshipping-product-locator] {
  .form-group {
    @extend .flex;
    .btn-default {
      @extend .btn-primary;
      flex-grow: 1;
      height: 45px;
      line-height: 1;

      @media (min-width: $xs-width) {
        flex-grow: 0;
      }
  
      .glyphicon {
        display: none;
      }

      &:first-child {
        margin-bottom: 1.5rem;
      }
    }

    input {
      flex-grow: 1;
      width: 100% !important;
      
      @media (min-width: $xs-width) {
        width: auto !important;
      }
    }

    select { 
      display: none;
    }
  }

  & > div {
    h4, strong {
      color: color(primary);
      display: block;
      margin: 2rem 0 0 0;
      font-family: default(font-family, primary);
      font-weight: default(weight, book);
      font-size: 1.6rem;
    }
  }

  .text-danger {
    text-align: center;
    width: 100%;
  }
}
.storeshipping-stores-list {
  padding: 0;

  &-item {
    border: 1px solid color(primary, light);
    margin-top: 1rem;
    padding: 1.5rem;

    .col-sm-7.col-md-8 {
      width: 100%;

      @media (min-width: $md-width) {
        width: 65%;
      }
    }
    .col-sm-5.col-md-4 {
      margin-top: 2rem;
      text-align: center;
      width: 100%;

      @media (min-width: $md-width) {
        width: 35%;
      }

      .btn {
        width: 100%;
      }
    }

    strong {
      margin: 0  0 1rem 0 !important;
    }
  }
}


/* Store page */
[data-name="Rbs_Storelocator_Search"] {
  margin-bottom: 45px;
  
  .storelocator-search {
    background-color: color(background, grey);
    border-top: 1px solid color(grey, dark);
    padding: 15px 0;
    margin: -65px 0 15px;
    text-align: center;

    @media (min-width: $md-width) {
      padding: 30px 0;
    }

    .navbar {
      @extend .flex;
      @extend .between-xs;
      background: none;
      border: 0;
      margin: 0;
      @media (min-width: $md-width) {
        padding: 0 5%;
          
      }
      
      .navbar-left {
        border: 0;
        flex-grow: 1;
        width: 100%;

        @media (min-width: $sm-width) {
          width: auto;
        }

        button  {
          width: 100%;
        }

        @media (min-width: $sm-width) {
          padding: 0 15px
        }

        &:first-child {
          flex-grow: 0;
          margin-bottom: 3rem;
          @media (min-width: $sm-width) {
            margin-bottom: 0;
          }
        }

        > form {
          @extend .flex;
        }
      }

      & > div {
        display: inline-block;
        float: none !important;
        margin: 0;
        padding: 0;

        .form-group { 
          margin: 0!important; 
          padding-right: 10px;
          width: 70%;

          @media (min-width: $sm-width) {
            padding-right: 15px;
            width: 50%
          }
          @media (min-width: $md-width) {
            width: 55%;
          }

          input {
            font-family: default(font-family, primary);
            font-size: 1.6rem;
            font-weight: default(weight, light);
            width: 100% !important;

            &::placeholder {
              color: color(primary);
            }
          
          }
        }

        form select {
          font-family: default(font-family, primary);
          font-size: 1.61rem;
          font-weight: default(weight, light);
          width: calc(30%);
          padding: 10px;
          margin: 0 0 1rem 0;

          @media (min-width: $sm-width) {
            margin: 0 15px 0 0; 
            width: calc(20% - 15px);
          } 
          @media (min-width: $md-width) {
            width: calc(15% - 15px); 
          }
        }

        form button {
          width: 100%;

          @media (min-width: $sm-width) {
            width: 30% !important;
          }
        }

        @media (max-width: $xs-width) {
          width: 100%;
          border: 0;

          & > div {
            width: 100%;
          }
          .btn {
            width: 100%;
            margin: 0;
          }
        }
      }
      
      .navbar-text {
        display: none;
      }

      .btn {
        background: color(primary);
        border-color: color(primary);
        color: color(mono);

        &:hover {
          background-color: color(button);
          color: color(mono, dark);
        }

        .glyphicon {
          display: none;
        }
      }

      select,
      input {
        color: color(text, dark);
        height: 44px;

        @media (max-width: $xs-width) {
          margin: 0;
        }
        @media (min-width: $md-width) {
          height: 50px;
        }

        &[disabled="disabled"] {
          display: none;
        }
      }
    }
    @media (max-width: $xs-width) {
      margin: -30px 0 15px;
    }
  }

  .storelocator-result {

    [data-ng-hide="showHome()"] {
      > .row {
        @extend .start-xxs;

        @media (max-width: $xs-width) {
          > div {
            width: 100%;

            ol {
              max-height: auto;
            }
          }
        }

        > .col-md-6 {
          width: 100%;

          @media (min-width: $md-width) {
            width: 50%;
          }
        }
      }
      > p {
        color: color(text, dark);
        font-size: 1.8rem;
        > span {
          display: block;
          padding-bottom: 15px;
          @media (min-width: $sm-width) {
            padding-bottom: 50px;
          }
        }
      }
    }

    [data-role="map"] {
      margin: 0 0 45px 0;
      overflow: hidden;
      width: 100%;
      z-index: 1;

      [data-rbs-storelocator-store-popup] {
        > a {
          @extend .btn-primary;
          display: block;
          margin-top: 20px;

          .glyphicon {
            display: none;
          }
        }
      }

      @media (max-width: $xs-width) {
        display: none;
      }
    }
  
    [data-rbs-storelocator-store-item] {
      border-top: 1px solid color(border, lighter);
      padding: 25px 0 0;

      > .row {
        @extend .start-xxs;

        > .col-xs-10 {
          flex-basis: 100%
        }

        [data-ng-click="viewStoreOnMap(storeData)"] {
          display: none;
        }

        address {
          margin-bottom: 20px;
          @media (min-width: $sm-width) {
            font-size: 1.8rem;
          }

          strong {
            font-weight: default(weight);
          }
        }
      }

      .btn-responsive-toolbar {
        border-top: 0;
        padding: 0 0 10px;

        .btn {
          width: 50%;
          margin: 0 30px 15px 0;

          .glyphicon {
            display: none;
          }
          &.btn-view-store {
            @extend .btn-wipe;
            @extend .btn-link;
            width: auto;

            @media (max-width: $xs-width) {
              display: none;
            }
          }

          @media (max-width: $xs-width) {
            width: 100%;
          }
        }
        a {
          background: color(primary);
          border-color: color(primary);
          color: color(mono);
  
          &:hover {
            background-color: color(button);
            color: color(mono, dark);
          }
        }
      }
    }
  }

  .storelocator-filter {
    margin-top: 50px;
    .facet-nav > .row {
      @extend .start-xxs;

      @media (max-width: $xs-width) {
        ol {
          width: 50%;
        }
      }
    }
    li a {
      color: color(text, dark);
      font-size: 1.8rem;
    }
  }
}

[data-name="Rbs_Storelocator_Store"] {
  
  .store {
    &-map {
      z-index: 1;

      [data-role="map"] {
        height: 100%;
      }

      .btn-link {
        display: none;
      }
      @media (max-width: $xs-width) {
        order: 2;
      }
    }
    
    &-caption {
      &-btn {
        margin-bottom: 40px;

        a {
          @extend .btn;
          height: auto;

          .glyphicon {
            display: none;
          }
        }
        @media (max-width: $xs-width) {
          margin-bottom: 15px;
          a {
            width: 100%;
            text-align: center;
          }
        }
      }
      &-location {
        border-bottom: 1px solid color(border, lighter);
        padding-bottom: 30px;

        h2 {
          display: none;
        }
        address {
          margin-bottom: 20px;
          @media (min-width: $sm-width) {
            font-size: 1.8rem;
          }

          strong {
            font-weight: default(weight);
            margin-bottom: 0;
          }
        }
        @media (max-width: $xs-width) {
          padding-bottom: 15px;
        }
      }
      &-hours {
        h2 {
          font-size: 1.5rem;
          font-family: default(font-family, secondary);
          margin: 25px 0 15px;

          .glyphicon {
            display: none;
          }
        }

        .table {
          > thead > tr > th {
            text-transform: none;
            font-size: 1.4rem;
            letter-spacing: 0;
            font-weight: default(weight, light);
          }
          > tbody > tr > td {
            padding: 15px;
          }
        }

        @media (max-width: $xs-width) {
          overflow-y: auto;
        }
      }
    }
  }

  .nav-tabs {
    border-color: color(primary);
    margin-top: 3rem;

    .active a,
    .active a:hover {
      border-color: color(primary);
      border-bottom-color: transparent;
      border-radius: 0;
      color: color(primary);
    }

    & + .tab-content {
      padding: 3rem 0
    }
  }
}