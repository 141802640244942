
.order-process,
[data-rbs-commerce-cart-deliveries=""] {
  .process-top-menu {
    margin: 0 auto;
    position: fixed;
    right: 0;
    text-align: center;
    top: 57px;
    z-index: 151;
    padding: 0 70px;
    left: 0;
    
    @media (min-width: $sm-width) {
      top: 85px;
      padding: 0;
    }

    > .row {
      padding: 0;
      display: inline-block;
      width: auto;

      > .col-sm-12 {
        background: none;
        margin: 0;
        padding: 0;
        width: 100%;
        @media (max-width: 767px) {
          position: unset;
        }
      }
    }
    ol {
      align-items: center;
      display: flex;
      list-style: decimal inside;
      line-height: 1;
      li {
        padding: 0 5px;
        margin: 0;
        float: left;
        display: list-item;
        color: color(text, dark);
        @include rem(14);
        
        @media (min-width: $sm-width) {
          margin: 0 0 0 5px;
          @include rem(16);
        }

        @media (min-width: $md-width) {
          margin: 0 15px;
        }

        a {
          color: color(text, dark);
          position: relative;
          padding: 0;
          z-index: 2;
          text-transform: uppercase;
          background: none;
          display: none;

          @media (min-width: $sm-width) {
            display: inline-block;
          }

          .badge {
            display: none;
          }
          &:hover {
            color: color(text, dark);
          }
        }
        &.done {
          opacity: 0.3;
        }
        &.active, &.done {
          font-weight: default(weight);

          a {
            opacity: 1;
          }
        }
        &.active {
          background: none;
          color: color(secondary);

          a {
            color: color(secondary);
            background: none;
            display: inline-block;
          }
        }
        &.done, &.disabled {
          height: 17px;
          width: 20px;
          overflow: hidden;

          @media (min-width: $xs-width) {
            width: 30px;
          }
          @media (min-width: $sm-width) {
            width: auto;
            font-size: 1.6rem;
          }

          &::marker {
            font-size: 1.6rem;
          }
        }
      }
    }
    & + .visible-xs-block {display: none !important;}
    display: block !important;
  }
}