@media (max-width: $sm-width) {
  .panel-open {
    overflow: hidden;
  }
}

.panel-custom {
  background: color(mono, light);
  bottom: 0;
  max-width: 100%;
  overflow-x: auto;
  position: fixed;
  right: -100%;
  top: 0;
  transition: .3s ease;
  width: 100%;
  z-index: 1000;
  opacity: 0;
  pointer-events: none;
  margin: 0;
  border-radius: 0;
  border: 0;


  @media (min-width: $sm-width) {
    max-width: 100%;
    right: -586px;
    width: 576px;

    &-fw {
      right: -100%;
      width: 100%;
    }
  }

  &-head {
    background: color(background, grey);
    color: color(text, dark);
    padding: 2rem 6rem 2rem 3rem;
    position: relative;
  }

  &-block {
    padding: 1rem 1.5rem;

    @media (min-width: $md-width) {
      padding: 1rem 5rem;
    }
  }

  &-message {
    margin: 2rem 5rem 0;
  }

  &-messages {
    margin-bottom: 1.5rem;
    width: 100%;
  }

  &-title {
    background-color: color(background, grey);
    color: color(text, dark);
    display: block;
    position: relative;
    text-transform: uppercase;
    width: 100%;
    z-index: 2;
    text-align: left;
    font-size: 1.8rem;

    @media (min-width: $sm-width) {
      font-size: 2.5rem;
    }
  }

  &-close {
    @include poa(50%, 0);

    cursor: pointer;
    font-size: 2rem;
    padding: 1.5rem 2.5rem;
    transform: translateY(-50%);
    transition: .3s;
    z-index: 3;
    border: 0;
    background-color: transparent;

    &:hover {
      color: color(text);
    }
  }

  &-message {
    display: none;
    padding: 10px 30px;
  }

  &-buttons {
    padding: 30px;
  }

  &-footer {
    margin-top: 15px;
  }

  &.open {
    right: 0;
  }

  &-overlay {
    background-color: color(shadow, primary);
    bottom: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    right: 0;
    top: 0;
    transition: .5s ease 0s;
    z-index: 200;
  }

  &-content {
    padding: 3rem 2rem;

    @media (min-width: $sm-width) {
      padding: 4rem 10rem;
    }
  }

  .small-ctn {
    padding: 3rem 5rem;
  }

  .choose-date-btns {
    text-align: left;
    margin-bottom: 3rem;
    margin-top: 1rem;
  }

  .block-reinsurance-item {
    margin-top: 4.5rem;
  }

  .block-reinsurance-title {
    font-size: 2.5rem;
    font-family: default(font-family, tertiary);
    margin-bottom: 0;
    padding-top: 3.5rem;
    border-top: 1px solid color(border, lighter2);
  }

  .block-reinsurance-subtitle {
    color: color(grey);

    p:first-child {
      margin-bottom: 1rem;
    }
  }
}

.panels {
  &.open {
    .panel-overlay,
    .panel-custom {
      opacity: 1;
      pointer-events: auto;
    }
  }
}

.panel-cms {
  z-index: 1100;
}

.panel-product {
  margin-bottom: 30px;
  position: relative;

  &-img-ctn {
    padding: 0 15px;
    position: relative;
    width: 31%;
  }

  &-reduction {
    @include poa(5px, null, null, 20px);

    background: color(mono, dark);
    border-radius: 50%;
    color: color(mono, light);
    display: inline-block;
    font-size: 10px;
    font-weight: default(weight, bold);
    height: 30px;
    line-height: 30px;
    text-align: center;
    width: 30px;
  }

  &-infos {
    padding: 0 15px 0 0;
    position: relative;
    vertical-align: middle;
    width: 69%;

    .panel-product-name {
      font-size: 16px;
      margin-bottom: 10px;
    }

    .panel-product-attributes {
      color: color(text);
      font-size: 12px;
      line-height: 1.5rem;
      margin-bottom: 20px;
    }

    .panel-product-prices {
      text-align: right;

      .panel-product-old-price {
        color: color(text);
        font-size: 12px;
      }
    }
  }
}

.panel-account {
  .submit {
    margin-top: 30px;
  }

  .tab-radio {
    font-weight: default(weight, bold);
    padding: 30px 0;
  }

  .birthdate {
    label {
      display: block;
      margin-bottom: .5rem;
    }

    .row {
      margin: 0 -.5rem;
    }

    .xxs {
      padding: 0 .5rem;
    }
  }
}

.panel-info,
.panel-success,
.panel-default {
  box-shadow: none;
  border-radius: 0;
  border: 0;

  > .panel-heading {
    @extend %panel-header;
    background: color(background, grey);
    border: 0;
    margin-bottom: 0;
    padding: 25px 15px 0;
    text-align: center;
  }
  > .panel-body {
    padding: 25px 15px;
    background: color(background, grey);

    @media (min-width: $xs-width) {
      padding: 25px 20px;
    }

    .form-group {
      &:last-child {
        margin: 0;
      }
    }
    & + .panel-body {
      margin-top: 30px;
    }
  }
}